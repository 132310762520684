import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Housing } from "@app/@shared/models/housing";
import { RequestParams, ResultPaginated } from "@app/@shared/models/_results";
import { ReplaySubject, Subject } from "rxjs";
import { HousingService } from "@app/housing/housing.service";
import { debounceTime, filter, finalize, map, takeUntil } from "rxjs/operators";
import { Logger } from "@app/@core";
import { NotificationService } from "@app/@shared/services/notification.service";
import { resourceLimits } from "worker_threads";

@Component({
  selector: "app-housing-selector",
  templateUrl: "./housing-selector.component.html",
  styleUrls: ["./housing-selector.component.scss"],
})
export class HousingSelectorComponent implements OnInit, OnDestroy {
  log = new Logger(HousingSelectorComponent.name);

  private _value: string;
  private _editable: boolean;
  private _label: string;
  private _required: boolean;
  private _group_id: string;
  private _availableHouses: Housing[];
  private _availableControl: boolean = false;
  houses: ResultPaginated<Housing>;

  @Input() set value(value: string) {
    this._value = value;

    const _valueId = this.housingControl.value
      ? this.housingControl.value.id
      : undefined;

    if (value && value != _valueId) {
      this.getHouse(value);
    } else if (!value) {
      this.getHouses();
    }
  }
  get value(): string {
    return this._value;
  }

  @Input() set group_id(value: string) {
    this._group_id = value;
    if (value) {
      this.requestHousingParams.filter = [{ field: "group_id", value: value }];
    }
  }
  get group_id(): string {
    return this._group_id;
  }

  @Input() set availableHouses(houses: Housing[]) {
    if (houses) {
      this._availableControl = true;
      this.availableHouses = houses;
    } else {
      this._availableHouses = [];
    }
  }

  @Input() set editable(value: boolean) {
    this._editable = value;
    value ? this.housingControl.enable() : this.housingControl.disable();
  }
  get editable(): boolean {
    return this._editable;
  }

  @Input() set label(value: string) {
    this._label = value;
  }
  get label(): string {
    return this._label;
  }

  @Input() set required(value: boolean) {
    let _validators = [];

    if (value) _validators.push(Validators.required);

    this.housingControl.clearValidators();
    this.housingControl.setValidators(_validators);
  }
  get required(): boolean {
    return this._required;
  }

  @Output() formControlEvent = new EventEmitter<FormControl>();

  /** control for the selected entity for selection */
  public housingControl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword selection */
  public housingFilterControl: FormControl = new FormControl();

  /** list of entities filtered by search keyword */
  public housingFiltered: ReplaySubject<Housing[]> = new ReplaySubject<
    Housing[]
  >(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  requestHousingParams = new RequestParams();
  isLoading: boolean = false;

  constructor(
    private housingService: HousingService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {
    // listen for search field value changes
    this.housingFilterControl.valueChanges
      .pipe(
        filter((search) => !!search),
        takeUntil(this._onDestroy),
        debounceTime(250),
        map((search) => {
          this.requestHousingParams.query = {
            fields: ["street"],
            value: search,
          };
          this.getHouses();
        }),
        takeUntil(this._onDestroy),
      )
      .subscribe();

    this.housingControl.valueChanges
      .pipe(takeUntil(this._onDestroy), debounceTime(250))
      .subscribe(() => {
        this.formControlEvent.emit(this.housingControl);
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getHouses() {
    this.isLoading = true;

    this.housingService
      .getHouses(this.requestHousingParams)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (result) => {
          this.houses = result;
          this.housingFiltered.next(result.items);
        },
        error: (error) => {
          this.log.error("Error get companies: ", error);
          this.notificationService.open("app.messages.operation_failed");
        },
      });
  }

  getHouse(houseId: string) {
    this.isLoading = true;
    this.housingService
      .getHouseById("id", parseInt(houseId))
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (result) => {
          this.housingFiltered.next([result]);
          this.housingControl.setValue(result);
          this.housingControl.updateValueAndValidity();
        },
        error: (error) => {
          this.notificationService.open("Error loading user list", "error");
        },
      });
  }
}
