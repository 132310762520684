import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SortDirection } from "@angular/material/sort";
import { Logger, UntilDestroy, untilDestroyed } from "@app/@core";
import { AppConstants } from "@app/@shared/app-constants";
import {
  DefaultFilterForm,
  defaultFilterFormValue,
} from "@app/@shared/models/_common/filter-forms/default-filter-form";
import { RequestParams } from "@app/@shared/models/_results";
import { Housing, HousingFields } from "@app/@shared/models/housing";
import { NotificationService } from "@app/@shared/services/notification.service";
import { HousingService } from "@app/housing/housing.service";
import { Subject } from "rxjs";
import { debounceTime, finalize } from "rxjs/operators";

interface HousingsReadFormGroup extends DefaultFilterForm {
  tenantId: FormControl<number | null>;
  // roleId: FormControl<number | null>;
  // type: FormControl<HousingTypes | null>;
  // housingId: FormControl<number | null>;
}

@UntilDestroy()
@Component({
  selector: "app-house-selector[control]",
  templateUrl: "./house-selector.component.html",
  styleUrls: ["./house-selector.component.scss"],
})
export class HouseSelectorComponent implements OnInit {
  @Input() control: FormControl<Housing>;
  @Input() label = "housings.housing";
  @Input() set tenantId(value: number) {
    this.form.controls.tenantId.setValue(value);
  }

  @Input() showOnlyHousesWithTenants = false;
  // @Input() set type(value: HousingTypes) {
  //   this.form.controls.type.setValue(value);
  // }
  @Input() hasClearButton = false;
  @Input() hasRefreshButton = true;
  @Input() public set isTouch(isTouch: false) {
    this._isTouch = isTouch;
    if (this._isTouch == true) {
      this.control.markAllAsTouched();
    }
  }
  @Output() closeButtonClicked = new EventEmitter<void>();

  itemList = <Housing[]>[];
  hasMoreItems: boolean;
  peopleInput$ = new Subject<string>();
  isLoading: boolean;
  HousingFields = HousingFields;
  private _isTouch: any;

  form = new FormGroup<HousingsReadFormGroup>({
    ...defaultFilterFormValue,
    sortField: new FormControl<string>(HousingFields.description, {
      nonNullable: true,
    }),
    sortDirection: new FormControl<SortDirection>("asc"),
    tenantId: new FormControl<number>(null),
    // roleId: new FormControl<number | null>(null, { nonNullable: true }),
    // type: new FormControl<HousingTypes | null>(null, { nonNullable: true }),
    // housingId: new FormControl<number | null>(null, { nonNullable: true }),
  });
  log = new Logger(this.constructor.name);
  constructor(
    private housesService: HousingService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.peopleInput$.pipe(untilDestroyed(this)).subscribe((term) => {
      this.log.debug("term", term);
      this.form.patchValue({
        query: term,
        pageSize: AppConstants.DEFAULT_PAGE_SIZE,
      });
    });

    this.form.valueChanges
      .pipe(
        debounceTime(AppConstants.DEFAULT_DEBOUNCE_TIME),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.log.debug("form", this.form.getRawValue());
        this._loadData();
      });

    this.form.patchValue({});
    // this.form.updateValueAndValidity();
  }

  isRequired(): boolean {
    return this.control.hasValidator(Validators.required);
  }

  compareWith(a: Housing, b: Housing): boolean {
    return a.id === b.id;
  }

  onScrollToEnd() {
    if (this.hasMoreItems) {
      const pageSize: number =
        this.form.value.pageSize + AppConstants.DEFAULT_PAGE_SIZE;
      this.form.patchValue({ pageSize });
    } else {
      this.log.debug("No more items");
    }
  }
  onCloseButtonClicked() {
    this.control.reset();
    this.closeButtonClicked.emit();
  }

  getShowedText(item: Housing): string {
    if (item.description && item.description.startsWith("GL")) {
      return `${item.description}, ${item.full_street}`;
    } else {
      return `${item.full_street}`;
    }
  }

  onRefreshButtonClicked() {
    this._loadData();
  }

  private _loadData(): void {
    if (this.form.invalid) {
      this.log.warn("Trying to load data with invalid form");
      return;
    }

    this.isLoading = true;

    const formValue = this.form.getRawValue();

    const param = new RequestParams({
      query: {
        fields: [
          HousingFields.description,
          HousingFields.street,
          HousingFields.full_street,
        ],
        value: formValue.query,
      },
      pagination: {
        pageIndex: formValue.pageIndex,
        pageSize: formValue.pageSize,
      },
      order: [{ field: formValue.sortField, type: formValue.sortDirection }],
      filter: [
        ...(formValue.tenantId != null
          ? [
              {
                field: `${HousingFields.tenants}.id__exact`,
                value: formValue.tenantId.toString(),
              },
            ]
          : this.showOnlyHousesWithTenants
            ? [
                {
                  field: `${HousingFields.tenants}.id__gt`,
                  value: "-1",
                },
              ]
            : []),
        // ...(param.roleIds != null ? [{ field: `${HousingFields.role_id}__in`, value: param.roleIds.join(',') }] : []),
        // ...(formValue.isActive != null ? [{ field: HousingFields.is_active, value: formValue.isActive ? '1' : '0' }] : []),
      ],
    });
    this.housesService
      .getHouses(param)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (result) => {
          this.itemList = result.items;
          // this.itemList = result.items.map((item) => ({
          //   ...item,
          //   disabled: item.tenants?.length > 0? false : true,
          // }));
          this.hasMoreItems = result.total > result.items.length;
        },
        error: (error) => {
          this.notificationService.openEntityListLoadErrorNotification(
            "houses.houses",
          );
        },
      });
  }
}
