<mat-form-field fxFlex color="primary">
  <mat-label>{{
    (label ? label : "housing.groups.title") | translate | capitalize
  }}</mat-label>
  <mat-select
    #matSelector
    [formControl]="housingGroupControl"
    [multiple]="false"
    placeholder="{{
      (label ? label : 'housing.groups.title') | translate | capitalize
    }}"
  >
    <mat-select-trigger>
      <span>{{ housingGroupControl?.value?.address }}</span>
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search
        [formControl]="housingGroupFilterControl"
        [searching]="isLoading"
        placeholderLabel="{{ 'app.actions.search' | translate | capitalize }}"
        noEntriesFoundLabel="{{ 'any result' | translate }}"
      >
        <mat-icon ngxMatSelectSearchClear class="mat-select-search-button"
          >close</mat-icon
        >
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let group of groupsFiltered | async" [value]="group">
      <div fxLayout="column">
        <span>{{ group.name | capitalize }}</span>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
