import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";

import { User, UserTypes } from "@app/@shared/models/user";
import { Tenant } from "@app/@shared/models/tenant";
import { RequestParams, ResultPaginated } from "@app/@shared/models/_results";
import { Technician } from "@app/@shared/models/technician";
import { auth } from "@app/@core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  constructor(private httpClient: HttpClient) {}

  register(data: User): Observable<User> {
    const url = "/register";
    return this.httpClient
      .post(url, data, { context: auth("none") })
      .pipe(map((body: User) => body));
  }

  setUser(user: User): Observable<User> {
    return this.httpClient.post("/users", user).pipe(map((body: User) => body));
  }

  getUserById(param: RequestParams): Observable<ResultPaginated<User>> {
    const _params = param.toHttpParams();
    return this.httpClient.get<ResultPaginated<User>>("/users", {
      params: _params,
    });
  }

  setTenant(tenant: Tenant): Observable<Tenant> {
    tenant.role_id = 2;
    return this.httpClient
      .post("/users", tenant)
      .pipe(map((body: Tenant) => body));
  }

  updateUser(user: User): Observable<User> {
    const url = "/users";
    return this.httpClient.patch<User>(url, user);
  }

  updateStatusUser(user: User): Observable<User> {
    const url = "/users/status";
    return this.httpClient.patch<User>(url, user);
  }

  getUsers(requestParams: RequestParams): Observable<ResultPaginated<User>> {
    const _params = requestParams.toHttpParams();
    return this.httpClient.get<ResultPaginated<User>>("/users", {
      params: _params,
    });
  }

  getTenants(
    requestParams: RequestParams,
  ): Observable<ResultPaginated<Tenant>> {
    const url = "/users";
    const params = requestParams
      .toHttpParams()
      .set("_filter_type", UserTypes.tenant);
    return this.httpClient.get<ResultPaginated<User>>(url, { params });
  }

  getTenant(field: string, value: number): Observable<User> {
    const _params = new HttpParams()
      .set(field, value)
      .set("_filter_type", UserTypes.tenant);
    return this.httpClient.get<User>("/users", { params: _params });
  }

  getTechnicians(
    requestParams: RequestParams,
  ): Observable<ResultPaginated<Technician>> {
    const url = "/users";
    const params = requestParams
      .toHttpParams()
      .set("_filter_type", UserTypes.technician);
    return this.httpClient.get<ResultPaginated<Technician>>(url, { params });
  }

  getTechnician(field: string, value: number): Observable<Technician> {
    const _params = new HttpParams()
      .set(field, value)
      .set("_filter_type", UserTypes.technician);
    return this.httpClient.get<Technician>("/users", { params: _params });
  }

  getEmployees(
    requestParams: RequestParams,
  ): Observable<ResultPaginated<User>> {
    const params = requestParams.toHttpParams();
    return this.httpClient.get<ResultPaginated<User>>("/users", { params });
  }

  getEmployee(field: string, value: number): Observable<User> {
    const _params = new HttpParams().set(field, value);
    return this.httpClient.get<User>("/users", { params: _params });
  }

  resetUserPassword(email: string, username: string): Observable<any> {
    return this.httpClient.patch("/users/reset_password", {
      email: email,
      username: username,
    });
  }
}
