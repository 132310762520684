import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  AUTO_STYLE,
} from "@angular/animations";

import {
  permissionsSettings,
  ModuleInterface,
} from "@env/permissions-settings";
import { filter, take } from "rxjs/operators";
import { AWSAuthService } from "@app/@core/services/aws/auth.service";
import { PermissionService } from "@app/@core";

import * as _ from "lodash";
import { UserTypes } from "@app/@shared/models/user";

@Component({
  selector: "app-sidenav-primary",
  templateUrl: "./sidenav-primary.component.html",
  styleUrls: ["./sidenav-primary.component.scss"],
  animations: [
    trigger("collapse", [
      state("true", style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state("false", style({ height: "0", visibility: "hidden" })),
      transition("false => true", animate("250ms ease-in")),
      transition("true => false", animate("250ms ease-out")),
    ]),
  ],
})
export class SidenavPrimaryComponent {
  @Input() isExpanded: boolean;
  @Output() toggleSidenavPrimaryEvent: EventEmitter<void> =
    new EventEmitter<void>();

  rootPatter: RegExp = /[^/\\]+/;
  modules: ModuleInterface[] = [];

  private _type: UserTypes = UserTypes.user;
  currentUserType: UserTypes = this.awsAuthService.currentUser?.type;

  constructor(
    public router: Router,
    private awsAuthService: AWSAuthService,
    private permissionService: PermissionService,
  ) {
    const onNavigationEnd = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    );

    onNavigationEnd
      .pipe(take(1))
      .toPromise()
      .then((result: NavigationEnd) => {
        const _url = result.url.match(this.rootPatter);
        this.expandCurrentModule(_url ? _url[0] : "");

        // Filter modules to view from user role/permissions configuration
        this.checkAccessModules();
      });
  }

  expandCurrentModule(root: string) {
    const _module = permissionsSettings.find((m) => m.path === `/${root}`);
    if (_module && this.isExpanded == true) _module.expanded = true;
  }

  async checkAccessModules() {
    for await (let m of permissionsSettings) {
      let _module: ModuleInterface = _.cloneDeep(m);
      _module.submodules = _module.submodules ? [] : undefined;

      if (m.submodules == undefined) {
        if (await this.permissionService.checkPermissionRoute(m.path)) {
          this.modules.push(_module);
        }
      } else {
        // this.modules.push(m);
        for await (let s of m.submodules) {
          if (await this.permissionService.checkPermissionRoute(s.path)) {
            if (
              this.currentUserType != UserTypes.tenant ||
              s.path != "/housing/groups"
            ) {
              _module.submodules.push(s);
            }
          }
        }

        if (_module.submodules.length > 0) {
          this.modules.push(_module);
        }
      }
    }
  }
}
