import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

import * as moment from "moment";

/* Datetime component with native/material components
   > Control IN: Datetime in string with UTC timezone to local timezone in inputs
   > Control OUT: Inputs in local timezone to object moment in UTC timezone
*/
@Component({
  selector: "mat-datetime-picker",
  templateUrl: "./mat-datetime-picker.component.html",
  styleUrls: ["./mat-datetime-picker.component.scss"],
})
export class MatDatetimePickerComponent implements OnDestroy {
  private _value: string;
  private _editable: Boolean = true;
  private _required: Boolean = true;
  private _currentDatetime: Boolean = false;

  @Input() set value(value: string) {
    this._value = value;

    const _moment = moment(value).local();

    this.timeForm.patchValue({
      date: _moment.format("YYYY-MM-DD"),
      time: _moment.format("HH:mm"),
    });
  }
  get value(): string {
    return this._value;
  }

  @Input() set editable(value: Boolean) {
    this._editable = value;

    if (!value) this.timeForm.disable();
    else this.timeForm.enable();
  }
  get editable(): Boolean {
    return this._editable;
  }

  @Input() set required(value: Boolean) {
    this._required = value;

    if (this._required) {
      this.timeForm.get("date").setValidators([Validators.required]);
      this.timeForm.get("date").updateValueAndValidity();
      this.timeForm.get("time").setValidators([Validators.required]);
      this.timeForm.get("time").updateValueAndValidity();
    } else {
      this.timeForm.get("date").clearValidators();
      this.timeForm.get("date").updateValueAndValidity();
      this.timeForm.get("time").clearValidators();
      this.timeForm.get("time").updateValueAndValidity();
    }
  }
  get required(): Boolean {
    return this._required;
  }

  @Input() set currentDatetime(value: Boolean) {
    this._currentDatetime = value;

    if (!this.value && this.currentDatetime) {
      const _currentMoment: moment.Moment = moment();

      this.timeForm.get("date").patchValue(_currentMoment.format("YYYY-MM-DD"));
      this.timeForm.get("date").updateValueAndValidity();
      this.timeForm.get("time").patchValue(_currentMoment.format("HH:mm"));
      this.timeForm.get("time").updateValueAndValidity();
    }
  }
  get currentDatetime(): Boolean {
    return this._currentDatetime;
  }

  @Input() defaultDatetime: boolean = true;
  @Input() name: string;

  @Output() formGroupEvent = new EventEmitter<moment.Moment>();

  protected _onDestroy = new Subject<void>();

  timeForm: FormGroup = this.formBuilder.group({
    date: [{ value: null, disabled: false }, []],
    time: [{ value: null, disabled: false }, []],
  });

  constructor(private formBuilder: FormBuilder) {
    this.timeForm.valueChanges
      .pipe(takeUntil(this._onDestroy), debounceTime(250))
      .subscribe((value) => {
        const _datetime = moment(value.date)
          .add(moment.duration(value.time))
          .utc();
        this.formGroupEvent.emit(_datetime);
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
