import { Logger } from "@core";
import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { PermissionService } from "@app/@core/services/permission.service";

const log = new Logger("PermissionGuard");

@Injectable({
  providedIn: "root",
})
export class PermissionGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private permissionService: PermissionService,
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    return this.permissionMonitor(_route, state);
  }

  canActivateChild(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    return this.permissionMonitor(_route, state);
  }

  async permissionMonitor(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    try {
      const permission: boolean =
        await this.permissionService.checkPermissionRoute(state.url);
      log.debug(`(permission: ${permission})`, state.url);

      if (!permission) {
        this.router.navigate(["restriction/permission"]);
        return false;
      }

      return true;
    } catch (error) {
      log.debug("(permission: error)", error);
      this.router.navigate(["restriction/permission"]);

      return false;
    }
  }
}
