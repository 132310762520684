import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { Logger } from "../logger.service";

import { MatDialog } from "@angular/material/dialog";

import { Auth, CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";

const log = new Logger("AWSAuthService");

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

/**
Amplify’s Auth category publishes in the auth channel when signIn, signUp, and
signOut events happen. You can listen and act upon those event notifications.
**/
Hub.listen("auth", (data) => {
  switch (data.payload.event) {
    case "signIn":
      log.info("user signed in");
      break;
    case "signUp":
      log.info("user signed up");
      break;
    case "signOut":
      log.info("user signed out");
      break;
    case "signIn_failure":
      log.info("user sign in failed");
      // We set the cookie only if we recieve a provider auth error (google in this case)
      if (data.payload.data.message.includes("PROVIDER_AUTH_ERROR")) {
        localStorage.setItem("aws-amplify-federaSignInError", "true");
      }
      break;
    case "configured":
      log.info("the Auth module is configured");
  }
});

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: "root",
})
export class AWSAuthService {
  public currentUser: any = {};
  public isActiveSession: BehaviorSubject<boolean | null> = new BehaviorSubject(
    null,
  );

  constructor(
    private router: Router,
    private dialogRef: MatDialog,
  ) {}

  /**
   * Logs out the user info
   * @return The auth cognito info user
   */
  currentUserInfo(): Promise<any> {
    const _promise: Promise<any> = Auth.currentUserInfo();

    // Logout in revoke remote session
    _promise.then(
      (result) => {
        this.currentUser = { ...this.currentUser, ...result };
        if (result) this.isActiveSession.next(true);
      },
      (error) => {
        log.error(">>> [MONITOR REVOKE]", error);
      },
    );

    return _promise;
  }

  /**
   * Logs out the user session
   * @return The auth cognito info session (CognitoUserSession)
   */
  currentSession(): Promise<any> {
    return Auth.currentSession();
  }

  /**
   * Logs out the user and clear credentials.
   * @return The auth cognito authenticated user info (CognitoUser)
   */
  currentAuthenticatedUser(): Promise<any> {
    return Auth.currentAuthenticatedUser();
    // TODO: Implement force refresh
    // return Auth.currentAuthenticatedUser({bypassCache: true});
  }

  /**
   * Logs out the user and clear credentials.
   * @return The auth cognito info user
   */
  completeNewPassword(user: any, newPassword: string): Promise<any> {
    return Auth.completeNewPassword(user, newPassword);
  }

  /**
   * Logs out the user and clear credentials.
   * @return The auth cognito info user
   */
  changePassword(oldPassword: string, newPassword: string): Promise<any> {
    return Auth.currentAuthenticatedUser().then((user) => {
      return Auth.changePassword(user, oldPassword, newPassword);
    });
  }

  /**
   * Logs out the user and clear credentials.
   * @return The auth cognito info user
   */
  forgotPassword(username: string): Promise<any> {
    return Auth.forgotPassword(username);
  }

  /**
   * Logs out the user and clear credentials.
   * @return The auth cognito info user
   */
  forgotPasswordSubmit(
    username: string,
    code: string,
    newPassword: string,
  ): Promise<any> {
    return Auth.forgotPasswordSubmit(username, code, newPassword);
  }

  /**
   * Authenticate user.
   * @param context The login parameters.
   * @return The auth cognito login resolver
   */
  login(context: LoginContext): Promise<any> {
    Auth.configure({
      storage: context.remember ? localStorage : sessionStorage,
    });

    return Auth.signIn(context);
  }

  /**
   * Authenticate user.
   * @param context The login parameters.
   * @return The auth cognito login resolver
   */
  federatedSignIn(provider: string): Promise<any> {
    if (provider == "google")
      return Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
  }

  /**
   * Confirmate user.
   * @param username The login username
   * @param code Verification code received by email
   * @return The auth cognito login resolver
   */
  confirmSignUp(username: string, code: string): Promise<any> {
    return Auth.confirmSignUp(username, code);
  }

  /**
   * Resend confirmate code.
   * @param username The login username
   * @return The auth cognito login resolver
   */
  resendSignUp(username: string): Promise<any> {
    return Auth.resendSignUp(username);
  }

  /**
   * Update an authenticated users' attributes
   * @param user: CognitoUser object
   * @attributes: Object {key: value} with attributes
   * @return The auth cognito info user
   */
  updateUserAttributes(user: any, attributes: any): Promise<any> {
    return Auth.updateUserAttributes(user, attributes);
  }

  /**
   * Logs out the user and clear credentials.
   * @return The auth cognito logout resolver
   */
  logout(): Promise<any> {
    const result: Promise<any> = Auth.signOut();

    result.then(() => {
      this.currentUser = {};
      this.isActiveSession.next(false);

      // Delete all dialogs in current view
      this.dialogRef.closeAll();
      this.router.navigate(["/auth"], { replaceUrl: true });
    });

    return result;
  }

  /**
   * Generate random password
   * @return The string password
   */
  generatePassword() {
    let result = "";
    let uppercases = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let lowercases = "abcdefghijklmnopqrstuvwxyz";
    let numbers = "0123456789";

    result += uppercases.charAt(Math.floor(Math.random() * uppercases.length));
    result += numbers.charAt(Math.floor(Math.random() * numbers.length));
    result += lowercases.charAt(Math.floor(Math.random() * lowercases.length));
    result += ".";
    result += numbers.charAt(Math.floor(Math.random() * numbers.length));
    result += lowercases.charAt(Math.floor(Math.random() * lowercases.length));
    result += uppercases.charAt(Math.floor(Math.random() * uppercases.length));
    result += "#";
    result += numbers.charAt(Math.floor(Math.random() * numbers.length));
    result += uppercases.charAt(Math.floor(Math.random() * uppercases.length));
    result += lowercases.charAt(Math.floor(Math.random() * lowercases.length));

    return result;
  }
}
