import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Logger } from "@core";

import { COLLETION_MIMETYPES } from "./files-mimetypes";

const log = new Logger("FilesUploadComponent");

export interface FileUpload {
  id?: number;
  path?: string;
  name: string;
  size: number;
  url: string;
  mimetype?: string;
  file: File;
}

@Component({
  selector: "app-files-upload",
  templateUrl: "./files-upload.component.html",
  styleUrls: ["./files-upload.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilesUploadComponent),
      multi: true,
    },
  ],
})
export class FilesUploadComponent implements OnInit, ControlValueAccessor {
  private _uploadedFiles: any[] = [];

  @Input() set uploadedFiles(value: any[]) {
    this._uploadedFiles = value;
    this.loadFilesList();
  }
  get uploadedFiles(): any[] {
    return this._uploadedFiles;
  }

  @Input() title: string = "upload-files.title";
  @Input() subtitle: string = "upload-files.subtitle";
  @Input() buttonUpload: string = "upload-files.upload";
  @Input() buttonReset: string = "upload-files.reset";
  @Input() showRestrictions: string[] = [
    "fileFormatsRestriction",
    "fileItemsRestriction",
    "fileSizeRestriction",
  ];
  @Input() showDropzone: boolean = true;
  @Input() showActions: boolean = true;
  @Input() editable: boolean = true;
  @Input() hideFiles: boolean = false;
  @Input() oneImage: boolean = false;
  @Input() fileItemsRestriction: number = 10;
  @Input() fileSizeRestriction: number = 10; // MB
  @Input() fileFormatsRestriction: string[] = [".jpg", ".jpeg", ".png", ".pdf"]; // .jpg, .png, .pdf...

  @Output() filesEvent = new EventEmitter<FileUpload[]>(); // Notification auto
  @Output() uploadEvent = new EventEmitter<FileUpload[]>(); // Notification manual > button upload
  @Output() deleteEvent = new EventEmitter<FileUpload>(); // Notification manual > button delete

  @ViewChild("fileDropReference", { static: false })
  fileDropElement: ElementRef;
  files: FileUpload[] = [];

  value: File[] = [];
  inDropzone: boolean = false;

  constructor() {}

  onChange: any = () => {};
  onTouch: any = () => {};

  ngOnInit(): void {
    // this.loadFilesList();
  }

  writeValue(value: File[]): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  fileBrowseHandler(files: File[]) {
    this.prepareFilesList(files);

    this.inDropzone = false;
  }

  deleteFile(index: number) {
    const _file = this.files.splice(index, 1);
    this.fileListChanged();

    if (_file[0].id) this.deleteEvent.emit(_file[0]);
  }

  resetFiles() {
    this.files = [];
  }

  sendFiles() {
    this.uploadEvent.emit(this.files);
  }

  prepareFilesList(filesToPrepare: File[]) {
    // this.files = [];
    for (const file of filesToPrepare) {
      const _file = {} as FileUpload;
      const _reader = new FileReader();

      const _mimetype = this.searchFileMimeType(file.type);

      _file.file = file;
      _file.name = file.name;
      _file.size = file.size;
      // _file.url = _mimetype.icon;

      // Overwrite with preview
      // if (_mimetype.preview && this.oneImage) {
      //   _reader.readAsDataURL(file);
      //   _reader.onload = () => {
      //     _file.url = _reader.result.toString();
      //   };
      // }

      // Check file size
      if (_file.size < this.fileSizeRestriction * 1024 * 1024)
        this.files.push(_file);

      // Check file items
      if (this.files.length > this.fileItemsRestriction) {
        log.info("Exced number files > Delete first queue file");
        this.deleteFile(0);
      }

      this.fileListChanged();
    }

    this.filesEvent.emit(this.files);
    this.fileDropElement.nativeElement.value = "";
  }

  loadFilesList() {
    this.files = [];

    for (const file of this.uploadedFiles) {
      const _file = {} as FileUpload;

      const _mimetype = this.searchFileMimeType(file.file_type);

      _file.id = file.id;
      _file.file = undefined;
      _file.name = file.file_name;
      _file.size = file.file_size;
      _file.path = file.file_path_signed;
      _file.url = _mimetype.icon;
      _file.mimetype = _mimetype.icon;

      // Overwrite with preview
      if (_mimetype.preview) {
        _file.url = file.file_path_signed;
      }

      this.files.push(_file);
    }
  }

  fileListChanged() {
    const fileList = this.getFileList();
    this.value = fileList;
    this.onTouch();
    this.onChange(fileList);
  }

  getFileList(): File[] {
    const fileList: File[] = [];
    for (const file of this.files) {
      fileList.push(file.file);
    }
    return fileList;
  }

  formatBytes(bytes: number, decimals: number = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  onDropzoneOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.inDropzone = true;
  }

  onDropzoneOut(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.inDropzone = false;
  }

  searchFileMimeType(type: string) {
    let mimeType = COLLETION_MIMETYPES.find((mime) =>
      mime.mimetypes.some((item) => item === type || item === "*"),
    );

    return mimeType;
  }

  onUrlError(event: any) {
    event.target.src = "assets/images/documents/image.svg";
  }
}
