import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { AWSAuthService } from "@app/@core/services/aws/auth.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() isMobile: Boolean = false;

  @Output() toggleSidenavPrimaryEvent: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() toggleSidenavSecundaryEvent: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() profileChangePasswordEvent: EventEmitter<void> =
    new EventEmitter<void>();

  currentUser: Promise<any>;

  constructor(
    private router: Router,
    public titleService: Title,
    private awsAuthService: AWSAuthService,
  ) {}

  ngOnInit() {
    this.currentUser = this.awsAuthService.currentUserInfo();
  }

  get title() {
    return this.titleService.getTitle();
  }

  logout() {
    this.awsAuthService
      .logout()
      .then(() => this.router.navigate(["/auth"], { replaceUrl: true }));
  }
}
