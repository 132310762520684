<mat-toolbar class="header-container">
  <button
    class="menu-button"
    mat-icon-button
    (click)="toggleSidenavPrimaryEvent.emit()"
    fxHide.gt-sm
  >
    <mat-icon>menu</mat-icon>
  </button>

  <h1>{{ title | capitalize }}</h1>
  <div fxFlex></div>

  <div fxLayout="row" fxLayoutAlign="center center">
    <!-- LANGUAGE -->
    <!-- <app-language-selector icon="true"></app-language-selector> -->

    <!-- PROFILE -->
    <div class="header-profile-container">
      <button mat-icon-button [matMenuTriggerFor]="userMenu">
        <!-- <div fxLayout="row" fxLayoutGap="0.25rem" fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutAlign="center center" class="header-user-profile">
            <span><strong>{{ (currentUser | async)?.username[0] | uppercase }}</strong></span>
          </div>
          <span *ngIf="!isMobile">{{ (currentUser | async)?.username }}</span>
        </div> -->
        <div fxLayout="row" fxLayoutGap="0.25rem" fxLayoutAlign="center center">
          <!-- <div fxLayout="row" fxLayoutAlign="center center" class="header-user-profile"> -->
          <!-- <span><strong>{{ (currentUser | async)?.attributes.name[0] | uppercase }}</strong></span> -->

          <mat-icon mat-list-icon color="primary">account_circle</mat-icon>
          <!-- </div> -->
          <!-- <span *ngIf="!isMobile">{{ (currentUser | async)?.attributes.name }}</span> -->
        </div>
      </button>
    </div>

    <!-- RIGHT SIDENAV (Testing) -->
    <!--  <button mat-icon-button (click)="this.toggleSidenavSecundaryEvent.emit()">
      <mat-icon>align_horizontal_right</mat-icon>
    </button> -->

    <!-- PROFILE > Menu -->
    <div class="header-profile-menu-container">
      <mat-menu #userMenu="matMenu">
        <!--  <button mat-menu-item color="primary">
          <mat-icon mat-list-icon>account_circle</mat-icon>
          <span>{{ (currentUser | async)?.username }}</span>
        </button> -->
        <button mat-menu-item color="primary">
          <mat-icon mat-list-icon>mail</mat-icon>
          <span>{{ (currentUser | async)?.attributes?.email }}</span>
        </button>
        <mat-divider></mat-divider>
        <!--  <button mat-menu-item>
          <mat-icon mat-list-icon>person</mat-icon>
          <span transate>Profile</span>
        </button>
        <button mat-menu-item>
          <mat-icon mat-list-icon>settings</mat-icon>
          <span transate>Configuration</span>
        </button> -->
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="profileChangePasswordEvent.emit()">
          <mat-icon mat-list-icon>lock</mat-icon>
          <span transate>Change Password</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon mat-list-icon>logout</mat-icon>
          <span transate>Logout</span>
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
