export interface Base {
  id?: number;
  created_on?: Date;
  updated_on?: Date;
}

export enum BaseFields {
  Id = "id",
  CreatedOn = "created_on",
  UpdatedOn = "updated_on",
}
