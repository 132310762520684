import { Company } from "./company";
import { User } from "./user";

export interface Technician extends User {
  id?: number;
  company_id: number;
  company?: Company;
  address?: string;
  workstation?: string;
  department?: string;
}

export enum TechnicianFields {
  id = "id",
  address = "address",
  workstation = "workstation",
  department = "department",
}
