import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { RequestParams } from "@app/@shared/models/_results";
import { HousingGroups } from "@app/@shared/models/housing";
import { ReplaySubject, Subject } from "rxjs";
import { debounceTime, filter, map, takeUntil } from "rxjs/operators";
import { finalize } from "rxjs/operators";
import { NotificationService } from "@app/@shared/services/notification.service";
import { Logger } from "@app/@core";
import { HousingService } from "../../../../housing/housing.service";

@Component({
  selector: "app-housing-group-selector",
  templateUrl: "./housing-group-selector.component.html",
  styleUrls: ["./housing-group-selector.component.scss"],
})
export class HousingGroupSelectorComponent implements OnInit, OnDestroy {
  log = new Logger(HousingGroupSelectorComponent.name);

  private _value: string;
  private _editable: boolean;
  private _label: string;
  private _required: boolean;
  private _availableGroups: HousingGroups[];
  private _availableControl: boolean = false;

  @Input() set value(value: string) {
    this._value = value;

    const _valueId = this.housingGroupControl.value
      ? this.housingGroupControl.value.id
      : undefined;

    if (value && value != _valueId) {
      this.getGroup(value);
    } else if (!value) {
      this.getGroups();
    }
  }
  get value(): string {
    return this._value;
  }

  @Input() set availableGroups(groups: HousingGroups[]) {
    if (groups) {
      this._availableControl = true;
      this.availableGroups = groups;
    } else {
      this._availableGroups = [];
    }
  }

  @Input() set editable(value: boolean) {
    this._editable = value;
    value
      ? this.housingGroupControl.enable()
      : this.housingGroupControl.disable();
  }
  get editable(): boolean {
    return this._editable;
  }

  @Input() set label(value: string) {
    this._label = value;
  }
  get label(): string {
    return this._label;
  }

  @Input() set required(value: boolean) {
    let _validators = [];

    if (value) _validators.push(Validators.required);

    this.housingGroupControl.clearValidators();
    this.housingGroupControl.setValidators(_validators);
  }
  get required(): boolean {
    return this._required;
  }

  @Output() formControlEvent = new EventEmitter<FormControl>();

  /** control for the selected entity for selection */
  public housingGroupControl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword selection */
  public housingGroupFilterControl: FormControl = new FormControl();

  /** list of entities filtered by search keyword */
  public groupsFiltered: ReplaySubject<HousingGroups[]> = new ReplaySubject<
    HousingGroups[]
  >(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  requestHousingGroupsParams = new RequestParams();
  isLoading: boolean = false;

  constructor(
    private housingService: HousingService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {
    // listen for search field value changes
    this.housingGroupFilterControl.valueChanges
      .pipe(
        filter((search) => !!search),
        takeUntil(this._onDestroy),
        debounceTime(250),
        map((search) => {
          this.requestHousingGroupsParams.filter = [
            { field: "address", value: search },
          ];
          this.getGroups();
        }),
        takeUntil(this._onDestroy),
      )
      .subscribe();

    this.housingGroupControl.valueChanges
      .pipe(takeUntil(this._onDestroy), debounceTime(250))
      .subscribe(() => {
        this.formControlEvent.emit(this.housingGroupControl);
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getGroups() {
    this.isLoading = true;

    this.housingService
      .getGroups(this.requestHousingGroupsParams)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (result) => {
          if (this._availableControl) {
            result.items = this._availableGroups;
          }
          this.groupsFiltered.next(result.items);
        },
        error: (error) => {
          this.log.error("Error getGroups: ", error);
          this.notificationService.open("app.messages.operation_failed");
        },
      });
  }

  getGroup(groupId: string) {
    this.isLoading = true;
    this.housingService
      .getGroupById("id", parseInt(groupId))
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (result) => {
          this.groupsFiltered.next([result]);
          this.housingGroupControl.setValue(result);
          this.housingGroupControl.updateValueAndValidity();
        },
        error: (error) => {
          this.notificationService.open("Error loading user list", "error");
        },
      });
  }
}
