import { NgModule } from "@angular/core";
import { SharedModule } from "@shared";

import { AuthRoutingModule } from "./auth-routing.module";

import { PasswordRecoverDialogComponent } from "./_dialogs/password-recover-dialog/password-recover-dialog.component";
import { AuthComponent } from "./auth.component";
import { AuthConfirmationAccountComponent } from "./confirmation-account/confirmation-account.component";
import { AuthConfirmationComponent } from "./confirmation/auth-confirmation.component";
import { AuthForgotComponent } from "./forgot/auth-forgot.component";
import { AuthLoginComponent } from "./login/login.component";
import { PasswordChangeComponent } from "./password-change/password-change.component";
import { PasswordHintsComponent } from "./password-hints/password-hints.component";
import { PasswordRefactorComponent } from "./password-refactor/password-refactor.component";
import { AuthRegisterComponent } from "./register/register.component";
import { RegisterUserLoginComponent } from "./_dialogs/register-user-login/register-user-login.component";

@NgModule({
  imports: [AuthRoutingModule, SharedModule],
  declarations: [
    AuthComponent,

    AuthLoginComponent,
    AuthRegisterComponent,
    PasswordChangeComponent,
    PasswordRefactorComponent,
    AuthConfirmationAccountComponent,
    PasswordHintsComponent,
    AuthConfirmationComponent,
    AuthForgotComponent,
    PasswordRecoverDialogComponent,
    RegisterUserLoginComponent,
  ],
  providers: [],
})
export class AuthModule {}
