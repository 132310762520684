import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { ServiceWorkerModule } from "@angular/service-worker";
import { TranslateModule } from "@ngx-translate/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { CoreModule } from "@core";
import { SharedModule } from "@shared";
import { ShellModule } from "./shell/shell.module";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { APP_SETTINGS } from "./app.settings";
import { environment } from "@env/environment";
import { AuthModule } from "./auth/auth.module";

import { GoogleMapsModule } from "@angular/google-maps";
import { DashboardModule } from "./dashboard/dashboard.module";

@NgModule({
  imports: [
    BrowserModule,
    ServiceWorkerModule.register("./ngsw-worker.js", {
      enabled: environment.serviceWorker,
    }),
    HttpClientModule,
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    ShellModule,
    AppRoutingModule,
    AuthModule,
    DashboardModule,
    GoogleMapsModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [APP_SETTINGS],
  bootstrap: [AppComponent],
})
export class AppModule {}
