<div fxLayout="column" fxLayoutAlign="center center" fxFlex>
  <div fxLayoutAlign="center" style="margin: 3rem">
    <img
      class="logo"
      fxLayoutAlign="center center"
      src="assets/images/logo_urvial_blanco.png"
      style="width: 350px"
      alt="URVIAL logo"
    />
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" fxFlex>
    <mat-card fxFlexAlign="center center">
      <div fxFlex fxFill fxLayout="column" fxLayoutGap="1.0rem">
        <div fxLayout="row" fxLayoutAlign="center">
          <mat-card-title class="register-row-text">
            {{ "auth.recover password.title" | translate | capitalize }}
          </mat-card-title>
        </div>

        <mat-divider></mat-divider>

        <div
          fxLayout="row"
          fxLayoutAlign="center"
          fxLayoutGap="0.5rem"
          class="register-row"
        >
          <form
            *ngIf="!inFinalProcess"
            [formGroup]="passwordRecoverForm"
            fxFlex="100"
            fxLayout="column"
          >
            <div class="register-row-text">
              Introducir email para enviar código de recuperación:
            </div>

            <div fxFlex="0.5rem"></div>

            <mat-form-field>
              <mat-label>{{
                "users.user.email" | translate | capitalize
              }}</mat-label>
              <input matInput [formControlName]="'username'" />
              <mat-error
                *ngIf="
                  passwordRecoverForm.controls.username.errors?.required &&
                  passwordRecoverForm.controls.username.touched
                "
              >
                <span>{{
                  "app.forms.is required"
                    | translate
                      : { item: ("users.user.email" | translate | capitalize) }
                }}</span>
              </mat-error>
            </mat-form-field>

            <div fxFlex="0.5rem"></div>
            <div fxFlex="0.5rem"></div>

            <button
              mat-flat-button
              color="primary"
              [disabled]="passwordRecoverForm.invalid"
              (click)="requestVerificationCode()"
            >
              {{ "auth.recover password.send code" | translate | capitalize }}
            </button>

            <div fxFlex="0.5rem"></div>

            <button
              mat-flat-button
              class="existCode"
              (click)="profileRecoverPassword()"
            >
              {{ "auth.recover password.exist code" | translate | capitalize }}
            </button>

            <div fxFlex="0.5rem"></div>
            <mat-divider></mat-divider>
            <div fxFlex="0.5rem"></div>

            <button mat-flat-button routerLink="/auth/login" color="primary">
              <mat-icon> keyboard_return </mat-icon>
              {{ "app.actions.return" | translate | capitalize }}
            </button>
          </form>

          <form
            *ngIf="inFinalProcess"
            [formGroup]="passwordRecoverForm"
            fxFle
            fxLayout="column"
          >
            <mat-form-field>
              <mat-label>{{
                "users.user.password" | translate | capitalize
              }}</mat-label>
              <input
                matInput
                [type]="hidePassword ? 'password' : 'text'"
                [formControlName]="'password_replace'"
              />
              <div fxLayout="row" matSuffix>
                <mat-icon (click)="hidePassword = !hidePassword">{{
                  hidePassword ? "visibility_off" : "visibility"
                }}</mat-icon>
              </div>
              <mat-error
                *ngIf="
                  passwordRecoverForm.controls.password_replace.errors
                    ?.required &&
                  passwordRecoverForm.controls.password_replace.touched
                "
              >
                <span>{{
                  "app.forms.is required"
                    | translate
                      : {
                          item: ("users.user.password" | translate | capitalize)
                        }
                }}</span>
              </mat-error>
            </mat-form-field>
            <app-password-hints
              [formControl]="passwordRecoverForm.controls.password_replace"
            ></app-password-hints>

            <button
              mat-flat-button
              color="primary"
              [disabled]="passwordRecoverForm.invalid"
              (click)="requestChangePassword()"
            >
              {{ "app.actions.accept" | translate | capitalize }}
            </button>
          </form>
        </div>
      </div>
    </mat-card>
  </div>
</div>
