import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from "@angular/forms";
import { Observable, of, timer } from "rxjs";
import { catchError, finalize, switchMap } from "rxjs/operators";
import { UsersService } from "@app/users/users.service";
import { RequestParams } from "../models/_results";
import { NotificationService } from "../services/notification.service";
import { Logger } from "@app/@core";
import { UserTypes } from "../models/user";

export class UniqueUsernameValidator {
  log = new Logger(UniqueUsernameValidator.name);
  constructor(private usersService: UsersService) {}
  private notificationService: NotificationService;
  isLoading: boolean = false;
  createValidator(currentUsername?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const username = control.value as string;
      if (currentUsername == username) {
        return of(null);
      }
      return timer(500).pipe(
        switchMap(() => {
          let requestParams = new RequestParams();
          requestParams.filter = [{ field: "dni__exact", value: username }];
          requestParams.fields = ["dni"];

          return this.usersService.getUsers(requestParams).pipe(
            finalize(() => (this.isLoading = false)),
            switchMap((result) => {
              return of(result.total > 0 ? { notUniqueUsername: true } : null);
            }),
            catchError((error) => {
              this.log.error("Error getting users: ", error);
              this.notificationService.open("app.messages.operation_failed");
              return of(null); // Return null to indicate that the validation passed with an error
            }),
          );
        }),
        catchError(() => of(null)), // Catch any error during the timer and return null
      );
    };
  }
}
