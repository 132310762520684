import { Pipe, PipeTransform } from "@angular/core";
import { AbstractControl, Validators } from "@angular/forms";

@Pipe({ name: "hasRequiredValidator" })
export class HasRequiredValidatorPipe implements PipeTransform {
  transform(value: AbstractControl | null, controlName?: string): boolean {
    const control = controlName ? value?.get(controlName) : value;
    return !!control?.hasValidator(Validators.required);
  }
}
