<div fxLayout="column">
  <mat-form-field fxFlex color="primary">
    <mat-label>{{
      (label ? label : "companies.title") | translate | capitalize
    }}</mat-label>
    <mat-select #matSelector [formControl]="companyControl" [multiple]="false">
      <mat-select-trigger>
        <span>{{ companyControl?.value?.name }}</span>
      </mat-select-trigger>
      <mat-option>
        <ngx-mat-select-search
          [formControl]="companyFilterControl"
          [searching]="isLoading"
          placeholderLabel="{{ 'app.actions.search' | translate | capitalize }}"
          noEntriesFoundLabel="{{ 'any result' | translate }}"
        >
          <mat-icon ngxMatSelectSearchClear class="mat-select-search-button"
            >close</mat-icon
          >
        </ngx-mat-select-search>
      </mat-option>
      <mat-option
        *ngFor="let company of companyFiltered | async"
        [value]="company"
      >
        <div fxLayout="column">
          <span>{{ company.name | capitalize }}</span>
          <span class="mat-suboption-text">{{
            company.address | capitalize
          }}</span>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
