<form [formGroup]="userForm">
  <div fxLayout="column" fxLayoutGap="0.5rem" fxLayoutGap.xs="0rem">
    <mat-card class="content-fixed">
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutGap="1.0rem"
        fxLayoutGap.xs="0rem"
      >
        <!-- Tipo -->
        <mat-form-field fxFlex="50%" fxFlex.xs="100%" appearance="outline">
          <mat-label>{{
            "users.user.type.title" | translate | capitalize
          }}</mat-label>
          <mat-select
            formControlName="type"
            (selectionChange)="onUserTypeChange($event)"
            [value]="userForm.controls.type?.value"
          >
            <mat-option
              *ngIf="
                currentUserType == UserTypes.urvial_administrator ||
                currentUserType == UserTypes.company_admin
              "
              [value]="UserTypes.technician"
              >{{
                "users.user.type.technician" | translate | capitalize
              }}</mat-option
            >
            <mat-option
              *ngIf="currentUserType == UserTypes.urvial_administrator"
              [value]="UserTypes.company_admin"
              >{{
                "users.user.type.company_admin" | translate | capitalize
              }}</mat-option
            >
            <mat-option
              *ngIf="currentUserType == UserTypes.urvial_administrator"
              [value]="UserTypes.tenant"
              >{{
                "users.user.type.tenant" | translate | capitalize
              }}</mat-option
            >
            <mat-option
              *ngIf="currentUserType == UserTypes.urvial_administrator"
              [value]="UserTypes.urvial_administrator"
              >{{
                "users.user.type.urvial_administrator" | translate | capitalize
              }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="userForm.controls.type.errors?.required">
            <span>{{
              "app.forms.is required"
                | translate
                  : { item: ("users.user.type.title" | translate | capitalize) }
            }}</span>
          </mat-error>
        </mat-form-field>

        <!-- Nombre -->
        <mat-form-field fxFlex="30%" fxFlex.xs="100%" appearance="outline">
          <mat-label>{{
            "users.user.name" | translate | capitalize
          }}</mat-label>
          <input
            matInput
            placeholder="{{ 'users.user.name' | translate | capitalize }}"
            formControlName="name"
            required
          />
          <mat-error *ngIf="userForm.controls.name.errors?.maxlength">
            <span>{{
              "app.forms.is too long"
                | translate
                  : { item: ("users.user.name" | translate | capitalize) }
            }}</span>
          </mat-error>
          <mat-error *ngIf="userForm.controls.name.errors?.required">
            <span>{{
              "app.forms.is required"
                | translate
                  : { item: ("users.user.name" | translate | capitalize) }
            }}</span>
          </mat-error>
        </mat-form-field>

        <!-- Apellidos -->
        <mat-form-field fxFlex="50%" fxFlex.xs="100%" appearance="outline">
          <mat-label>{{
            "users.user.surname" | translate | capitalize
          }}</mat-label>
          <input
            matInput
            placeholder="{{ 'users.user.surname' | translate | capitalize }}"
            formControlName="surname"
            required
          />
          <mat-error *ngIf="userForm.controls.surname.errors?.maxlength">
            <span>{{
              "app.forms.is too long"
                | translate
                  : { item: ("users.user.surname" | translate | capitalize) }
            }}</span>
          </mat-error>
          <mat-error *ngIf="userForm.controls.surname.errors?.required">
            <span>{{
              "app.forms.is required"
                | translate
                  : { item: ("users.user.surname" | translate | capitalize) }
            }}</span>
          </mat-error>
        </mat-form-field>

        <!-- Dni -->
        <mat-form-field fxFlex="50%" fxFlex.xs="100%" appearance="outline">
          <mat-label>{{ "users.user.dni" | translate | capitalize }}</mat-label>
          <input
            matInput
            placeholder="{{ 'users.user.dni' | translate | capitalize }}"
            formControlName="dni"
            required
          />
          <mat-error *ngIf="userForm.controls.dni.errors?.maxlength">
            <span>{{
              "app.forms.is too long"
                | translate
                  : { item: ("users.user.dni" | translate | capitalize) }
            }}</span>
          </mat-error>
          <mat-error *ngIf="userForm.controls.dni.errors?.required">
            <span>{{
              "app.forms.is required"
                | translate
                  : { item: ("users.user.dni" | translate | capitalize) }
            }}</span>
          </mat-error>
          <mat-error *ngIf="userForm.controls.dni.errors?.notUniqueUsername">
            <span>{{
              "app.forms.is repeated"
                | translate
                  : { item: ("users.user.username" | translate | capitalize) }
            }}</span>
          </mat-error>
          <mat-error *ngIf="userForm.controls.dni.errors?.pattern">
            <span>{{
              "app.forms.is invalid"
                | translate
                  : { item: ("users.user.username" | translate | capitalize) }
            }}</span>
          </mat-error>
        </mat-form-field>

        <!-- Teléfono -->
        <mat-form-field fxFlex="25%">
          <mat-label
            >{{ "users.user.phone" | translate | capitalize }} *</mat-label
          >
          <ngx-mat-intl-tel-input
            formControlName="phone"
            [onlyCountries]="['es']"
            [enablePlaceholder]="true"
            cdkFocusInitial
          ></ngx-mat-intl-tel-input>
          <mat-error
            *ngIf="
              userForm.controls.phone.errors?.required &&
              userForm.controls.phone.touched
            "
          >
            <span>{{
              "app.forms.is required"
                | translate
                  : { item: ("users.user.phone" | translate | capitalize) }
            }}</span>
          </mat-error>
          <mat-error
            *ngIf="
              userForm.controls.phone.errors?.maxlength ||
              (userForm.controls.phone.errors?.minlength &&
                userForm.controls.phone.touched)
            "
          >
            <span>{{
              "app.forms.is invalid"
                | translate
                  : { item: ("users.user.phone" | translate | capitalize) }
            }}</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayoutAlign="0.5rem">
        <h3>{{ "users.register.access info" | translate | capitalize }}</h3>

        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutGap="0.5rem"
          fxLayoutGap.xs="0rem"
        >
          <!-- Email -->
          <mat-form-field fxFlex fxFlex.xs="100%" appearance="outline">
            <mat-label>{{
              "users.user.email" | translate | capitalize
            }}</mat-label>
            <input
              matInput
              placeholder="{{ 'users.user.email' | translate | capitalize }}"
              formControlName="email"
            />
            <mat-error *ngIf="userForm.controls.email.errors?.maxlength">
              <span>{{
                "app.forms.is too long"
                  | translate
                    : { item: ("users.user.email" | translate | capitalize) }
              }}</span>
            </mat-error>
            <mat-error *ngIf="userForm.controls.email.errors?.email">
              <span>{{
                "app.forms.is invalid"
                  | translate
                    : { item: ("users.user.email" | translate | capitalize) }
              }}</span>
            </mat-error>
          </mat-form-field>

          <!-- Apellidos -->
          <mat-form-field
            fxFlex
            fxFlex.xs="100%"
            appearance="outline"
            *ngIf="false"
          >
            <mat-label>{{
              "users.user.username" | translate | capitalize
            }}</mat-label>
            <input
              matInput
              placeholder="{{ 'users.user.username' | translate | capitalize }}"
              formControlName="username"
              required
            />
            <mat-error *ngIf="userForm.controls.username.errors?.maxlength">
              <span>{{
                "app.forms.is too long"
                  | translate
                    : { item: ("users.user.username" | translate | capitalize) }
              }}</span>
            </mat-error>
            <mat-error *ngIf="userForm.controls.username.errors?.required">
              <span>{{
                "app.forms.is required"
                  | translate
                    : { item: ("users.user.username" | translate | capitalize) }
              }}</span>
            </mat-error>
          </mat-form-field>
        </div>

        <div
          *ngIf="type == UserTypes.tenant"
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutGap="0.5rem"
          fxLayoutGap.xs="0rem"
        >
          <!-- Contacto -->
          <mat-form-field fxFlex="25%" fxFlex.xs="100%">
            <mat-label>{{
              "tenants.tenant.contact" | translate | capitalize
            }}</mat-label>
            <input
              matInput
              placeholder="{{
                'tenants.tenant.contact' | translate | capitalize
              }}"
              formControlName="contact"
            />
            <mat-error *ngIf="userForm.controls.contact.errors?.maxlength">
              <span>{{
                "app.forms.is too long"
                  | translate
                    : {
                        item:
                          ("tenants.tenant.contact" | translate | capitalize)
                      }
              }}</span>
            </mat-error>
            <mat-error *ngIf="userForm.controls.contact.errors?.required">
              <span>{{
                "app.forms.is required"
                  | translate
                    : {
                        item:
                          ("tenants.tenant.contact" | translate | capitalize)
                      }
              }}</span>
            </mat-error>
          </mat-form-field>

          <!-- Código -->
          <mat-form-field fxFlex="20%" fxFlex.xs="100%">
            <mat-label>{{
              "tenants.tenant.code" | translate | capitalize
            }}</mat-label>
            <input
              matInput
              placeholder="{{ 'tenants.tenant.code' | translate | capitalize }}"
              formControlName="code"
            />
            <mat-error *ngIf="userForm.controls.code.errors?.maxlength">
              <span>{{
                "app.forms.is too long"
                  | translate
                    : { item: ("tenants.tenant.code" | translate | capitalize) }
              }}</span>
            </mat-error>
            <mat-error *ngIf="userForm.controls.code.errors?.required">
              <span>{{
                "app.forms.is required"
                  | translate
                    : { item: ("tenants.tenant.code" | translate | capitalize) }
              }}</span>
            </mat-error>
          </mat-form-field>

          <!-- Vivienda -->
          <app-multi-general-selector
            [formControlName]="'housing_ids'"
            [entity]="'housing'"
            [field_entity]="'street'"
            [showed_field]="'full_street'"
            [currentEntityList]="entity?.housing"
            [isDisabled]="
              this.entity?.status != userStatus.confirmed && !register
            "
            [fxShow]="
              includeHousingSelector &&
              currentUserType == UserTypes.urvial_administrator
            "
            fxFlex
            fxHide
          ></app-multi-general-selector>
        </div>

        <div
          *ngIf="
            type === UserTypes.technician ||
            type === UserTypes.company_admin ||
            currentUserType == UserTypes.company_admin
          "
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutGap="0.5rem"
          fxLayoutGap.xs="0rem"
        >
          <!-- Compañía -->
          <app-general-selector
            [formControlName]="'company_id'"
            [entity]="generalServices.company"
            [currentEntity]="selectedCompany"
            [field_entity]="'name'"
            [showed_field]="'name'"
            [isTouch]="touch"
            [isRequired]="
              userForm.controls.type.value == 'technician' ||
              userForm.controls.type.value == 'company_admin'
            "
            fxFlex
          >
          </app-general-selector>

          <!-- Dirección -->
          <mat-form-field fxFlex fxFlex.xs="100%">
            <mat-label>{{
              "companies.technician.address" | translate | capitalize
            }}</mat-label>
            <input
              matInput
              placeholder="{{
                'companies.technician.address' | translate | capitalize
              }}"
              formControlName="address"
            />
            <mat-error *ngIf="userForm.controls.address?.errors?.maxlength">
              <span>{{
                "app.forms.is too long"
                  | translate
                    : {
                        item:
                          ("companies.technician.address"
                          | translate
                          | capitalize)
                      }
              }}</span>
            </mat-error>
            <mat-error *ngIf="userForm.controls.address?.errors?.required">
              <span>{{
                "app.forms.is required"
                  | translate
                    : {
                        item:
                          ("companies.technician.address"
                          | translate
                          | capitalize)
                      }
              }}</span>
            </mat-error>
          </mat-form-field>

          <!-- Estación de trabajo -->
          <mat-form-field fxFlex fxFlex.xs="100%">
            <mat-label>{{
              "companies.technician.workstation" | translate | capitalize
            }}</mat-label>
            <input
              matInput
              placeholder="{{
                'companies.technician.workstation' | translate | capitalize
              }}"
              formControlName="workstation"
            />
            <mat-error *ngIf="userForm.controls.workstation?.errors?.maxlength">
              <span>{{
                "app.forms.is too long"
                  | translate
                    : {
                        item:
                          ("companies.technician.workstation"
                          | translate
                          | capitalize)
                      }
              }}</span>
            </mat-error>
            <mat-error *ngIf="userForm.controls.workstation?.errors?.required">
              <span>{{
                "app.forms.is required"
                  | translate
                    : {
                        item:
                          ("companies.technician.workstation"
                          | translate
                          | capitalize)
                      }
              }}</span>
            </mat-error>
          </mat-form-field>

          <!-- Departamento -->
          <mat-form-field fxFlex fxFlex.xs="100%">
            <mat-label>{{
              "companies.technician.department" | translate | capitalize
            }}</mat-label>
            <input
              matInput
              placeholder="{{
                'companies.technician.department' | translate | capitalize
              }}"
              formControlName="department"
            />
            <mat-error *ngIf="userForm.controls.department?.errors?.maxlength">
              <span>{{
                "app.forms.is too long"
                  | translate
                    : {
                        item:
                          ("companies.technician.department"
                          | translate
                          | capitalize)
                      }
              }}</span>
            </mat-error>
            <mat-error *ngIf="userForm.controls.department?.errors?.required">
              <span>{{
                "app.forms.is required"
                  | translate
                    : {
                        item:
                          ("companies.technician.department"
                          | translate
                          | capitalize)
                      }
              }}</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
  </div>
</form>
