import { Component, Input, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { Logger } from "@app/@core";

import { RequestParams, ResultPaginated } from "@app/@shared/models/_results";
import { User, UserStatus, UserTypes } from "@app/@shared/models/user";

import { UsersService } from "@app/users/users.service";
import { NotificationService } from "@app/@shared/services/notification.service";
import { FormGroup } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { ErrorNotificationService } from "@app/@shared/services/error_notification.service";

@Component({
  selector: "app-users-collection",
  templateUrl: "./users-collection.component.html",
  styleUrls: ["./users-collection.component.scss"],
})
export class UsersCollectionComponent implements OnInit {
  log = new Logger(UsersCollectionComponent.name);
  isLoading: boolean = false;
  userForm: FormGroup;
  private _filters: boolean = true;
  private _actionsDisabled: boolean = false;
  private _includedUsersType: UserTypes;
  private _itemId: number;
  requestUsersParams = new RequestParams();
  users: ResultPaginated<User>;
  UserStatus = UserStatus;

  displayedColumns: String[] = [];

  @Input() set filters(value: boolean) {
    this._filters = value;
  }
  get filters(): boolean {
    return this._filters;
  }

  @Input() set itemId(value: number) {
    this._itemId = value;
  }
  get itemId(): number {
    return this._itemId;
  }

  @Input() set userType(value: UserTypes) {
    this._includedUsersType = value;
  }
  get userType(): UserTypes {
    return this._includedUsersType;
  }

  @Input() set actionsDisabled(value: boolean) {
    this._actionsDisabled = value;
  }
  get actionsDisabled(): boolean {
    return this._actionsDisabled;
  }

  constructor(
    private usersService: UsersService,
    private notificationService: NotificationService,
    private errorNotificationService: ErrorNotificationService,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this._actionsDisabled == true) {
      this.displayedColumns = [
        "code",
        "type",
        "name",
        "email",
        "phone",
        "status",
      ];
    } else {
      this.displayedColumns = [
        "code",
        "type",
        "name",
        "email",
        "phone",
        "status",
        "actions",
      ];
    }
    if (
      this.userType === UserTypes.technician ||
      this.userType === UserTypes.company_admin
    ) {
      this.requestUsersParams.filter = [
        { field: "company_id", value: this.itemId.toString() },
      ];
      this.requestUsersParams.contain = [
        {
          field: "type",
          values: [UserTypes.company_admin, UserTypes.technician],
        },
      ];
    }

    if (this.userType === UserTypes.tenant) {
      this.requestUsersParams.filter = [
        { field: "type", value: this.userType },
      ];
      //this.requestUsersParams.entity = [{field: 'housing_id', value: this.itemId.toString()}]
    }

    this.requestUsersParams.order = [{ type: "desc", field: "created_on" }];
    this.getUsers();
  }

  getUsers() {
    this.isLoading = true;

    this.usersService
      .getUsers(this.requestUsersParams)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (result) => {
          this.users = result;
        },
        error: (error) => {
          this.log.error("Error get users: ", error);
          this.errorNotificationService.notification(error, "ErrorLoadingList");
        },
      });
  }

  onButtonUpdate(id: number) {
    this.router.navigate(["/users/detail", id]);
  }

  onChangeFilter(params: {}) {
    let queryString = params["query"];
    let type = params["type"];
    let status = params["status"];
    let group_id = params["group_id"];

    this.requestUsersParams.filter = [];
    this.requestUsersParams.entity = [];
    this.requestUsersParams.pagination.pageIndex = 0;

    this.requestUsersParams.query = {
      value: queryString,
      fields: ["name", "surname", "username", "email"],
    };

    if (type)
      this.requestUsersParams.filter.push({ value: type, field: "type" });

    if (status)
      this.requestUsersParams.filter.push({ value: status, field: "status" });

    if (group_id)
      this.requestUsersParams.entity.push({
        value: group_id,
        field: "housing_group_id",
      });

    this.getUsers();
  }

  onChangeQuery(queryString: string) {
    this.requestUsersParams.pagination.pageIndex = 0;
    this.requestUsersParams.query = {
      value: queryString,
      fields: ["name", "surname", "email"],
    };

    this.getUsers();
  }

  onChangePage(eventPage: PageEvent) {
    this.requestUsersParams.pagination = {
      pageIndex: eventPage.pageIndex,
      pageSize: eventPage.pageSize,
    };
    this.getUsers();
  }

  onRefresh() {
    this.requestUsersParams.filter = [];
    this.requestUsersParams.order = [{ type: "desc", field: "created_on" }];
    this.getUsers();
  }

  updateUserStatus(user: User, status: UserStatus) {
    const _user = Object.assign({}, user);
    _user.status = status;
    this.updateUser(_user);
  }

  updateUser(user: User) {
    this.isLoading = true;
    this.usersService
      .updateUser(user)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.log.info("User updated succesful");
          this.notificationService.open("SuccessFullyUpdate");
          this.getUsers();
        },
        error: (error) => {
          this.log.error("Error updating User", error);
          this.errorNotificationService.notification(
            error,
            "ErrorUpdatingData",
          );
        },
      });
  }
}
