import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";

import { RequestParams, ResultPaginated } from "@app/@shared/models/_results";

import {
  Worksheet,
  WorksheetFile,
  WorksheetFavorite,
  AuditWorksheets,
} from "@app/@shared/models/worksheet";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WorksheetsService {
  constructor(private httpClient: HttpClient) {}

  getWorksheetsAuditHistory(
    requestParams: RequestParams,
  ): Observable<ResultPaginated<AuditWorksheets>> {
    const params = requestParams.toHttpParams();
    return this.httpClient.get<ResultPaginated<AuditWorksheets>>(
      "/audit/worksheets",
      { params: params },
    );
  }

  setWorksheet(worksheet: Worksheet): Observable<Worksheet> {
    const url = "/worksheets";
    return this.httpClient.post<Worksheet>(url, worksheet);
  }

  // patchWorksheet(worksheet: Worksheet): Observable<Worksheet> {
  //   const url = '/worksheets';
  //   return this.httpClient.patch<Worksheet>(url, worksheet);
  // }

  updateWorksheet(worksheet: Worksheet): Observable<Worksheet> {
    const url = "/worksheets";
    return this.httpClient.patch<Worksheet>(url, worksheet);
  }

  deleteWorksheet(id: number): Observable<Worksheet> {
    const _params = new HttpParams().set("id__exact", id);
    const url = `/worksheets`;
    return this.httpClient.delete<Worksheet>(url, { params: _params });
  }

  getWorksheets(
    requestParams: RequestParams,
  ): Observable<ResultPaginated<Worksheet>> {
    const url = "/worksheets";
    const params = requestParams.toHttpParams();
    return this.httpClient.get<ResultPaginated<Worksheet>>(url, { params });
  }

  getWorksheetById(field: string, value: number): Observable<Worksheet> {
    const _params = new HttpParams().set(field, value);
    return this.httpClient.get<Worksheet>("/worksheets", { params: _params });
  }

  notificationWorksheet(
    worksheetId: number,
  ): Observable<ResultPaginated<Worksheet>> {
    const _params = { id: worksheetId };
    return this.httpClient.get<any>("/mobile/worksheets/notification", {
      params: _params,
    });
  }

  setWorksheetFile(worksheetFile: WorksheetFile): Observable<WorksheetFile> {
    return this.httpClient.post<WorksheetFile>(
      "/worksheets/files",
      worksheetFile,
    );
  }

  deleteWorksheetFile(worksheetFileId: number): Observable<any> {
    const _params = new HttpParams().set("id", worksheetFileId);
    return this.httpClient.delete("/worksheets/files", { params: _params });
  }

  getWorksheetFiles(
    requestParams: RequestParams = new RequestParams(),
  ): Observable<ResultPaginated<WorksheetFile>> {
    const _params = requestParams.toHttpParams();
    return this.httpClient.get<ResultPaginated<WorksheetFile>>(
      "/worksheets/files",
      { params: _params },
    );
  }

  // patchWorksheetFile(worksheetFile: WorksheetFile): Observable<WorksheetFile> {
  //   return this.httpClient.patch('/worksheets/files', worksheetFile).pipe(map((body: WorksheetFile) => body),)
  // }

  postFavorites(worksheetFavorite: WorksheetFavorite) {
    const url = "/worksheets/favorites";
    return this.httpClient.post<WorksheetFavorite>(url, worksheetFavorite);
  }

  deleteFavorite(worksheetFavoriteId: number): Observable<any> {
    const _params = new HttpParams().set("worksheet_id", worksheetFavoriteId);
    return this.httpClient.delete("/worksheets/favorites", { params: _params });
  }
}
