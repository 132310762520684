<div fxLayout="column" fxLayoutGap="0.5erm" class="mat-typography">
  <h2 mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <span>{{ title | translate | uppercase }}</span>
    <button
      mat-icon-button
      mat-dialog-close
      [style.top.px]="-20"
      [style.right.px]="-20"
      *ngIf="closeButton"
    >
      <mat-icon>close</mat-icon>
    </button>
  </h2>
  <mat-dialog-content>
    {{ message | translate }}
  </mat-dialog-content>
  <br />
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>
      {{ acceptButton | translate }}
    </button>
  </mat-dialog-actions>
</div>
