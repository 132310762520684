import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { GoogleMap } from "@angular/google-maps";
import { Subject } from "rxjs";

@Component({
  selector: "app-gmap",
  templateUrl: "./gmap.component.html",
  styleUrls: ["./gmap.component.scss"],
})
export class GMapComponent implements AfterViewInit, OnDestroy {
  @ViewChild("googleMap") map: GoogleMap;
  @ViewChild("mapSearch") searchField: ElementRef;

  private _geoQuery: string;

  @Input() set geoQuery(value: string) {
    this._geoQuery = value;
    this.searchGeocode(value);
  }
  get geoQuery(): string {
    return this._geoQuery;
  }

  @Input() includeSearchPlace = false;
  @Output() resultGeoQueryEvent = new EventEmitter<google.maps.LatLngLiteral>();
  @Output() resultPlaceQueryEvent =
    new EventEmitter<google.maps.places.PlaceResult>();

  readonly DEFAULT_ZOOM: number = 10;
  readonly DEFAULT_POSITION: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  readonly DEFAULT_OPTIONS: google.maps.MapOptions = {
    streetViewControl: false,
    zoomControl: false,
    scaleControl: false,
    mapTypeControl: false,
    scrollwheel: true,
    mapTypeId: "hybrid",
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 5,
  };

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  zoom = this.DEFAULT_ZOOM;
  position = this.DEFAULT_POSITION;
  options = this.DEFAULT_OPTIONS;
  markers: google.maps.MarkerOptions[] = Array<google.maps.MarkerOptions>();
  renderControls: boolean = false;

  mapDirectionsControl: FormControl = new FormControl();

  constructor() {}

  ngAfterViewInit(): void {
    const searchBox = new google.maps.places.SearchBox(
      this.searchField.nativeElement,
    );
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(
      this.searchField.nativeElement,
    );

    // Prevent not align items in initial load
    setTimeout(() => {
      this.renderControls = true;
    }, 2000);

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      // const bounds = new google.maps.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          return;
        }

        this.resultPlaceQueryEvent.emit(place);

        // bounds.extend(place.geometry.location);
        const _position = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        this.updateMarks(_position);
      });
      // this.map.fitBounds(bounds);
    });
  }

  searchGeocode(query: string) {
    const _gmapGeocodeService = new google.maps.Geocoder();
    const _request = { address: query };
    _gmapGeocodeService.geocode(
      _request,
      (
        results: google.maps.GeocoderResult[],
        status: google.maps.GeocoderStatus,
      ) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results) {
            // Recover only first result (more relevant)
            const _position = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            };

            this.resultGeoQueryEvent.emit(_position);
            this.updateMarks(_position);
          }
        }
      },
    );
  }

  // Require <places> API activation
  // searchPlace(query: string) {
  //   const _gmapPlaceService = new google.maps.places.PlacesService(this.map.googleMap);
  //   const _request = { fields: ['all'], query: query };
  //
  //   _gmapPlaceService.findPlaceFromQuery(_request, (results: google.maps.places.PlaceResult[], status: google.maps.places.PlacesServiceStatus) => {
  //     if (status === google.maps.places.PlacesServiceStatus.OK) {
  //         if (results) {
  //           console.log("RESULTS", results);
  //         }
  //       }
  //   });
  // }

  updateMarks(position: google.maps.LatLngLiteral) {
    this.markers = [];

    if (position) {
      this.position = position;
      this.zoom = 15;

      const _marker = {
        position: position,
        options: { animation: google.maps.Animation.BOUNCE },
      } as google.maps.MarkerOptions;

      this.markers.push(_marker);
    } else {
      this.position = this.DEFAULT_POSITION;
      this.zoom = 10;
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
