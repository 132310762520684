import { AbstractControl, ValidatorFn } from "@angular/forms";

const FORMAT_LENGTH: RegExp = new RegExp(".{8,}");
const FORMAT_LOWERCASE: RegExp = new RegExp("(?=[^a-z]*[a-z])");
const FORMAT_UPPERCASE: RegExp = new RegExp("(?=[^A-Z]*[A-Z])");
const FORMAT_NUMBER: RegExp = new RegExp("(?=[^0-9]*[0-9])");
const FORMAT_SPECIAL_CHARACTER: RegExp = new RegExp(
  "(?=[^<>{}\"/|;:.,~!?@#$%^=&*+\\-_`'?()[\\]\\\\]*[<>{}\"/|;:.,~!?@#$%^=&*+\\-_`'?()[\\]\\\\])",
);

export class PasswordValidator {
  static password(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean | null } => {
      if (control.value) {
        let errors: { [key: string]: boolean | null } = {};

        if (!FORMAT_LENGTH.test(control.value)) {
          errors.formatLength = true;
        }
        if (!FORMAT_LOWERCASE.test(control.value)) {
          errors.formatLowercase = true;
        }
        if (!FORMAT_UPPERCASE.test(control.value)) {
          errors.formatUppercase = true;
        }
        if (!FORMAT_NUMBER.test(control.value)) {
          errors.formatNumber = true;
        }
        if (!FORMAT_SPECIAL_CHARACTER.test(control.value)) {
          errors.formatSpecialCharacter = true;
        }

        return errors;
      }
      return null;
    };
  }
}
