<h2 mat-dialog-title>
  {{ title | translate | uppercase }}
</h2>
<mat-dialog-content fxLayout="column">
  <span>{{ description | translate }}</span>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row">
  <button
    (click)="onNoButtonClicked()"
    mat-raised-button
    mat-dialog-close
    color="primary"
    fxFlex
  >
    <mat-icon> cancel </mat-icon>
    {{ "Common.No" | translate | uppercase }}
  </button>
  <button
    (click)="onYesButtonClicked()"
    mat-raised-button
    cdkFocusInitial
    color="primary"
    fxFlex
  >
    <mat-icon> done </mat-icon>
    {{ "Common.Yes" | translate | uppercase }}
  </button>
</mat-dialog-actions>
