import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { RouteReuseStrategy, RouterModule } from "@angular/router";

import { RouteReusableStrategy } from "./route-reusable-strategy";

import { ApiPrefixInterceptor } from "./http/api-prefix.interceptor";
import { TokenInterceptor } from "./http/token.interceptor";
import { CacheInterceptor } from "./http/cache.interceptor";
import { ErrorHandlerInterceptor } from "./http/error-handler.interceptor";
import { LoaderInterceptor } from "./http/loader.interceptor";

import { CacheService } from "./services/cache.service";
import { LoaderService } from "./services/loader.service";
import { PermissionService } from "./services/permission.service";

@NgModule({
  imports: [CommonModule, HttpClientModule, RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    CacheService,
    LoaderService,
    PermissionService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(
        `${parentModule} has already been loaded. Import Core module in the AppModule only.`,
      );
    }
  }
}
