import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { AWSAuthService } from "@app/@core/services/aws/auth.service";
import { DialogService } from "@app/@shared/services/dialog.service";

import { NotificationService } from "@app/@shared/services/notification.service";
import { PasswordValidator } from "@app/@shared/validators/password-validator";
import { UsersService } from "@app/users/users.service";
import { Logger } from "aws-amplify";
import { PasswordRecoverDialogComponent } from "../_dialogs/password-recover-dialog/password-recover-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-auth-forgot",
  templateUrl: "./auth-forgot.component.html",
  styleUrls: ["./auth-forgot.component.scss"],
})
export class AuthForgotComponent implements OnInit {
  passwordRecoverForm: UntypedFormGroup;

  log = new Logger(AuthForgotComponent.name);
  isLoading: boolean = false;

  isSent: Boolean = false;
  isReset: Boolean = false;
  errorMsg: string;
  inFinalProcess: Boolean = false;
  hidePassword: Boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private awsAuthService: AWSAuthService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    const username = this.route.snapshot.queryParamMap.get("username");
    const confirmation_code =
      this.route.snapshot.queryParamMap.get("confirmation_code");

    if (username && confirmation_code) {
      this.inFinalProcess = true;

      this.passwordRecoverForm.controls.username.setValue(username);
      this.passwordRecoverForm.controls.verification_code.setValue(
        confirmation_code,
      );

      this.passwordRecoverForm.controls.username.removeValidators(
        Validators.email,
      );
    } else {
      this.passwordRecoverForm.controls.verification_code.removeValidators([
        Validators.required,
      ]);
      this.passwordRecoverForm.controls.password_replace.removeValidators([
        Validators.required,
      ]);
    }
  }

  private createForm() {
    this.passwordRecoverForm = this.formBuilder.group({
      username: [null, [Validators.email, Validators.required]],
      verification_code: [null, [Validators.required]],
      password_replace: [
        null,
        [PasswordValidator.password(), Validators.required],
      ],
    });
  }

  requestVerificationCode() {
    this.isLoading = true;
    const { username } = this.passwordRecoverForm.getRawValue();
    this.awsAuthService
      .forgotPassword(username)
      .then((data) => {
        const destination = data.CodeDeliveryDetails.Destination;
        const message = this.translateService.instant(
          "auth.verificationCodeSendTo",
          { destination },
        );
        this.notificationService.open(message);
      })
      .catch((error) => {
        this.log.error("Errors sending verification code", error);
        const message = this.translateService.instant(
          "auth.errorSendingVerificationCode",
        );
        return this.notificationService.open(message, "warn");
      })
      .finally(() => (this.isLoading = false));
    this.passwordRecoverForm.reset();
  }

  requestChangePassword() {
    this.awsAuthService
      .forgotPasswordSubmit(
        this.passwordRecoverForm.value.username,
        this.passwordRecoverForm.value.verification_code,
        this.passwordRecoverForm.value.password_replace,
      )
      .then((data) => {
        this.isReset = true;
        this.router.navigate(["/auth/login"], { replaceUrl: true });
      })
      .catch((error) => {
        this.errorMsg = error.message;
      });
  }

  profileRecoverPassword() {
    this.dialog.open(PasswordRecoverDialogComponent, {
      panelClass: "mat-dialog-responsive",
      disableClose: true,
      data: {},
    });
  }
}
