<div fxFlex fxLayout="column" class="gmap-container">
  <input
    #mapSearch
    placeholder="{{ 'app.actions.search' | translate | titlecase }}"
    [style.display]="renderControls && includeSearchPlace ? 'block' : 'none'"
    class="mat-typography"
  />
  <google-map
    fxFlex
    height="340px"
    width="100%"
    [zoom]="zoom"
    [center]="position"
    [options]="options"
    #googleMap="googleMap"
  >
    <map-marker
      *ngFor="let marker of markers"
      [position]="marker.position"
      [label]="marker.label"
      [title]="marker.title"
      [options]="marker['options']"
    >
    </map-marker>
  </google-map>
</div>
