<div
  fxFlex
  fxLayout="column"
  fxLayoutGap="0.5rem"
  class="files-upload-container"
>
  <!-- RESTRICTIONS INFO -->
  <div
    fxLayout="row"
    fxLayoutAlign="end "
    fxLayoutGap="0.5rem"
    class="dropzone-restrictions"
    *ngIf="!oneImage"
  >
    <span
      *ngIf="
        fileFormatsRestriction &&
        showRestrictions.includes('fileFormatsRestriction')
      "
      ><strong>{{ "upload-files.formats-restriction" | translate }}:</strong>
      {{ fileFormatsRestriction }}</span
    >
    <mat-divider
      [vertical]="true"
      *ngIf="
        fileFormatsRestriction &&
        showRestrictions.includes('fileFormatsRestriction')
      "
    ></mat-divider>
    <span
      *ngIf="
        fileItemsRestriction &&
        showRestrictions.includes('fileItemsRestriction')
      "
      ><strong>{{ "upload-files.item-restriction" | translate }}:</strong>
      {{ fileItemsRestriction }}</span
    >
    <mat-divider
      [vertical]="true"
      *ngIf="
        fileItemsRestriction &&
        showRestrictions.includes('fileItemsRestriction')
      "
    ></mat-divider>
    <span
      *ngIf="
        fileSizeRestriction && showRestrictions.includes('fileSizeRestriction')
      "
      ><strong>{{ "upload-files.size-restriction" | translate }}:</strong>
      {{ fileSizeRestriction }} MB</span
    >
  </div>

  <!-- DROPZONE -->
  <div
    class="dropzone"
    (dragover)="onDropzoneOver($event)"
    (dragleave)="onDropzoneOut($event)"
    [ngClass]="{
      'dropzone-over': inDropzone,
      'dropzone-disabled':
        !editable || files.length >= this.fileItemsRestriction
    }"
    *ngIf="showDropzone"
  >
    <input
      #fileDropReference
      fxFlex
      fxFill
      multiple
      type="file"
      [accept]="fileFormatsRestriction"
      (change)="fileBrowseHandler($event.target.files)"
      [disabled]="!editable || files.length >= this.fileItemsRestriction"
    />
    <div fxFlex fxFill fxLayout="column" fxLayoutAlign="center center">
      <span>{{ title | translate }}</span>
      <span>{{ subtitle | translate }}</span>
      <mat-icon>cloud_upload</mat-icon>
    </div>
  </div>

  <!-- FILES -->
  <div fxLayout="column" *ngIf="!hideFiles">
    <ng-container *ngFor="let file of files; let i = index">
      <mat-progress-bar
        *ngIf="!file.url"
        mode="indeterminate"
      ></mat-progress-bar>
      <div
        *ngIf="file.url"
        fxFlex
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="0.5rem"
      >
        <img
          class="filename-icon"
          [src]="file.mimetype"
          (error)="onUrlError($event)"
        />
        <button mat-button class="filename-title" fxFlex>
          <div fxLayoutGap="0.5rem" fxLayout="row">
            <a
              fxFlex
              fxLayoutAlign="start"
              class="link"
              [href]="file.path"
              target="_blank"
              *ngIf="file.id"
            >
              {{ file?.name }}
            </a>
          </div>
        </button>
        <div fxLayoutAlign="end">({{ formatBytes(file?.size) }})</div>
        <button [disabled]="!editable" mat-icon-button (click)="deleteFile(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>

  <!-- ACTIONS -->
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    *ngIf="files.length > 0 && showActions && editable"
  >
    <button mat-button (click)="resetFiles()">
      {{ buttonReset | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="sendFiles()">
      {{ buttonUpload | translate }}
    </button>
  </div>
</div>

<div class="oneImage" *ngIf="oneImage">
  <div *ngFor="let file of files; let i = index">
    <img *ngIf="file.url" [src]="file.url" (error)="onUrlError($event)" />
  </div>
</div>
