export const COLLETION_MIMETYPES = [
  {
    // JPEG, JPG, PNG, BMP, GIF
    mimetypes: ["image/jpeg", "image/png", "image/bmp", "	image/gif"],
    icon: "assets/images/documents/image.svg",
    preview: true,
  },
  {
    // XLS, XLSX, XLSM, XLSB
    mimetypes: [
      "application/vnd.ms-excel",
      "application/vnd.ms-excel.sheet.macroEnabled.12",
      "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    icon: "assets/images/documents/excel.svg",
    preview: false,
  },
  {
    // PDF
    mimetypes: ["application/pdf"],
    icon: "assets/images/documents/pdf.svg",
    preview: false,
  },
  {
    // OTHERS
    mimetypes: ["*"],
    icon: "assets/images/documents/doc.svg",
    preview: false,
  },
];
