<div class="auth-confirmation-account-container" fxLayout="column">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center">
    <form
      [formGroup]="confirmationAccountForm"
      fxLayout="column"
      style="margin-top: 50px"
    >
      <mat-form-field>
        <mat-label>{{ "auth.login.email" | translate }}</mat-label>
        <input
          matInput
          [formControlName]="ConfirmationAccountFormFields.email"
        />
      </mat-form-field>
      <button
        mat-flat-button
        color="accent"
        (click)="requestVerificationCode()"
        [disabled]="confirmationAccountForm.controls.email.errors?.required"
      >
        <span>{{ "auth.login.resend_confirmation" | translate }}</span>
      </button>
      <br />
      <mat-form-field>
        <mat-label>{{ "auth.login.confirmation_code" | translate }}</mat-label>
        <input
          matInput
          [formControlName]="ConfirmationAccountFormFields.confirmation_code"
        />
      </mat-form-field>
      <button
        mat-flat-button
        color="primary"
        [disabled]="confirmationAccountForm.invalid"
        (click)="requestVerificationAccount()"
      >
        {{ "app.actions.confirm" | translate }}
      </button>
    </form>
  </div>
</div>
