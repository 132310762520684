import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";

import { RequestParams, ResultPaginated } from "@app/@shared/models/_results";

import { Advice, AdviceFile, AuditAdvices } from "@app/@shared/models/advice";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AdvicesService {
  constructor(private httpClient: HttpClient) {}

  getAdvicesAuditHistory(
    requestParams: RequestParams,
  ): Observable<ResultPaginated<AuditAdvices>> {
    const params = requestParams.toHttpParams();
    return this.httpClient.get<ResultPaginated<AuditAdvices>>(
      "/audit/advices",
      { params: params },
    );
  }

  setAdvice(advice: Advice): Observable<Advice> {
    return this.httpClient
      .post("/advices", advice)
      .pipe(map((body: Advice) => body));
  }

  patchAdvice(advice: Advice): Observable<Advice> {
    return this.httpClient
      .patch("/advices", advice)
      .pipe(map((body: Advice) => body));
  }
  updateAdvice(advice: Advice): Observable<Advice> {
    const url = "/advices";
    return this.httpClient.patch<Advice>(url, advice);
  }

  deleteAdvice(id: number): Observable<Advice> {
    const _params = new HttpParams().set("id", id);
    return this.httpClient
      .delete("/advices", { params: _params })
      .pipe(map((body: Advice) => body));
  }

  getAdvices(
    requestParams: RequestParams,
  ): Observable<ResultPaginated<Advice>> {
    const url = "/advices";
    const params = requestParams.toHttpParams();
    return this.httpClient.get<ResultPaginated<Advice>>(url, { params });
  }

  getAdviceById(field: string, value: number): Observable<Advice> {
    const _params = new HttpParams().set(field, value);
    return this.httpClient.get<Advice>("/advices", { params: _params });
  }

  notificationAdvice(adviceId: number): Observable<any> {
    const _params = { id: adviceId };
    return this.httpClient.get<any>("/advices/notification", {
      params: _params,
    });
  }

  setAdviceFile(adviceFile: AdviceFile): Observable<AdviceFile> {
    return this.httpClient
      .post("/advices/files", adviceFile)
      .pipe(map((body: AdviceFile) => body));
  }

  deleteAdviceFile(adviceFileId: number): Observable<any> {
    const _params = new HttpParams().set("id", adviceFileId);
    return this.httpClient.delete("/advices/files", { params: _params });
  }

  getAdviceFiles(
    requestParams: RequestParams = new RequestParams(),
  ): Observable<ResultPaginated<AdviceFile>> {
    const _params = requestParams.toHttpParams();
    return this.httpClient.get<ResultPaginated<AdviceFile>>("/advices/files", {
      params: _params,
    });
  }

  patchAdviceFile(adviceFile: AdviceFile): Observable<AdviceFile> {
    return this.httpClient
      .patch("/advices/files", adviceFile)
      .pipe(map((body: AdviceFile) => body));
  }
}
