import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SortDirection } from "@angular/material/sort";
import { Logger, UntilDestroy, untilDestroyed } from "@app/@core";
import { AppConstants } from "@app/@shared/app-constants";
import {
  DefaultFilterForm,
  defaultFilterFormValue,
} from "@app/@shared/models/_common/filter-forms/default-filter-form";
import { RequestParams } from "@app/@shared/models/_results";
import { User, UserFields, UserTypes } from "@app/@shared/models/user";
import { NotificationService } from "@app/@shared/services/notification.service";
import { UsersService } from "@app/users/users.service";
import { Subject } from "rxjs";
import { debounceTime, finalize } from "rxjs/operators";

interface UsersReadFormGroup extends DefaultFilterForm {
  type: FormControl<UserTypes | null>;
  houseId: FormControl<number | null>;
}

@UntilDestroy()
@Component({
  selector: "app-user-selector[control]",
  templateUrl: "./user-selector.component.html",
  styleUrls: ["./user-selector.component.scss"],
})
export class UserSelectorComponent implements OnInit {
  @Input() control: FormControl<User>;
  @Input() label = "users.user";
  @Input() set houseId(value: number) {
    this.form.controls.houseId.setValue(value);
  }

  @Input() set type(value: UserTypes) {
    this.form.controls.type.setValue(value);
  }

  @Input() showOnlyTenantsWithHouses = false;

  @Input() hasClearButton = false;
  @Input() hasRefreshButton = true;
  @Output() closeButtonClicked = new EventEmitter<void>();

  itemList = <User[]>[];
  hasMoreItems: boolean;
  peopleInput$ = new Subject<string>();
  isLoading: boolean;
  UserFields = UserFields;
  form = new FormGroup<UsersReadFormGroup>({
    ...defaultFilterFormValue,
    sortField: new FormControl<string>(UserFields.email, { nonNullable: true }),
    sortDirection: new FormControl<SortDirection>("asc"),
    houseId: new FormControl<number | null>(null, { nonNullable: true }),
    type: new FormControl<UserTypes | null>(null, { nonNullable: true }),
  });
  log = new Logger(this.constructor.name);
  constructor(
    private usersService: UsersService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this._loadData();

    this.peopleInput$.pipe(untilDestroyed(this)).subscribe((term) => {
      this.log.debug("term", term);
      this.form.patchValue({
        query: term,
        pageSize: AppConstants.DEFAULT_PAGE_SIZE,
      });
    });

    this.form.valueChanges
      .pipe(
        debounceTime(AppConstants.DEFAULT_DEBOUNCE_TIME),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.log.debug("form", this.form.getRawValue());
        this._loadData();
      });
  }

  isRequired(): boolean {
    return this.control.hasValidator(Validators.required);
  }

  compareWith(a: User, b: User): boolean {
    return a.id === b.id;
  }

  onScrollToEnd() {
    if (this.hasMoreItems) {
      const pageSize: number =
        this.form.value.pageSize + AppConstants.DEFAULT_PAGE_SIZE;
      this.form.patchValue({ pageSize });
    } else {
      this.log.debug("No more items");
    }
  }
  onCloseButtonClicked() {
    this.control.reset();
    this.closeButtonClicked.emit();
  }

  getShowedText(item: User): string {
    return item.email
      ? `${item.name} ${item.surname} (${item.email})`
      : `${item.name} ${item.surname}`;
  }

  onRefreshButtonClicked() {
    this._loadData();
  }

  private _loadData(): void {
    if (this.form.invalid) {
      this.log.warn("Trying to load data with invalid form");
      return;
    }

    this.isLoading = true;

    const formValue = this.form.getRawValue();

    const param = new RequestParams({
      query: {
        fields: [UserFields.email, UserFields.name, UserFields.phone],
        value: formValue.query,
      },
      pagination: {
        pageIndex: formValue.pageIndex,
        pageSize: formValue.pageSize,
      },
      order: [{ field: formValue.sortField, type: formValue.sortDirection }],
      filter: [
        ...(formValue.houseId != null
          ? [
              {
                field: `${UserFields.housing}.id__exact`,
                value: formValue.houseId.toString(),
              },
            ]
          : []),
        ...(formValue.type != null
          ? [{ field: UserFields.type, value: formValue.type }]
          : []),

        ...(this.showOnlyTenantsWithHouses
          ? [
              {
                field: `${UserFields.housing}.id__gt`,
                value: "-1",
              },
            ]
          : []),
        // ...(param.roleIds != null ? [{ field: `${UserFields.role_id}__in`, value: param.roleIds.join(',') }] : []),
        // ...(formValue.isActive != null ? [{ field: UserFields.is_active, value: formValue.isActive ? '1' : '0' }] : []),
      ],
    });
    this.usersService
      .getUsers(param)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (result) => {
          this.itemList = result.items;
          this.hasMoreItems = result.total > result.items.length;
        },
        error: (error) => {
          this.notificationService.openEntityListLoadErrorNotification(
            "users.users",
          );
        },
      });
  }
}
