import { Routes, Route } from "@angular/router";

import { AuthGuard } from "@app/auth";
import { ShellComponent } from "./shell.component";
import { PermissionGuard } from "../auth/permission.guard";

/**
 * Provides helper methods to create routes.
 */
export class Shell {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes, permissionGuard: boolean = true): Route {
    const _route: Route = {
      path: "",
      component: ShellComponent,
      children: routes,
      canActivate: [AuthGuard],
      canActivateChild: [],
    };

    if (permissionGuard) _route.canActivateChild.push(PermissionGuard);

    return _route;
  }
}
