<mat-form-field fxFlex [floatLabel]="'always'">
  <mat-label> {{ "Selector." + entity | translate | translate }} </mat-label>

  <mat-select
    class="select-chip"
    [formControl]="formControl"
    multiple="true"
    [disabled]="isDisabled"
  >
    <mat-select-trigger>
      <mat-chip-list #chipList>
        <mat-chip
          *ngFor="let item of selectedEntityList"
          [selectable]
          removable
          [matTooltip]="item[showed_field]"
          (removed)="unselectEntity(item)"
        >
          <p>{{ item[showed_field] }}</p>
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search
        [formControl]="filterControl"
        [placeholderLabel]="'app.actions.search' | translate"
        [noEntriesFoundLabel]="'app.actions.no-record' | translate"
        [searching]="isLoading"
        [clearSearchInput]="false"
        [disableScrollToActiveOnOptionsChanged]="true"
      >
      </ngx-mat-select-search>
    </mat-option>

    <!-- This option force mat-trigger is always visible -->
    <mat-option
      *ngFor="let selectedEntity of selectedEntityList"
      [disabled]="isLoading"
      [value]="selectedEntity"
    >
      {{ selectedEntity[showed_field] }}
    </mat-option>
    <mat-divider></mat-divider>

    <mat-option
      *ngFor="let item of getListWithoutSelectedItems()"
      [disabled]="isLoading"
      [value]="item"
      [matTooltip]="item[showed_field]"
    >
      {{ item[showed_field] }}
    </mat-option>

    <button
      *ngIf="total > list.length"
      [disabled]="isLoading"
      (click)="onSeeMoreButton()"
      mat-button
      fxFill
    >
      <mat-icon> expand_more </mat-icon>

      <span
        >{{
          "app.actions.seeMore"
            | translate
              : {
                  n:
                    total - list.length < param.pagination.pageSize
                      ? total - list.length
                      : param.pagination.pageSize
                }
        }}
      </span>
    </button>
  </mat-select>
</mat-form-field>
