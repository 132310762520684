<form [formGroup]="usersGroupForm">
  <div
    fxLayout="row wrap"
    fxLayout.lt-sm="column"
    fxLayoutGap="1rem"
    fxLayoutGap.lt-sm="0"
  >
    <mat-card fxFlex fxLayoutGap="1rem" class="fixed_filters_div">
      <mat-form-field fxFlex>
        <mat-label>
          {{ "app.actions.search" | translate | uppercase }}
        </mat-label>
        <input matInput formControlName="querytext" />
      </mat-form-field>

      <app-housing-group-selector
        fxFlex
        [label]="'housing.housing.group'"
        [value]="usersGroupForm.controls.group_id.value"
        [editable]="editable"
        (formControlEvent)="onSelect($event, usersGroupForm.controls?.group_id)"
      >
      </app-housing-group-selector>

      <mat-form-field fxFlex color="primary">
        <mat-label>
          {{ "users.user.type.title" | translate | uppercase }}
        </mat-label>
        <mat-select #matSelector formControlName="type" [multiple]="false">
          <mat-option [value]="'technician'">{{
            "users.user.type.technician" | translate | capitalize
          }}</mat-option>
          <mat-option [value]="'company_admin'">{{
            "users.user.type.company_admin" | translate | capitalize
          }}</mat-option>
          <mat-option [value]="'tenant'">{{
            "users.user.type.tenant" | translate | capitalize
          }}</mat-option>
          <mat-option [value]="'urvial_administrator'">{{
            "users.user.type.urvial_administrator" | translate | capitalize
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex color="primary">
        <mat-label>
          {{ "users.user.status.title" | translate | uppercase }}
        </mat-label>
        <mat-select #matSelector formControlName="status" [multiple]="false">
          <mat-option [value]="'registered'">{{
            "users.user.status.registered" | translate | capitalize
          }}</mat-option>
          <mat-option [value]="'confirmed'">{{
            "users.user.status.confirmed" | translate | capitalize
          }}</mat-option>
          <mat-option [value]="'rejected'">{{
            "users.user.status.rejected" | translate | capitalize
          }}</mat-option>
          <mat-option [value]="'disabled'">{{
            "users.user.status.disabled" | translate | capitalize
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div fxLayoutAlign="center center" fxLayoutGap="0.5rem">
        <button
          mat-icon-button
          (click)="usersGroupForm.reset()"
          class="buttonDelete"
          color="primary"
          mat-raised-button
          matTooltip="{{ 'app.common.delete' | translate | uppercase }}"
        >
          <mat-icon aria-label="delete">delete</mat-icon>
        </button>
        <button
          *ngIf="hasRefreshButton"
          mat-icon-button
          (click)="onButtonRefreshPressed()"
          [disabled]="isLoading"
          mat-raised-button
          class="buttonRefresh"
          matTooltip="{{ 'app.common.refresh' | translate | uppercase }}"
        >
          <mat-icon aria-label="refresh">refresh</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="onButtonCreate()"
          [disabled]="isLoading"
          mat-raised-button
          class="buttonRefresh"
          matTooltip="{{ 'app.common.add' | translate | uppercase }}"
        >
          <mat-icon aria-label="refresh">add</mat-icon>
        </button>
      </div>
    </mat-card>
  </div>
</form>
