import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Logger } from "@app/@core";

import { PasswordValidator } from "@app/@shared/validators/password-validator";

import { NotificationService } from "@app/@shared/services/notification.service";
import { UsersService } from "@app/users/users.service";
import { Router } from "@angular/router";
import { finalize } from "rxjs/operators";
import { ErrorNotificationService } from "@app/@shared/services/error_notification.service";

export enum RegisterFormFields {
  email = "email",
  // username = 'username',
  name = "name",
  surname = "surname",
  dni = "dni",
  phone = "phone",
  password = "password",
}

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class AuthRegisterComponent implements OnInit {
  log = new Logger(AuthRegisterComponent.name);

  RegisterFormFields = RegisterFormFields;

  isLoading: boolean = false;
  hidePassword: Boolean = true;
  focusPassword: Boolean = false;

  registerForm = new FormGroup({
    [RegisterFormFields.email]: new FormControl<string>(null, [
      Validators.required,
      Validators.email,
    ]),
    [RegisterFormFields.name]: new FormControl<string>(null, [
      Validators.required,
    ]),
    [RegisterFormFields.surname]: new FormControl<string>(null, [
      Validators.required,
    ]),
    [RegisterFormFields.dni]: new FormControl<string>(null, [
      Validators.required,
    ]),
    [RegisterFormFields.phone]: new FormControl<string>(null, [
      Validators.required,
    ]),
    [RegisterFormFields.password]: new FormControl<string>(null, [
      Validators.required,
      PasswordValidator.password(),
    ]),
  });

  constructor(
    private usersService: UsersService,
    private notificationService: NotificationService,
    public router: Router,
    private errorNotificationService: ErrorNotificationService,
  ) {}

  ngOnInit() {}

  register() {
    this.isLoading = true;
    this.usersService
      .register(this.registerForm.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (result) => {
          this.router.navigate(["/auth"], { replaceUrl: true });
          this.notificationService.open("app.messages.successful_register");
        },
        error: (error) => {
          this.log.error("Error register: ", error);
          this.errorNotificationService.notification(
            error,
            "ErrorCreatingData",
          );
        },
      });
  }
}
