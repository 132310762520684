<!-- <div fxFlex="nogrow" fxLayoutAlign="end start">
    <app-language-selector icon="true"></app-language-selector>
  </div> -->

<div fxLayout="column" fxLayoutAlign="center center" fxFlex>
  <div fxLayoutAlign="center" style="margin: 3rem">
    <img
      class="logo"
      fxLayoutAlign="center center"
      src="assets/images/logo_urvial_blanco.png"
      style="width: 350px"
      alt="URVIAL logo"
    />
  </div>
  <div fxFlex fxLayout="row" fxLayoutAlign="center">
    <mat-card fxFlex="500px" fxFlex.lt-md="70%" class="login-container">
      <form [formGroup]="loginForm">
        <div fxFlex fxFill fxLayout="column" fxLayoutGap="1.0rem">
          <div fxLayout="column" fxLayoutGap="0.5rem">
            <!-- LOGIN FORM -->
            <mat-form-field>
              <mat-label>{{
                "auth.login.email username" | translate | capitalize
              }}</mat-label>
              <input type="text" matInput formControlName="username" required />
              <mat-error
                *ngIf="
                  loginForm.controls.username.invalid &&
                  loginForm.controls.username.touched
                "
              >
                <span>{{ "Este campo es obligatorio" | translate }}</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{
                "auth.login.password" | translate | titlecase
              }}</mat-label>
              <input
                type="password"
                matInput
                formControlName="password"
                autocomplete="off"
                placeholder
                [type]="hidePassword ? 'password' : 'text'"
              />
              <mat-error
                *ngIf="
                  loginForm.controls.password.invalid &&
                  loginForm.controls.password.touched
                "
              >
                <span>{{ "Este campo es obligatorio" | translate }}</span>
              </mat-error>
              <div fxLayout="row" matSuffix>
                <mat-icon (click)="hidePassword = !hidePassword">{{
                  hidePassword ? "visibility" : "visibility_off"
                }}</mat-icon>
              </div>
            </mat-form-field>
            <br />

            <button
              mat-flat-button
              type="submit"
              color="primary"
              (click)="login()"
              [disabled]="loginForm.invalid"
            >
              <span>{{ "app.actions.enter" | translate | capitalize }}</span>
            </button>

            <button
              [disabled]="isLoading"
              (click)="onRecoverPasswordButtonClicked()"
              class="btnForget"
              mat-button
              fxFlex
            >
              <span>{{ "auth.login.forgot" | translate | capitalize }}</span>
            </button>

            <div fxFlex="0.5rem"></div>
            <mat-divider></mat-divider>
            <div fxFlex="0.5rem"></div>

            <div
              fxLayout="row"
              fxLayoutAlign="center"
              fxLayoutGap="0.5rem"
              class="register-row"
            >
              <div class="register-row-text">¿No tienes cuenta?</div>
              <div fxFlex="0.5rem"></div>
              <button
                mat-flat-button
                color="primary"
                class="btnRegister"
                (click)="registerUser()"
                *ngIf="router.url.includes('/auth/login')"
              >
                <span>{{
                  "auth.register.title" | translate | capitalize
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </mat-card>
  </div>
</div>
