<div class="auth-password-refactor-container" fxLayout="column">
  <div class="mat-card-title">
    {{ "auth.refactor password.title" | translate | capitalize }}
  </div>
  <div fxLayout="row" fxLayoutAlign="center">
    <form
      [formGroup]="passwordRefactorForm"
      fxFlex
      fxLayout="column"
      style="margin-top: 50px"
    >
      <mat-form-field>
        <mat-label>{{
          "auth.login.password" | translate | capitalize
        }}</mat-label>
        <input
          matInput
          [type]="hidePassword ? 'password' : 'text'"
          [formControlName]="PasswordRefactorFormFields.password"
        />
        <div fxLayout="row" matSuffix>
          <mat-icon (click)="hidePassword = !hidePassword">{{
            hidePassword ? "visibility_off" : "visibility"
          }}</mat-icon>
        </div>
      </mat-form-field>
      <app-password-hints
        [formControl]="passwordRefactorForm.controls.password"
      ></app-password-hints>

      <button
        mat-flat-button
        color="primary"
        [disabled]="passwordRefactorForm.invalid"
        (click)="changePassword()"
      >
        {{ "app.actions.confirm" | translate | capitalize }}
      </button>
    </form>
  </div>
</div>
