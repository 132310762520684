import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class NavigateService {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) {}

  updateRouteWithoutReload(params: {
    fragment?: string;
    queryParams?: Params;
    queryParamsHandling?: "merge" | "preserve";
  }): void {
    const url = this.router.createUrlTree([], {
      relativeTo: this.route,
      fragment: params.fragment,
      queryParams: params.queryParams,
      queryParamsHandling: params.queryParamsHandling,
    });

    this.location.replaceState(url.toString());
  }

  changeRouteFragmentWithoutReload(fragment: string): void {
    const url = this.router.createUrlTree([], {
      relativeTo: this.route,
      fragment: fragment,
    });

    this.location.replaceState(url.toString());
  }

  removeRouteFragmentWithoutReload(): void {
    this.changeRouteFragmentWithoutReload(null);
  }

  openPathInNewTab(path: string[], fragment?: string): void {
    const url = this.router.createUrlTree(path, {
      relativeTo: this.route,
      fragment,
    });

    this.location.replaceState(url.toString());
    window.open(url.toString(), "_blank");
  }
}
