import { Component, Inject } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MismatchValidator } from "@app/@shared/validators/mismatch-validator";

import { AWSAuthService } from "@app/@core/services/aws/auth.service";
import { NotificationService } from "@app/@shared/services/notification.service";

import { passwordSetting } from "@env/permissions-settings";
import { TranslateService } from "@ngx-translate/core";
import { PasswordValidator } from "@app/@shared/validators/password-validator";

export interface PasswordRecoverConfirmCodeDialogData {
  username: string;
  confirmationCode?: string;
}

@Component({
  selector: "app-password-recover-dialog",
  templateUrl: "./password-recover-dialog.component.html",
  styleUrls: ["./password-recover-dialog.component.scss"],
})
export class PasswordRecoverDialogComponent {
  passwordRecoverForm: FormGroup;
  focusPassword: Boolean = false;
  isLoading: Boolean = false;
  hidePassword: Boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PasswordRecoverConfirmCodeDialogData,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<any>,
    private awsAuthService: AWSAuthService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    this.createForm();
  }

  private createForm() {
    this.passwordRecoverForm = this.formBuilder.group({
      code: ["", Validators.required],
      username: [
        "",
        [
          Validators.required,
          Validators.pattern("^[XYZ0-9][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$"),
        ],
      ],
      password_replace: [
        "",
        [Validators.required, PasswordValidator.password()],
      ],
      // password_confirm: ['']
      // username: new FormControl<string>({ value: this.data.username, disabled: true }, [Validators.required]),
      // code: new FormControl<string>(this.data.confirmationCode, [Validators.required]),
      // password_replace: new FormControl<string>(null, [Validators.required, Validators.pattern(passwordSetting)]),
    });

    // this.passwordRecoverForm.controls.password_confirm.setValidators([
    //   Validators.required,
    //   MismatchValidator.mismatch(this.passwordRecoverForm.controls.password_replace)
    // ]);
  }

  changePassword() {
    this.isLoading = true;
    const { username, code, password_replace } =
      this.passwordRecoverForm.getRawValue();
    this.awsAuthService
      .forgotPasswordSubmit(username, code, password_replace)
      .then((data) => {
        const message = this.translateService.instant(
          "auth.passwordUpdateSuccess",
        );
        this.notificationService.open(message);
        this.dialogRef.close(data);
      })
      .catch((error) => {
        const message = this.translateService.instant("InvalidFields");
        this.notificationService.open(message, "warn");
      })
      .finally(() => (this.isLoading = false));
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
