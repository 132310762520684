import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpContext,
  HttpContextToken,
} from "@angular/common/http";
import { from, Observable, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { Logger } from "../services/logger.service";

import { AWSAuthService } from "../services/aws/auth.service";

const log = new Logger("TokenInterceptor");

/*
> [COGNITO] Agregate token authorization header
> [KEY] Agregate accessKey authorization header
> [NONE] Disable authorizacion header
*/
export interface AuthorizationContextToken {
  method: "cognito" | "key" | "none";
}

export const HTTP_AUTH = new HttpContextToken<AuthorizationContextToken>(
  () => <AuthorizationContextToken>{ method: "cognito" },
);

export function auth(method: string = "cognito"): HttpContext {
  return new HttpContext().set(HTTP_AUTH, { method: method });
}

/**
 * Set authorization header in all requests.
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private awsAuthService: AWSAuthService,
    private router: Router,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // [COGNITO] > Include authorizacion headers
    if (request.context.get(HTTP_AUTH).method === "cognito") {
      // TODO: Revision
      return from(this.awsAuthService.currentSession()) // Promise --> Observable
        .pipe(
          switchMap((session: any) => {
            const token = session.getIdToken().getJwtToken();
            const _request = request.clone({
              setHeaders: { Authorization: `Bearer ${token}` },
            });

            return next.handle(_request);
          }),
          catchError((error) => {
            if (error.status === 401) {
              // AUTO > Logout if API return 401 code
              return this.awsAuthService
                .logout()
                .finally(() =>
                  this.router.navigate(["/auth"], { replaceUrl: true }),
                );
            }
            throw error;
          }),
        );
    }
    // [KEY] > Include authorizacion headers
    else if (request.context.get(HTTP_AUTH).method === "key") {
      const token = "<access_key>";
      const _request = request.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });

      return next.handle(_request);
    }
    // [NONE] > Disable authorizacion headers
    else {
      return next.handle(request);
    }
  }
}
