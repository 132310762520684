import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { RequestParams, ResultPaginated } from "@app/@shared/models/_results";
import { Company } from "@app/@shared/models/company";
import { User } from "@app/@shared/models/user";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CompaniesService {
  constructor(private httpClient: HttpClient) {}

  getCompanies(
    requestParams: RequestParams,
  ): Observable<ResultPaginated<Company>> {
    const url = "/companies";
    const params = requestParams.toHttpParams();
    return this.httpClient.get<ResultPaginated<Company>>(url, { params });
  }

  getCompanyById(
    field: string,
    value: number,
  ): Observable<ResultPaginated<Company>> {
    const _params = new HttpParams().set(field, value);
    return this.httpClient.get<ResultPaginated<Company>>("/companies", {
      params: _params,
    });
  }

  createCompany(data: { company: Company }): Observable<Company> {
    const url = "/companies";
    return this.httpClient.post<Company>(url, data);
  }

  updateCompany(company: Company): Observable<Company> {
    const url = "/companies";
    return this.httpClient.patch<Company>(url, company);
  }
}
