<div fxLayout="column" fxLayoutAlign="center center" fxFlex>
  <div fxLayoutAlign="center" style="margin: 2rem">
    <img
      class="logo"
      fxLayoutAlign="center center"
      src="assets/images/logo_urvial_blanco.png"
      style="width: 350px"
      alt="URVIAL logo"
    />
  </div>

  <div fxLayout="column" fxLayoutAlign="center center" fxFlex>
    <mat-card fxLayout="row" fxFlex fxLayoutAlign="center stretch">
      <form [formGroup]="registerForm" fxFlex fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="center stretch">
          <span class="mat-card-title">{{
            "auth.register.title" | translate | capitalize
          }}</span>
        </div>

        <div fxFlex="0.5rem"></div>
        <mat-divider></mat-divider>
        <div fxFlex="0.50rem"></div>

        <div fxLayout="row">
          <!-- Nombre -->
          <mat-form-field fxFlex="40%">
            <mat-label>{{
              "users.user.name" | translate | capitalize
            }}</mat-label>
            <input matInput [formControlName]="RegisterFormFields.name" />
            <mat-error
              *ngIf="
                registerForm.controls.name.errors?.required &&
                registerForm.controls.name.touched
              "
            >
              <span>{{ "Este campo es obligatorio" | translate }}</span>
            </mat-error>
          </mat-form-field>

          <div fxFlex="0.50rem"></div>

          <!-- Apellidos -->
          <mat-form-field fxFlex="60%">
            <mat-label>{{
              "users.user.surname" | translate | capitalize
            }}</mat-label>
            <input matInput [formControlName]="RegisterFormFields.surname" />
            <mat-error
              *ngIf="
                registerForm.controls.surname.errors?.required &&
                registerForm.controls.surname.touched
              "
            >
              <span>{{ "Este campo es obligatorio" | translate }}</span>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- <div fxFlex="0.5rem"></div> -->

        <!-- Email -->
        <mat-form-field>
          <mat-label>{{
            "users.user.email" | translate | capitalize
          }}</mat-label>
          <input matInput [formControlName]="RegisterFormFields.email" />
          <mat-error
            *ngIf="
              registerForm.controls.email.errors?.required &&
              registerForm.controls.email.touched
            "
          >
            <span>{{ "Este campo es obligatorio" | translate }}</span>
          </mat-error>
          <mat-error
            *ngIf="
              registerForm.controls.email.errors?.email &&
              registerForm.controls.email.touched
            "
          >
            <span>{{
              "app.forms.is invalid"
                | translate
                  : { item: ("users.user.email" | translate | capitalize) }
            }}</span>
          </mat-error>
        </mat-form-field>

        <!-- <div fxFlex="0.5rem"></div> -->

        <div fxLayout="row">
          <!-- Dni -->
          <mat-form-field fxFlex="40%">
            <mat-label>{{
              "users.user.dni" | translate | capitalize
            }}</mat-label>
            <input matInput [formControlName]="RegisterFormFields.dni" />
            <mat-error
              *ngIf="
                registerForm.controls.dni.errors?.required &&
                registerForm.controls.dni.touched
              "
            >
              <span>{{ "Este campo es obligatorio" | translate }}</span>
            </mat-error>
          </mat-form-field>

          <div fxFlex="0.50rem"></div>

          <!-- Teléfono -->
          <mat-form-field fxFlex="60%">
            <mat-label>{{
              "users.user.phone" | translate | capitalize
            }}</mat-label>
            <ngx-mat-intl-tel-input
              formControlName="phone"
              [onlyCountries]="['es']"
              [enablePlaceholder]="true"
              cdkFocusInitial
            ></ngx-mat-intl-tel-input>
          </mat-form-field>
        </div>

        <!-- <div fxFlex="0.5rem"></div> -->

        <div fxLayout="row">
          <!-- Usuario -->
          <!-- <mat-form-field fxFlex="40%">
            <mat-label>{{
              "users.user.username" | translate | capitalize
            }}</mat-label>
            <input matInput [formControlName]="RegisterFormFields.username" [disabled]="true" />
          </mat-form-field> -->

          <!-- Contraseña -->
          <!-- <mat-form-field fxFlex>
            <mat-label>{{"users.user.password" | translate | capitalize}}</mat-label>
            <input
              matInput
              (focus)="focusPassword = true"
              [type]="hidePassword ? 'password' : 'text'"
              [formControlName]="RegisterFormFields.password"/>
            <div fxLayout="row" matSuffix>
              <mat-icon (click)="hidePassword = !hidePassword">{{hidePassword ? "visibility_off" : "visibility"}}</mat-icon>
            </div>
            <mat-error
              *ngIf="
                registerForm.controls.password.errors?.required &&
                registerForm.controls.password.touched">
            <span>{{ 'Este campo es obligatorio' | translate }}</span>
            </mat-error>
          </mat-form-field> -->
        </div>

        <div fxFlex="0.5rem"></div>

        <app-password-hints
          *ngIf="focusPassword"
          [formControl]="registerForm.controls.password"
        ></app-password-hints>

        <div fxFlex="0.5rem"></div>

        <div fxLayout="row" fxLayoutGap="0.5rem">
          <button
            fxFlex="50%"
            mat-flat-button
            color="primary"
            routerLink="/auth/login"
            *ngIf="router.url.includes('/auth/register')"
          >
            <span>{{
              "auth.login.return login" | translate | capitalize
            }}</span>
          </button>

          <button
            fxFlex="50%"
            mat-flat-button
            class="btnRegister"
            [disabled]="registerForm.invalid"
            (click)="register()"
          >
            {{ "auth.login.register" | translate | capitalize }}
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
