import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { debounceTime, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Logger } from "@app/@core";
import { FormControl, FormGroup } from "@angular/forms";

export enum UserFormFields {}

@Component({
  selector: "app-users-collection-list-filter",
  templateUrl: "./users-collection-list-filter.component.html",
  styleUrls: ["./users-collection-list-filter.component.scss"],
})
export class UsersCollectionListFilterComponent implements OnInit {
  log = new Logger(UsersCollectionListFilterComponent.name);

  @Input() isLoading = false;
  @Input() hasRefreshButton = true;

  @Output() buttonRefreshEvent = new EventEmitter<null>();
  @Output() filterEvent = new EventEmitter<{
    query: string;
    type: string;
    status: string;
    group_id: string;
  }>();

  groupList: any;
  editable: boolean = true;
  userForm = new FormGroup({});

  constructor(
    private formBuilder: UntypedFormBuilder,
    public router: Router,
  ) {}

  usersGroupForm: UntypedFormGroup;
  private _onDestroy = new Subject<void>();

  ngOnInit(): void {
    this.usersGroupForm = this.formBuilder.group({
      querytext: [""],
      type: [""],
      status: [""],
      group_id: [""],
    });

    this.usersGroupForm.valueChanges
      .pipe(debounceTime(300))
      .pipe(takeUntil(this._onDestroy))
      .subscribe((formValue) => {
        let query = formValue.querytext;
        let type = this.usersGroupForm.controls.type.value as string;
        let status = this.usersGroupForm.controls.status.value as string;
        let group_id = this.usersGroupForm.controls.group_id.value as string;
        this.filterEvent.emit({
          query,
          type: type,
          status: status,
          group_id: group_id,
        });
      });
  }
  onSelect(selectorControl: FormControl, formControl: FormControl) {
    formControl.setValue(selectorControl.value.id);
  }
  onButtonRefreshPressed() {
    this.buttonRefreshEvent.emit();
  }

  // Create button user
  onButtonCreate(): void {
    this.router.navigate(["/users/register"]);
  }
}
