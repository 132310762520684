<div fxLayout="column">
  <mat-form-field fxFlex color="primary">
    <mat-label>{{
      (label ? label : "housing.title") | translate | capitalize
    }}</mat-label>
    <mat-select #matSelector [formControl]="housingControl" [multiple]="false">
      <mat-select-trigger>
        <span style="max-width: 500px" formatTruncate>
          <app-housing-address
            [entity]="housingControl?.value"
          ></app-housing-address>
        </span>
      </mat-select-trigger>
      <mat-option>
        <ngx-mat-select-search
          [formControl]="housingFilterControl"
          [searching]="isLoading"
          placeholderLabel="{{ 'app.actions.search' | translate | capitalize }}"
          noEntriesFoundLabel="{{ 'any result' | translate }}"
        >
          <mat-icon ngxMatSelectSearchClear class="mat-select-search-button"
            >close</mat-icon
          >
        </ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let house of housingFiltered | async" [value]="house">
        <div fxLayout="column">
          <span formatTruncate>
            <app-housing-address [entity]="house"></app-housing-address>
          </span>
          <span class="mat-suboption-text">
            {{ house?.land_registry_reference }}
          </span>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
