<div>
  <mat-card class="auth-login-container">
    <mat-card-content>
      <mat-card-title class="card-title">
        <img
          class="logo"
          src="assets/images/logo_urvial_blanco.png"
          alt="URVIAL logo"
          style="width: 350px"
        />

        <div fxFlex="1.0rem"></div>

        <strong class="title" translate
          >Bienvenido a la aplicación de URVIAL</strong
        >
      </mat-card-title>
    </mat-card-content>
  </mat-card>
</div>
