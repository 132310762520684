// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from "./.env";

export const environment = {
  production: false,
  serviceWorker: true,
  serverUrl: "https://api.preproduction.urvial-sat.albacete.es/api",
  baseUrl: "https://preproduction.urvial-sat.albacete.es",
  version: env.npm_package_version + "-preproduction",
  aws: {
    auth: {
      identityPoolId: "eu-west-1:4ccc0d64-aa3b-476a-abe5-4a1e2e917b63",
      region: "eu-west-1",
      userPoolId: "eu-west-1_0ZXXGskzy",
      userPoolWebClientId: "18l2n3ofo0ph7ip738v4cp3puo",
      mandatorySignIn: true,
      oauth: {
        domain: "urvial-preproduction.auth.eu-west-1.amazoncognito.com",
        scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
        redirectSignIn: "https://preproduction.urvial-sat.albacete.es",
        redirectSignOut: "https://preproduction.urvial-sat.albacete.es",
        responseType: "token",
        options: {
          AdvancedSecurityDataCollectionFlag: false,
        },
      },
    },
    storage: {
      s3: {
        region: "eu-west-1",
        bucket: "urvial.storage.preproduction",
        customPrefix: { public: "", protected: "", private: "" },
      },
    },
  },
  cache: {
    Key: "http_cache",
    Persistence: "local" /* local / session / memory */,
    Duration: 60 * 24 /* minutes */,
  },
  webhook: {
    Endpoint: "",
    Key: "",
    Token: "",
  },
  googleMapsApiKey: "AIzaSyC-qEoInIbw2kq_K7i0iM3bY1PkF-a-dyE",
};
