<div fxFlexFill class="sidenav-container">
  <mat-toolbar class="sidenav-container-toolbar">
    <div fxFlexFill fxLayout="row" fxLayoutAlign="end center">
      <span>{{ "settings" | translate | capitalize }}</span>
      <div fxFlex></div>
      <button mat-icon-button (click)="closeSidenav()">
        <mat-icon>highlight_off</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <div class="mat-container">
    <span>Content</span>
  </div>
</div>
