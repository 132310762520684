import { Base } from "./_base";
import { Tenant } from "./tenant";

export interface Housing {
  id?: number;
  group_id?: number;
  project?: string;
  type?: string;
  description?: string;
  vestibule?: string;
  street?: string;
  location_latitude?: string;
  location_longitude?: string;
  number?: number;
  block?: string;
  staircase?: string;
  floor?: string;
  door?: string;
  zip_code?: string;
  town?: string;
  built_m2?: number;
  usable_m2?: number;
  common_areas_m2?: number;
  bedrooms_total?: number;
  bathrooms_total?: number;
  has_clothes_horse?: boolean;
  floors_total?: number;
  balconies_total?: number;
  terraces_total?: number;
  terrace_m2?: number;
  acs?: string;
  plot?: string;
  block_quote?: number;
  urban_quote?: number;
  land_horizontal_division_number?: number;
  land_horizontal_division_letter?: string;
  deposit_months_total?: number;
  land_registry_reference?: string;
  has_efficiency_certificate?: boolean;
  efficiency_certificate_date?: Date;
  energy_rating?: string;
  full_street?: string;

  group?: HousingGroups;
  tenants?: Tenant[];
}

export enum HousingFields {
  id = "id",
  group_id = "group_id",
  project = "project",
  type = "type",
  description = "description",
  vestibule = "vestibule",
  street = "street",
  location_latitude = "location_latitude",
  location_longitude = "location_longitude",
  number = "number",
  block = "block",
  staircase = "staircase",
  floor = "floor",
  door = "door",
  zip_code = "zip_code",
  town = "town",
  built_m2 = "built_m2",
  usable_m2 = "usable_m2",
  common_areas_m2 = "common_areas_m2",
  bedrooms_total = "bedrooms_total",
  bathrooms_total = "bathrooms_total",
  has_clothes_horse = "has_clothes_horse",
  floors_total = "floors_total",
  balconies_total = "balconies_total",
  terraces_total = "terraces_total",
  terrace_m2 = "terrace_m2",
  acs = "acs",
  plot = "plot",
  block_quote = "block_quote",
  urban_quote = "urban_quote",

  land_horizontal_division_number = "land_horizontal_division_number",
  land_horizontal_division_letter = "land_horizontal_division_letter",
  deposit_months_total = "deposit_months_total",
  land_registry_reference = "land_registry_reference",
  has_efficiency_certificate = "has_efficiency_certificate",
  efficiency_certificate_date = "efficiency_certificate_date",
  energy_rating = "energy_rating",
  full_street = "full_street",

  group = "group",
  tenants = "tenants",
}

export interface HousingGroups extends Base {
  uuid?: string;
  address?: string;
  location_latitude?: string;
  location_longitude?: string;
  admin_name?: string;
  admin_surname?: string;
  admin_email?: string;
  admin_phone?: string;
  name?: string;
  houses?: Housing[];
}

export interface HousingGroupsFiles extends Base {
  housing_group_id?: number;

  user_email?: string;
  reference?: string;
  file_name: string;
  file_size: number;
  file_type: string;
  file_tag?: string;
  file_path: string;
  is_deleted?: boolean;
  file_path_signed?: string;

  user_id?: number;
}

export enum HousingAcs {
  COMUNITARIA_CENTRALIDA = "COMUNITARIA CENTRALIZADA",
  TERMO_ELECTRICO = "TERMO ELECTRICO",
  CALDERA_ESTANCA_GAS = "CALDERA ESTANCA GAS",
  CALDERA_ATMOSFERICA_GAS = "CALDERA ATMOSFERICA GAS",
  GICA = "GICA",
  AEROTERMIA = "AEROTERMIA",
  NO = "NO",
  SIN_ESPECIFICAR = "SIN ESPECIFICAR",
  OTHER = "OTRO",
}
export const housingAcsList = Object.values(HousingAcs);

export enum HousingHeating {
  COMUNITARIA_CENTRALIDA = "COMUNITARIA CENTRALIZADA",
  CALDERA_ESTANCA_GAS = "CALDERA ESTANCA GAS",
  CALDERA_GASOLEO = "CALDERA GASOLEO",
  GICA = "GICA",
  AEROTERMIA = "AEROTERMIA",
  FANCOILS_ELECTRICOS = "FANCOILS ELECTRICOS",
  NO = "NO",
  SIN_ESPECIFICAR = "SIN ESPECIFICAR",
  OTHER = "OTRO",
}
export const housingHeatingList = Object.values(HousingHeating);
