<span *ngIf="entity?.street">{{ entity?.street }}</span>
<span *ngIf="entity?.number"
  >{{ "housing.housingList.number" | translate }}:{{ entity?.number }}</span
>
<span *ngIf="entity?.block"
  >- {{ "housing.housingList.block" | translate }}Blq: {{ entity?.block }}</span
>
<span *ngIf="entity?.vestibule"
  >- {{ "housing.housingList.vestibule" | translate }}:
  {{ entity?.vestibule }}</span
>
<span *ngIf="entity?.staircase"
  >- {{ "housing.housingList.staircase" | translate }}:
  {{ entity?.staircase }}</span
>
<span *ngIf="entity?.floor"
  >- {{ "housing.housingList.floor" | translate }}: {{ entity?.floor }}</span
>
<span *ngIf="entity?.door"
  >- {{ "housing.housingList.door" | translate }}: {{ entity?.door }}</span
>
