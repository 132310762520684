<div fxFlexFill class="sidenav-container" style="overflow-x: hidden">
  <div fxLayout="row">
    <div
      class="menu-logo"
      [ngClass]="isExpanded ? 'open' : 'close'"
      fxFlex
      fxLayoutAlign="center center"
    >
      <img
        class="logo"
        src="assets/images/logo_urvial_blanco.png"
        alt="URVIAL logo"
      />

      <button
        class="menu-button"
        mat-icon-button
        (click)="toggleSidenavPrimaryEvent.emit()"
      >
        <mat-icon>{{
          isExpanded ? "keyboard_arrow_left" : "keyboard_arrow_right"
        }}</mat-icon>
      </button>
    </div>
  </div>

  <nav>
    <mat-list>
      <ng-container *ngFor="let module of modules">
        <!-- MODULE (SIMPLE) -->
        <div *ngIf="!module.submodules && module.sidenav">
          <mat-list-item
            [matTooltip]="module.label | translate | capitalize"
            matRipple
            [ngClass]="{ 'module-active': module.path === router.url }"
          >
            <div
              fxFlex
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="0.5rem"
              routerLink="{{ module.path }}"
              routerLinkActive="active"
            >
              <mat-icon mat-list-icon>{{ module.icon }}</mat-icon>
              <span [fxHide]="!isExpanded">{{
                module.label | translate | capitalize
              }}</span>
            </div>
            <!-- <mat-divider fxFlex></mat-divider> -->
          </mat-list-item>
        </div>

        <!-- MODULE (MULTIPLE SUBMODULES) -->
        <div *ngIf="module.submodules && module.sidenav">
          <mat-list-item
            [matTooltip]="module.label | translate | capitalize"
            matRipple
            (click)="module.expanded = !module.expanded"
          >
            <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
              <div
                fxFlex
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="0.5rem"
              >
                <mat-icon *ngIf="isExpanded" mat-list-icon>{{
                  module.icon
                }}</mat-icon>
                <mat-icon
                  *ngIf="!isExpanded"
                  [matMenuTriggerFor]="menumodules"
                  mat-list-icon
                  >{{ module.icon }}</mat-icon
                >
                <span [fxHide]="!isExpanded">{{
                  module.label | translate | capitalize
                }}</span>
              </div>
              <mat-icon
                *ngIf="isExpanded"
                class="submenu-button"
                [ngClass]="{ rotated: module.expanded }"
                >expand_more</mat-icon
              >
            </div>
            <!-- <mat-divider fxFlex></mat-divider> -->
          </mat-list-item>
          <div class="submenu" *ngIf="isExpanded" [@collapse]="module.expanded">
            <ng-container *ngFor="let submodule of module.submodules">
              <mat-list-item
                [matTooltip]="submodule.label | translate | capitalize"
                matRipple
                [ngClass]="{
                  'submodule-active': submodule.path === router.url
                }"
                *ngIf="submodule.sidenav"
              >
                <div
                  fxFlex
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="0.5rem"
                  routerLink="{{ submodule.path }}"
                  routerLinkActive="active"
                >
                  <span [fxHide]="!isExpanded">{{
                    submodule.label | translate | capitalize
                  }}</span>
                </div>
              </mat-list-item>
            </ng-container>
          </div>
        </div>
        <mat-menu #menumodules="matMenu">
          <ng-container *ngFor="let submodule of module.submodules">
            <button
              routerLink="{{ submodule.path }}"
              routerLinkActive="active"
              mat-menu-item
            >
              <span [fxHide]="isExpanded">{{
                submodule.label | translate | capitalize
              }}</span>
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>
    </mat-list>
  </nav>
</div>
