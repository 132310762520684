<div fxLayout="column" fxLayoutGap="0.5rem">
  <div *ngIf="this.filters">
    <app-users-collection-list-filter
      (filterEvent)="onChangeFilter($event)"
      (buttonRefreshEvent)="onRefresh()"
    ></app-users-collection-list-filter>
  </div>
  <mat-card fxFlex fxLayout="column">
    <mat-card-title>
      {{ "companies.company.users_company" | translate | capitalize }}
    </mat-card-title>
    <mat-card-content list-table-scrollable>
      <div fxLayout="column" class="customTable">
        <table
          mat-table
          [dataSource]="users?.items"
          multiTemplateDataRows
          class="table_custom"
        >
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef>
              {{ "users.user.code" | translate | uppercase }}
            </th>
            <td mat-cell *matCellDef="let user">{{ user.id }}</td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>
              {{ "users.user.type.title" | translate | uppercase }}
            </th>
            <td mat-cell *matCellDef="let user">
              {{ "users.user.type." + user.type | translate | uppercase }}
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
              {{ "users.user.name" | translate | uppercase }}
            </th>
            <td mat-cell *matCellDef="let user">
              {{ user.name }} {{ user.surname }}
            </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>
              {{ "users.user.email" | translate | uppercase }}
            </th>
            <td mat-cell *matCellDef="let user">{{ user.email }}</td>
          </ng-container>

          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef>
              {{ "users.user.phone" | translate | uppercase }}
            </th>
            <td mat-cell *matCellDef="let user">{{ user.phone }}</td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
              {{ "users.user.status.title" | translate | uppercase }}
            </th>
            <td mat-cell *matCellDef="let user">
              {{ "users.user.status." + user.status | translate | uppercase }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: displayedColumns"
            class="data-element-row"
          ></tr>
          <mat-progress-bar
            *ngIf="isLoading && !users?.items?.length"
            mode="indeterminate"
          ></mat-progress-bar>
          <div
            *ngIf="!users?.items?.length"
            fxLayoutAlign="center center"
            fxFlex
            class="list-table-notFound"
          >
            <mat-icon>info</mat-icon>
            <span translate>NotFoundRegisters</span>
          </div>
        </table>
      </div>
    </mat-card-content>

    <mat-paginator
      [length]="users?.total"
      [pageIndex]="users?.page"
      [pageSize]="users?.page_size"
      (page)="onChangePage($event)"
    >
    </mat-paginator>
  </mat-card>
</div>
