import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";

import { AuthSessionGuard } from "./auth.guard";

import { AuthComponent } from "./auth.component";
import { AuthLoginComponent } from "./login/login.component";
import { AuthRegisterComponent } from "./register/register.component";
import { AuthConfirmationComponent } from "./confirmation/auth-confirmation.component";
import { AuthForgotComponent } from "./forgot/auth-forgot.component";

const routes: Routes = [
  {
    path: "auth",
    component: AuthComponent,
    children: [
      { path: "", redirectTo: "login", pathMatch: "full" },
      {
        path: "login",
        component: AuthLoginComponent,
        data: { title: marker("auth.login.title") },
      },
      {
        path: "register",
        component: AuthRegisterComponent,
        data: { title: marker("auth.register.title") },
      },
      {
        path: "confirmation",
        component: AuthConfirmationComponent,
        data: { title: marker("auth.confirmation.title") },
      },
      {
        path: "forgot",
        component: AuthForgotComponent,
        data: { title: marker("auth.recover password.title") },
      },
    ],
    canActivate: [AuthSessionGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthRoutingModule {}
