import { Injectable } from "@angular/core";
import { HttpResponse } from "@angular/common/http";

import { Logger } from "../services/logger.service";
import { environment } from "@env/environment";

const log = new Logger("HttpCacheService");

export interface HttpCacheEntry {
  datetimeUpdate: Date;
  datetimeExpiration: Date;
  data: HttpResponse<any>;
  size: string;
}

/**
 * Provides a cache facility for HTTP requests with configurable persistence policy.
 */
@Injectable()
export class CacheService {
  private cachePersistenceKey: string = "http_cache";
  private cachedData: { [key: string]: HttpCacheEntry } = {};
  private storage: Storage | null = null;

  constructor() {
    if (environment.cache) {
      this.cachePersistenceKey = environment.cache.Key;
      this.setPersistence(environment.cache.Persistence);
    } else {
      this.loadCacheData();
    }
  }

  /**
   * Sets the cache data for the specified request.
   * @param url The request URL.
   * @param data The received data.
   * @param datetimeUpdate The cache last update, current date is used if not specified.
   */
  setCacheData(url: string, data: HttpResponse<any>, datetimeUpdate?: Date) {
    this.cachedData[url] = {
      datetimeUpdate: datetimeUpdate || new Date(),
      datetimeExpiration: this._getExpirationDatetime(),
      size: `${(parseInt(data.headers.get("content-length")) / 1024).toFixed(2)}kb`,
      data: data,
    };
    log.debug(`Cache set for key: "${url}"`);
    this.saveCacheData();
  }

  /**
   * Gets the cached data for the specified request.
   * @param url The request URL.
   * @return The cached data or null if no cached data exists for this request.
   */
  getCacheData(url: string): HttpResponse<any> | null {
    const cacheEntry = this.cachedData[url];

    if (cacheEntry) {
      if (
        cacheEntry.datetimeExpiration &&
        new Date(cacheEntry.datetimeExpiration) < new Date()
      )
        return null;

      log.debug(`Cache hit for key: "${url}"`);
      return cacheEntry.data;
    }

    return null;
  }

  /**
   * Gets the cached entry for the specified request.
   * @param url The request URL.
   * @return The cache entry or null if no cache entry exists for this request.
   */
  getHttpCacheEntry(url: string): HttpCacheEntry | null {
    return this.cachedData[url] || null;
  }

  /**
   * Clears the cached entry (if exists) for the specified request.
   * @param url The request URL.
   */
  clearCache(url: string): void {
    delete this.cachedData[url];
    log.debug(`Cache cleared for key: "${url}"`);
    this.saveCacheData();
  }

  /**
   * Cleans cache entries
   * @param onlyClearExpirations The cache entries expirations. If not is specified, all cache is cleared.
   */
  cleanCache(onlyClearExpirations: Boolean = false) {
    if (onlyClearExpirations) {
      Object.entries(this.cachedData).forEach(([key, value]) => {
        if (
          value.datetimeExpiration &&
          new Date(value.datetimeExpiration) < new Date()
        ) {
          log.debug(`Cache delete for key: "${key}"`);
          delete this.cachedData[key];
        }
      });
    } else {
      this.cachedData = {};
    }

    this.saveCacheData();
  }

  /**
   * Sets the cache persistence policy.
   * Note that changing the cache persistence will also clear the cache from its previous storage.
   * @param persistence How the cache should be persisted, it can be either local or session storage, or if no value is
   *   provided it will be only in-memory (default).
   */
  setPersistence(persistence?: String) {
    this.cleanCache();
    this.storage =
      persistence === "local" || persistence === "session"
        ? window[persistence + "Storage"]
        : null;
    this.loadCacheData();
    this.cleanCache(true);
  }

  private saveCacheData() {
    if (this.storage) {
      this.storage.setItem(
        this.cachePersistenceKey,
        JSON.stringify(this.cachedData),
      );
    }
  }

  private loadCacheData() {
    const data = this.storage
      ? this.storage.getItem(this.cachePersistenceKey)
      : null;
    this.cachedData = data ? JSON.parse(data) : {};
  }

  private _getExpirationDatetime() {
    const _date = new Date();
    const _duration = environment.cache.Duration;

    if (_duration) {
      _date.setMinutes(_date.getMinutes() + _duration);
      return _date;
    }

    return null;
  }
}
