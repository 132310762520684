<!-- BUTTON -->
<button
  *ngIf="icon; else text"
  mat-icon-button
  [matMenuTriggerFor]="languageMenu"
>
  <mat-icon>language</mat-icon>
</button>
<ng-template #text>
  <button mat-raised-button color="primary" [matMenuTriggerFor]="languageMenu">
    {{ currentLanguage }}
  </button>
</ng-template>

<!-- BUTTON > Menu -->
<div class="language-selector-menu-container">
  <mat-menu #languageMenu="matMenu">
    <button
      mat-menu-item
      *ngFor="let language of languages"
      (click)="setLanguage(language.code)"
    >
      <div fxFLexLayout="row" fxLayoutAlign="start center" fxLayoutGap="1.0rem">
        <img
          src="assets/images/language/flags/flag-{{ language.code }}.jpg"
          alt="language flag"
          class="language-flag"
        />
        <span>{{ language.label | translate | titlecase }}</span>
      </div>
    </button>
  </mat-menu>
</div>
