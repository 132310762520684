import { Base } from "./_base";
import { Housing } from "./housing";

export interface User extends Base {
  role_id?: number;
  email?: string;
  username?: string;
  name?: string;
  surname?: string;
  dni?: string;
  phone?: string;
  last_access_dt?: Date;
  language?: string;
  type?: UserTypes;
  status?: UserStatus;
  housing?: Housing[];

  role?: Role;
}

export enum UserFields {
  role_id = "role_id",
  email = "email",
  username = "username",
  name = "name",
  surname = "surname",
  dni = "dni",
  phone = "phone",
  last_access_dt = "last_access_dt",
  language = "language",
  type = "type",
  status = "status",
  housing = "housing",
  role = "role",
}

export enum UserStatus {
  registered = "registered",
  confirmed = "confirmed",
  rejected = "rejected",
  disabled = "disabled",
}

export enum UserTypes {
  urvial_administrator = "urvial_administrator",
  company_admin = "company_admin",
  technician = "technician",
  tenant = "tenant",
  user = "user",
}

// PERMISSIONS =================================================================

export interface Role extends Base {
  name: string;
  description?: string;

  modules?: Array<Module>;
}

export interface Module extends Base {
  name?: string;
  code: string;
  path: string;
  description?: string;

  permissions: Array<String>;
}

export interface Permission extends Base {
  code: string;
  description: string;
}
