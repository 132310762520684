export const languageSettings = {
  defaultLanguage: "es-ES",
  supportedLanguages: [
    {
      code: "es-ES",
      label: "app.languages.spanish",
    },
    {
      code: "en-US",
      label: "app.languages.english",
    },
  ],
};
