import { Component, Input, OnInit } from "@angular/core";
import { Housing } from "@app/@shared/models/housing";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-housing-address",
  templateUrl: "./housing-address.component.html",
  styleUrls: ["./housing-address.component.scss"],
})
export class HousingAddressComponent implements OnInit {
  private _entity: Housing;

  @Input() set entity(value: Housing) {
    this._entity = value;
  }
  get entity(): Housing {
    return this._entity;
  }

  constructor(private translateService: TranslateService) {}

  ngOnInit() {}

  public getFullAddress(house: Housing): string {
    let fullAddress = "";
    if (house) {
      if (house.street) {
        fullAddress += `${house.street}`;
      }
      if (house.number) {
        fullAddress += ` ${this.translateService.instant(
          "housing.housing.number",
        )} ${house.number}`;
      }
      if (house.block) {
        fullAddress += ` ${this.translateService.instant(
          "housing.housing.block",
        )} ${house.block}`;
      }

      if (house.floor) {
        fullAddress += ` ${this.translateService.instant(
          "housing.housing.floor",
        )} ${house.floor}`;
      }
    }
    return fullAddress;
  }
}
