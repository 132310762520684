import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { IDialogData } from "../dialogs.interfaces";

@Component({
  selector: "app-confirm",
  templateUrl: "./confirm.component.html",
  styleUrls: ["./confirm.component.scss"],
})
export class ConfirmComponent {
  title: string;
  message: string;
  acceptButton: string;
  cancelButton: string;
  closeButton: boolean;
  color: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: IDialogData) {
    this.title = data.title || "app.actions.confirm";
    this.message = data.message;
    this.acceptButton = data.acceptButton || "app.actions.yes";
    this.cancelButton = data.cancelButton || "app.actions.no";
    this.closeButton = data.closeButton;
    this.color = data.color || "primary";
  }
}
