import {
  DecimalPipe,
  getLocaleNumberSymbol,
  NumberSymbol,
} from "@angular/common";
import { Directive, ElementRef, HostListener, OnInit } from "@angular/core";
import { NgControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Directive({
  selector: "input[formatNumber]",
  providers: [DecimalPipe],
})
export class FormatNumberDirective implements OnInit {
  private el: HTMLInputElement;

  keyPressed: Boolean = false;
  cursorIndex: number;
  initialLength: number;
  finalLength: number;
  finalValue: string;
  reg: RegExp = new RegExp(
    getLocaleNumberSymbol(
      this.translateService.currentLang,
      NumberSymbol.Group,
    ),
    "g",
  );

  constructor(
    elementRef: ElementRef,
    private formControl: NgControl,
    private decimalPipe: DecimalPipe,
    private translateService: TranslateService,
  ) {
    this.el = elementRef.nativeElement;
  }

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe(() => this.refreshFormat());
    this.formControl.valueChanges.subscribe(() => this.setValue());
    // Initial formatting
    this.refreshFormat();
  }

  @HostListener("keydown", ["$event", "$event.target"])
  onKeyDown(e: KeyboardEvent, t: any) {
    this.keyPressed = true;
    this.initialLength = this.el.value.length;
    this.cursorIndex = this.el.selectionEnd;
    if (
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Ctrl+A
      (e.keyCode == 65 && (e.ctrlKey || e.metaKey)) ||
      // Ctrl+C
      (e.keyCode == 67 && (e.ctrlKey || e.metaKey)) ||
      // Ctrl+V
      (e.keyCode == 86 && (e.ctrlKey || e.metaKey)) ||
      // Ctrl+X
      (e.keyCode == 88 && (e.ctrlKey || e.metaKey)) ||
      // Home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }
    // only allow numbers and max length 15
    if (
      ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
        (e.keyCode < 96 || e.keyCode > 105)) ||
      this.el.value.length >= 15
    ) {
      e.preventDefault();
    }
  }

  private setValue() {
    if (
      (this.el.value != this.finalValue || !this.keyPressed) &&
      this.el.value
    ) {
      this.setInputValue(this.el.value);
      this.finalLength = this.el.value.length;
      this.cursorIndex += this.finalLength - this.initialLength;
      this.el.selectionStart = this.cursorIndex;
      this.el.selectionEnd = this.cursorIndex;
      this.finalValue = this.el.value;
    }
    this.keyPressed = false;
  }

  private formatNumber(s: string): string {
    if (s) {
      let n = this.unformatNumber(s);
      return isNaN(n) ? null : this.decimalPipe.transform(n, "1.0-0");
    } else {
      return null;
    }
  }

  private unformatNumber(s: string): number {
    return s ? parseInt(s.replace(this.reg, "")) : null;
  }

  private setInputValue(value: string) {
    this.formControl.control.setValue(this.unformatNumber(value), {
      emitEvent: false,
    });
    this.el.value = this.formatNumber(value);
  }

  private refreshFormat() {
    if (this.el.value) {
      let n = this.unformatNumber(this.el.value).toString();
      this.decimalPipe = new DecimalPipe(this.translateService.currentLang);
      this.reg = new RegExp(
        getLocaleNumberSymbol(
          this.translateService.currentLang,
          NumberSymbol.Group,
        ),
        "g",
      );
      this.setInputValue(n);
    }
  }
}
