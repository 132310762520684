import { Component, Input, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Logger } from "@app/@core/services/logger.service";
import { AdvicesService } from "@app/@shared/api/advices.service";
import { WorksheetsService } from "@app/@shared/api/worksheets.service";
import { RequestParams, ResultPaginated } from "@app/@shared/models/_results";
import { NotificationService } from "@app/@shared/services/notification.service";
import { finalize } from "rxjs/operators";
import { expandableRowAnimation } from "@app/@shared/animations/expandable-row.animation";

@Component({
  selector: "app-audit",
  templateUrl: "./audit.component.html",
  styleUrls: ["./audit.component.scss"],
  animations: [expandableRowAnimation],
})
export class AuditComponent implements OnInit {
  log = new Logger(AuditComponent.name);
  auditHistory: ResultPaginated<any>;
  displayedColumns: string[] = ["action_op", "action_user", "properties", "#"];
  requestAuditParams = new RequestParams();
  expandedElement: any | null = null;
  previousElement: any;

  private _id: number;
  private _entity: string;
  isLoading: boolean = false;

  @Input() set id(value: number) {
    this._id = value;
  }
  get id(): number {
    return this._id;
  }

  @Input() set entity(value: string) {
    this._entity = value;
  }
  get entity(): string {
    return this._entity;
  }

  constructor(
    private advicesService: AdvicesService,
    private notificationService: NotificationService,
    private worksheetsService: WorksheetsService,
  ) {}

  ngOnInit(): void {
    this.requestAuditParams.order = [{ type: "desc", field: "created_on" }];
    this.requestAuditParams.filter = [
      { value: this.id.toString(), field: "entity_id" },
    ];
    this.getAudit();
  }

  getAudit() {
    if (this.entity == "advices") {
      this.getAuditAdvices();
    } else if (this.entity == "worksheets") {
      this.getAuditWorksheets();
    }
  }

  getAuditAdvices() {
    this.advicesService
      .getAdvicesAuditHistory(this.requestAuditParams)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (result) => {
          this.auditHistory = result;
        },
        error: (error) => {
          this.log.error("Error get audit advices: ", error);
          this.notificationService.open("app.messages.operation_failed");
        },
      });
  }

  getAuditWorksheets() {
    this.worksheetsService
      .getWorksheetsAuditHistory(this.requestAuditParams)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (result) => {
          this.auditHistory = result;
        },
        error: (error) => {
          this.log.error("Error get audit advices: ", error);
          this.notificationService.open("app.messages.operation_failed");
        },
      });
  }

  getDifferentProperties(current: any): any {
    const ignoreFields = [
      "id",
      "entity_id",
      "created_on",
      "updated_on",
      "action_op",
      "action_user",
    ];
    const differentProps: any = {};

    // Obtener el siguiente objeto
    const index = this.auditHistory.items.indexOf(current);
    const nextObject =
      index < this.auditHistory.items.length - 1
        ? this.auditHistory.items[index + 1]
        : null;

    for (const key in current) {
      if (
        !ignoreFields.includes(key) &&
        nextObject &&
        nextObject[key] !== current[key]
      ) {
        differentProps[key] = current[key];
      }
    }
    return differentProps;
  }

  getNextHistory(history: any): any {
    const index = this.auditHistory.items.indexOf(history);
    return index < this.auditHistory.items.length - 1
      ? this.auditHistory.items[index + 1]
      : null;
  }

  onChangePage(eventPage: PageEvent) {
    this.requestAuditParams.pagination = {
      pageIndex: eventPage.pageIndex,
      pageSize: eventPage.pageSize,
    };
    this.getAudit();
  }
}
