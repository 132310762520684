<div class="loader-container">
  <div *ngIf="isLoadingNetwork | async" class="loader-network-overlay">
    <mat-progress-bar
      [mode]="mode"
      [value]="value"
      [color]="color"
    ></mat-progress-bar>
  </div>
  <div *ngIf="isLoadingNavigation | async" class="loader-navigation-overlay">
    <mat-progress-spinner
      [mode]="mode"
      [strokeWidth]="3"
      [diameter]="32 * size"
      [color]="color"
    ></mat-progress-spinner>
    <span>{{ message }}</span>
  </div>
</div>
