import { Component, OnInit } from "@angular/core";
import { PermissionService } from "@app/@core";

@Component({
  selector: "app-permission-restriction",
  templateUrl: "./permission-restriction.component.html",
  styleUrls: ["./permission-restriction.component.scss"],
})
export class PermissionRestrictionComponent implements OnInit {
  permissionRole: string;
  progressOperation: boolean = false;
  constructor(private permissionService: PermissionService) {}

  ngOnInit(): void {
    this.getPermission();
  }

  getPermission() {
    this.progressOperation = true;

    this.permissionService
      .getRolePermissions()
      .then((result) => {
        this.permissionRole = result.role.name;
      })
      .finally(() => {
        this.progressOperation = false;
      });
  }
}
