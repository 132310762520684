<mat-form-field fxLayout="column">
  <mat-label> {{ label | translate }} </mat-label>
  <mtx-select
    [items]="itemList"
    [formControl]="control"
    [multiple]="false"
    [loading]="isLoading"
    [compareWith]="compareWith"
    [clearable]="this.hasClearButton"
    [clearAllText]="'_common.remove' | translate"
    [typeahead]="peopleInput$"
    [virtualScroll]="true"
    [items]="itemList"
    (scrollToEnd)="onScrollToEnd()"
  >
    <ng-template ng-label-tmp let-item="item">
      {{ getShowedText(item) }}
    </ng-template>

    <ng-template fxFlex ng-option-tmp let-item="item">
      {{ getShowedText(item) }}
    </ng-template>
  </mtx-select>
  <!-- <mat-icon
    *ngIf="hasClearButton && control.value"
    (click)="onCloseButtonClicked()"
    matSuffix
    >close</mat-icon
  > -->
  <mat-icon
    *ngIf="hasRefreshButton && !isLoading"
    (click)="onRefreshButtonClicked()"
    matSuffix
    color="primary"
  >
    refresh
  </mat-icon>
  <mat-hint *ngIf="isRequired() && control.untouched"> </mat-hint>

  <mat-error *ngIf="control.hasError('required')">
    {{ "_common.thisFieldIsRequired" | translate }}
  </mat-error>
</mat-form-field>
