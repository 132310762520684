import { FormControl } from "@angular/forms";
import { SortDirection } from "@angular/material/sort";
import { AppConstants } from "@app/@shared/app-constants";
import { BaseFields } from "../../_base";

export interface DefaultFilterForm {
  query: FormControl<string>;
  pageIndex: FormControl<number>;
  pageSize: FormControl<number>;
  sortField: FormControl<string>;
  sortDirection: FormControl<SortDirection>;
}
export const defaultFilterFormValue: DefaultFilterForm = {
  query: new FormControl<string>("", { nonNullable: true }),
  pageIndex: new FormControl<number>(0, { nonNullable: true }),
  pageSize: new FormControl<number>(AppConstants.DEFAULT_PAGE_SIZE, {
    nonNullable: true,
  }),
  sortField: new FormControl<string>(BaseFields.CreatedOn, {
    nonNullable: true,
  }),
  sortDirection: new FormControl<SortDirection>("desc", { nonNullable: true }),
};
