import { Component, Input, OnInit, forwardRef } from "@angular/core";
import {
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { AppConstants } from "@app/@shared/app-constants";
import {
  RequestFilter,
  RequestEntity,
  RequestPagination,
  RequestParams,
} from "@app/@shared/models/_results";
import { GeneralService } from "@app/@shared/services/general.service";
import { NotificationService } from "@app/@shared/services/notification.service";
import { Logger } from "aws-amplify";
import { Subject } from "rxjs";
import { debounceTime, takeUntil, distinct, finalize } from "rxjs/operators";

@Component({
  selector: "app-multi-general-selector",
  templateUrl: "./multi-general-selector.component.html",
  styleUrls: ["./multi-general-selector.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiGeneralSelectorComponent),
      multi: true,
    },
  ],
})
export class MultiGeneralSelectorComponent implements OnInit {
  @Input() entity: string;
  @Input() value?: number[];
  @Input() field_entity: string;
  @Input() showed_field: string;
  @Input() isRequired: boolean = false;
  @Input() depends: boolean = false;
  private _isDisabled: boolean = false;
  public get isDisabled(): boolean {
    return this._isDisabled;
  }
  @Input()
  public set isDisabled(value: boolean) {
    this._isDisabled = value;
    value ? this.formControl.disable() : this.formControl.enable();
  }

  //RequestFilter --> getter and setter to update value param
  private _filter: any[];
  public get filter(): any[] {
    return this._filter;
  }

  @Input() public set filter(filter: RequestFilter[]) {
    // Example add HTML [filter]="[{field:'ceco',value:'P01203-PI'}]"
    if (filter[0]?.value !== null) {
      this._filter = filter;
      this.param.filter = this.filter;
      this.loadData();
    }
  }

  //RequestEntity --> getter and setter to update value param
  private _entity_param: any[];
  public get entity_param(): any[] {
    return this._entity_param;
  }

  @Input() public set entity_param(entity_param: RequestEntity[]) {
    // Example add HTML [filter]="[{field:'ceco',value:'P01203-PI'}]"
    if (entity_param[0]?.value !== null) {
      this._entity_param = entity_param;
      this.param.entity = this.entity_param;
      this.loadData();
    }
  }

  private _currentEntityList: any[];
  public get currentEntityList(): any[] {
    return this._currentEntityList;
  }
  @Input() public set currentEntityList(currentEntityList: any[]) {
    if (currentEntityList !== null) {
      this._currentEntityList = currentEntityList;
      this.formControl.setValue(currentEntityList, { emitEvent: false });
    }
  }

  formControl = new UntypedFormControl(<any[]>[]);
  get selectedEntityList(): any[] {
    return this.formControl.value as any[];
  }

  param = new RequestParams({
    query: {},
    order: {},
    pagination: {
      pageIndex: 0,
      pageSize: AppConstants.DEFAULT_PAGE_SIZE,
    } as RequestPagination,
  } as RequestParams);

  total = 0;
  list = <any[]>[];
  filterControl = new UntypedFormControl();
  isLoading: boolean;
  selectedContractorIds = <number[]>[];
  log = new Logger(MultiGeneralSelectorComponent.name);

  protected _onDestroy = new Subject<void>();

  constructor(
    private generalService: GeneralService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    if (this.isRequired) {
      this.formControl.setValidators(Validators.required);
    }
    this.param.query.fields = [this.field_entity];
    this.param.query.value = "";
    this.param.order = [{ field: this.field_entity, type: "asc" }];

    this.filterControl.valueChanges
      .pipe(debounceTime(AppConstants.DEFAULT_DEBOUNCE_TIME))
      .pipe(takeUntil(this._onDestroy))
      .subscribe((query) => {
        if (query != this.param.query.value) {
          this.param.query.value = query;
          this.param.pagination.pageIndex = 0;
          this.loadData();
        }
      });

    this.formControl.valueChanges
      .pipe(distinct())
      // .pipe(debounceTime(500))
      .pipe(takeUntil(this._onDestroy))
      .subscribe((selectedEntityList: any[]) => {
        const selectedEntityIds = selectedEntityList?.map((item) => item.id);
        this.writeValue(selectedEntityIds);
      });

    this.loadData();
  }

  ngOnDestroy(): void {
    this._onDestroy.next(null);
    this._onDestroy.complete();
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: number[]) {
    this.log.debug("writeValue", value);
    this.value = value;
    // Update value by original form
    if (!value) {
      this.formControl.setValue(null);
    }

    this.onChange(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  loadData(): void {
    this.isLoading = true;
    //Selector with dependencies, return items without paginator to identified selected values
    if (this.depends) {
      this.param.pagination.pageSize = 0;
    }

    this.generalService
      .find(this.param, this.entity)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (result) => {
          if (result.page === 0) {
            this.list = result.items;
          } else {
            this.list = [...this.list, ...result.items];
          }
          this.total = result.total;
          // To restart values with dependencies
          if (this.depends) {
            const selectedList: any[] = [];
            this.selectedEntityList?.forEach((selected) => {
              if (this.list.find((item) => item.id == selected.id)) {
                selectedList.push(selected);
              }
            });
            this.formControl.setValue(selectedList);
          }
        },
        error: (error) => {
          this.log.error("Error loading", error);
          this.notificationService.open("ErrorLoadingList", "error");
          this.isLoading = false;
        },
      });
  }

  onSeeMoreButton(): void {
    this.param.pagination.pageIndex++;
    this.loadData();
  }

  getListWithoutSelectedItems(): any[] {
    const items = this.list.filter(
      (item) =>
        !this.selectedEntityList
          ?.map((selectedItem) => selectedItem?.id)
          .includes(item.id),
    );
    return items;
  }

  unselectEntity(unselectedItem: any): void {
    const selectedEntityList = this.formControl.value as any[];
    const newValue = selectedEntityList.filter(
      (value) => value.id !== unselectedItem.id,
    );

    this.formControl.setValue(newValue);
  }
}
