import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LoaderService, untilDestroyed } from "@app/@core";

import {
  AWSAuthService,
  LoginContext,
} from "@app/@core/services/aws/auth.service";
import { DialogService } from "@app/@shared/services/dialog.service";
import { NotificationService } from "@app/@shared/services/notification.service";
import { PasswordValidator } from "@app/@shared/validators/password-validator";
import { PasswordRecoverDialogComponent } from "../_dialogs/password-recover-dialog/password-recover-dialog.component";
import { AuthConfirmationAccountComponent } from "../confirmation-account/confirmation-account.component";
import { PasswordRefactorComponent } from "../password-refactor/password-refactor.component";
import { MatDialog } from "@angular/material/dialog";
import { RegisterUserLoginComponent } from "../_dialogs/register-user-login/register-user-login.component";

export enum LoginFormFields {
  username = "username",
  password = "password",
  remember = "remember",
}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class AuthLoginComponent implements OnInit {
  LoginFormFields = LoginFormFields;

  isLoading: boolean = false;
  hidePassword: Boolean = true;
  focusPassword: Boolean = false;

  loginForm = new FormGroup({
    [LoginFormFields.username]: new FormControl<string>(null, [
      Validators.required,
    ]),
    [LoginFormFields.password]: new FormControl<string>(null, [
      Validators.required,
      PasswordValidator.password(),
    ]),
    [LoginFormFields.remember]: new FormControl<boolean>(true),
  });

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private awsAuthService: AWSAuthService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {}

  login() {
    this.loginForm.value.username = this.loginForm.value.username;
    const credentials: LoginContext = this.loginForm.value as LoginContext;

    this.isLoading = true;
    this.loaderService.activateNavigation();

    this.awsAuthService
      .login(credentials)
      .then((user: any) => {
        // [REFACTOR CREDENTIALS] Alternative manual process
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.dialogService
            .open(PasswordRefactorComponent, {
              data: { user: user },
              minWidth: "320px",
            })
            .subscribe(() => {
              this.router.navigate(
                [this.route.snapshot.queryParams.redirect || "/"],
                { replaceUrl: true },
              );
              untilDestroyed(this);
            });
        }

        // Login sucess > Redirect routing
        this.router.navigate(["/dashboard"]);

        untilDestroyed(this);
      })
      .catch((error: any) => {
        if (error.code === "UserNotConfirmedException") {
          this.dialogService
            .open(AuthConfirmationAccountComponent, {
              data: { email: credentials.username },
              minWidth: "320px",
            })
            .subscribe((result) => {
              if (result) this.login();
            });
        } else if (
          error.code === "NotAuthorizedException" &&
          error.message.includes("User is disabled")
        ) {
          this.notificationService.open(
            "auth.login.authentication failed",
            "warn",
          );
        } else if (
          error.code === "NotAuthorizedException" &&
          error.message.includes("Incorrect username or password")
        ) {
          this.notificationService.open(
            "auth.login.authentication failed",
            "warn",
          );
        } else {
          this.notificationService.open(
            "auth.login.authentication failed",
            "warn",
          );
        }
      })
      .finally(() => {
        this.isLoading = false;
        this.loaderService.deactivateNavigation();
      });
  }

  onRecoverPasswordButtonClicked() {
    this.router.navigate(["/auth/forgot"]);
  }

  registerUser() {
    this.dialog.open(RegisterUserLoginComponent, {
      panelClass: "mat-dialog-responsive",
      disableClose: true,
      data: {},
    });
  }
}
