import { Injectable } from "@angular/core";
import { NotificationService } from "./notification.service";

export interface ResponseError {
  error: {
    msg: string;
  };
  name: string;
  status: number;
  statusText: string;
  url: string;
}

@Injectable()
export class ErrorNotificationService {
  constructor(private notificationService: NotificationService) {}

  notification(response_error: ResponseError, text_error: string) {
    const error = response_error.error;
    if (response_error.status == 409) {
      this.notificationService.open(error.msg, "warn");
    } else {
      this.notificationService.open(text_error, "error");
    }
  }
}
