import { Base } from "./_base";
import { Housing } from "./housing";
import { User } from "./user";

export interface Tenant extends User {
  id?: number;
  contact?: string;
  code?: string;
  housing_ids?: number[];
  housing?: Housing[];
}

export enum TenantFields {
  id = "id",
  contact = "contact",
  code = "code",
}

export interface TenantHousing extends Base {
  housing_id: number;
  tenant_id: number;
}

export enum TenantHousingFields {
  housing_id = "housing_id",
  tenant_id = "tenant_id",
}
