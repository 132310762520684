import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";

import { RequestParams, ResultPaginated } from "@app/@shared/models/_results";
import {
  Housing,
  HousingGroupsFiles,
  HousingGroups,
} from "@app/@shared/models/housing";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HousingService {
  constructor(private httpClient: HttpClient) {}
  // GRUPOS DE VIVIENDAS
  createHousingGroup(group: HousingGroups): Observable<HousingGroups> {
    const url = "/housing/groups";
    return this.httpClient.post<HousingGroups>(url, group);
  }
  updateGroup(group: HousingGroups): Observable<HousingGroups> {
    const url = "/housing/groups";
    return this.httpClient.patch<HousingGroups>(url, group);
  }

  getGroups(
    requestParams: RequestParams,
  ): Observable<ResultPaginated<HousingGroups>> {
    const url = "/housing/groups";
    const params = requestParams.toHttpParams();
    return this.httpClient.get<ResultPaginated<HousingGroups>>(url, { params });
  }
  getGroupById(field: string, value: number): Observable<HousingGroups> {
    const _params = new HttpParams().set(field, value);
    return this.httpClient.get<HousingGroups>("/housing/groups", {
      params: _params,
    });
  }

  // FIN GRUPOS DE VIVIENDAS

  setHouse(company: Housing): Observable<Housing> {
    return this.httpClient
      .post("/housing", company)
      .pipe(map((body: Housing) => body));
  }

  updateHouse(house: Housing): Observable<Housing> {
    const url = "/housing";
    return this.httpClient.patch<Housing>(url, house);
  }

  getHouseById(field: string, value: number): Observable<Housing> {
    const _params = new HttpParams().set(field, value);
    return this.httpClient.get<Housing>("/housing", { params: _params });
  }

  getHouses(
    requestParams: RequestParams,
  ): Observable<ResultPaginated<Housing>> {
    const url = "/housing";
    const params = requestParams.toHttpParams();
    return this.httpClient.get<ResultPaginated<Housing>>(url, { params });
  }

  getHouse(field: string, value: string): Observable<ResultPaginated<Housing>> {
    const _params = new HttpParams().set(field, value);
    return this.httpClient.get<ResultPaginated<Housing>>("/housing", {
      params: _params,
    });
  }

  getGroup(field: string, value: string): Observable<HousingGroups> {
    const _params = new HttpParams().set(field, value);
    return this.httpClient.get<HousingGroups>("/housing/group", {
      params: _params,
    });
  }

  setHousingGroupFile(
    housingGroupFile: HousingGroupsFiles,
  ): Observable<HousingGroupsFiles> {
    return this.httpClient
      .post("/housing/groups/files", housingGroupFile)
      .pipe(map((body: HousingGroupsFiles) => body));
  }

  deleteHousingGroupFile(housingGroupFileId: number): Observable<any> {
    const _params = new HttpParams().set("id", housingGroupFileId);
    return this.httpClient.delete("/housing/groups/files", { params: _params });
  }

  getHousingGroupsFiles(
    requestParams: RequestParams,
  ): Observable<ResultPaginated<HousingGroupsFiles>> {
    const _params = requestParams.toHttpParams();
    return this.httpClient.get<ResultPaginated<HousingGroupsFiles>>(
      "/housing/groups/files",
      { params: _params },
    );
  }
}
