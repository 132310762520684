import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { Shell } from "@app/shell/shell.service";

const routes: Routes = [
  // [ROUTES] Application routes to control restrictions (active load)
  Shell.childRoutes(
    [
      { path: "", redirectTo: "/dashboard", pathMatch: "full" },
      // { path: 'restriction/permission', component: PermissionRestrictionComponent, data: { title: marker('app.restrictions.title') } },
    ],
    false,
  ),

  Shell.childRoutes([
    { path: "", redirectTo: "dashboard", pathMatch: "full" },
    {
      path: "dashboard",
      loadChildren: () =>
        import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
    },
    {
      path: "users",
      loadChildren: () =>
        import("./users/users.module").then((m) => m.UserModule),
    },
    {
      path: "companies",
      loadChildren: () =>
        import("./companies/companies.module").then((m) => m.CompaniesModule),
    },
    {
      path: "worksheets",
      loadChildren: () =>
        import("./worksheets/worksheets.module").then(
          (m) => m.WorksheetsModule,
        ),
    },
    {
      path: "advices",
      loadChildren: () =>
        import("./advices/advices.module").then((m) => m.AdvicesModule),
    },
    {
      path: "housing",
      loadChildren: () =>
        import("./housing/housing.module").then((m) => m.HousingModule),
    },
    {
      path: "exports",
      loadChildren: () =>
        import("./exports-worksheet/exports-worksheet.module").then(
          (m) => m.ExportsWorksheetModule,
        ),
    },
  ]),
  // Fallback when no prior route is matched
  { path: "**", redirectTo: "dashboard", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
