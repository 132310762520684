import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Company } from "@app/@shared/models/company";
import { RequestParams } from "@app/@shared/models/_results";
import { CompaniesService } from "@app/companies/companies.service";
import { ReplaySubject, Subject } from "rxjs";
import { debounceTime, filter, map, takeUntil } from "rxjs/operators";
import { finalize } from "rxjs/operators";
import { NotificationService } from "@app/@shared/services/notification.service";
import { Logger } from "@app/@core";

@Component({
  selector: "app-companies-selector",
  templateUrl: "./companies-selector.component.html",
  styleUrls: ["./companies-selector.component.scss"],
})
export class CompaniesSelectorComponent implements OnInit, OnDestroy {
  log = new Logger(CompaniesSelectorComponent.name);

  private _value: string;
  private _editable: boolean;
  private _label: string;
  private _required: boolean;
  private _availableCompanies: Company[];
  private _availableControl: boolean = false;

  @Input() set value(value: string) {
    this._value = value;

    const _valueId = this.companyControl.value
      ? this.companyControl.value.id
      : undefined;

    if (value && value != _valueId) this.getCompany(value);
    else if (!value) this.getCompanies();
  }
  get value(): string {
    return this._value;
  }

  @Input() set availableCompanies(companies: Company[]) {
    if (companies) {
      this._availableControl = true;
      this._availableCompanies = companies;
    } else {
      this._availableCompanies = [];
    }
  }

  @Input() set editable(value: boolean) {
    this._editable = value;
    value ? this.companyControl.enable() : this.companyControl.disable();
  }
  get editable(): boolean {
    return this._editable;
  }

  @Input() set label(value: string) {
    this._label = value;
  }
  get label(): string {
    return this._label;
  }

  @Input() set required(value: boolean) {
    let _validators = [];

    if (value) _validators.push(Validators.required);

    this.companyControl.clearValidators();
    this.companyControl.setValidators(_validators);
  }
  get required(): boolean {
    return this._required;
  }

  @Output() formControlEvent = new EventEmitter<FormControl>();

  /** control for the selected entity for selection */
  public companyControl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword selection */
  public companyFilterControl: FormControl = new FormControl();

  /** list of entities filtered by search keyword */
  public companyFiltered: ReplaySubject<Company[]> = new ReplaySubject<
    Company[]
  >(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  requestCompaniesParams = new RequestParams();
  isLoading: boolean = false;

  constructor(
    private companiesService: CompaniesService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {
    // listen for search field value changes
    this.companyFilterControl.valueChanges
      .pipe(
        filter((search) => !!search),
        takeUntil(this._onDestroy),
        debounceTime(250),
        map((search) => {
          this.requestCompaniesParams.query = {
            fields: ["name", "cif", "address"],
            value: search,
          };
          this.getCompanies();
        }),
        takeUntil(this._onDestroy),
      )
      .subscribe();

    this.companyControl.valueChanges
      .pipe(takeUntil(this._onDestroy), debounceTime(250))
      .subscribe(() => {
        this.formControlEvent.emit(this.companyControl);
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getCompanies() {
    this.isLoading = true;

    this.companiesService
      .getCompanies(this.requestCompaniesParams)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (result) => {
          if (this._availableControl) {
            result.items = this._availableCompanies;
          }
          this.companyFiltered.next(result.items);
        },
        error: (error) => {
          this.log.error("Error get companies: ", error);
          this.notificationService.open("app.messages.operation_failed");
        },
      });
  }

  getCompany(companyId: any) {
    this.isLoading = true;
    this.companiesService
      .getCompanyById("id", companyId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (result) => {
          if (result.total > 0) {
            this.companyFiltered.next([result.items[0]]);
            this.companyControl.setValue(result.items[0]);
            this.companyControl.updateValueAndValidity();
          }
        },
        error: (error) => {
          this.notificationService.open("Error loading user list", "error");
        },
      });
  }
}
