import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Logger } from "@app/@core";
import { ErrorNotificationService } from "@app/@shared/services/error_notification.service";
import { NotificationService } from "@app/@shared/services/notification.service";
import { UsersService } from "@app/users/users.service";
import { finalize } from "rxjs/operators";

export enum RegisterFormFields {
  email = "email",
  name = "name",
  surname = "surname",
  dni = "dni",
  phone = "phone",
  password = "password",
}

@Component({
  selector: "app-register-user-login",
  templateUrl: "./register-user-login.component.html",
  styleUrls: ["./register-user-login.component.scss"],
})

/*

  ### class RegisterUserLoginComponent

  [Muestra el diálogo de registro en el login]

  - Este formulario va a guardar el usuario SOLO en la base de datos. 
          [Estado usuario = Registrado] --> No podrá acceder a la aplicación.

  - Para que el usuario se cree en cognito el administrador deberá de confirmarlo. 
          [Estado usuario = Confirmado ] --> Se le mandará un correo con una contraseña provisional y ya podrá acceder a la aplicación

*/
export class RegisterUserLoginComponent implements OnInit {
  log = new Logger(RegisterUserLoginComponent.name);

  RegisterFormFields = RegisterFormFields;
  isLoading: boolean = false;
  fileURL = "/assets/PoliticaPrivacidad.pdf";
  check: boolean = false;

  registerForm = new FormGroup({
    [RegisterFormFields.email]: new FormControl<string>(null, [
      Validators.required,
      Validators.email,
    ]),
    [RegisterFormFields.name]: new FormControl<string>(null, [
      Validators.required,
    ]),
    [RegisterFormFields.surname]: new FormControl<string>(null, [
      Validators.required,
    ]),
    [RegisterFormFields.dni]: new FormControl<string>(null, [
      Validators.required,
      Validators.pattern("^[XYZ0-9][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$"),
    ]),
    [RegisterFormFields.phone]: new FormControl<string>(null, [
      Validators.required,
    ]),
  });

  constructor(
    private usersService: UsersService,
    private notificationService: NotificationService,
    public router: Router,
    private dialogRef: MatDialogRef<any>,
    private errorNotificationService: ErrorNotificationService,
  ) {}

  ngOnInit() {}

  register() {
    if (this.registerForm.invalid || this.check == false) {
      this.notificationService.open("WarnFieldsRequired", "warn");
      this.registerForm.markAllAsTouched();
      return;
    }

    this.isLoading = true;
    this.usersService
      .register(this.registerForm.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (result) => {
          this.router.navigate(["/auth"], { replaceUrl: true });
          this.notificationService.open("app.messages.register_login");
          this.dialogRef.close(result);
        },
        error: (error) => {
          this.log.error("Error register: ", error);
          this.errorNotificationService.notification(
            error,
            "ErrorCreatingData",
          );
        },
      });
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onCheck(event: MatCheckboxChange) {
    if (event.checked) {
      this.check = true;
    } else {
      this.check = false;
    }
  }
}
