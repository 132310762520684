import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@shared/shared.module";

import { AuthModule } from "@app/auth";
import { ShellComponent } from "./shell.component";

import { HeaderComponent } from "./header/header.component";
import { SidenavPrimaryComponent } from "./sidenavs/sidenav-primary.component";
import { SidenavSecundaryComponent } from "./sidenavs/sidenav-secundary.component";

@NgModule({
  imports: [RouterModule, SharedModule, AuthModule],
  declarations: [
    ShellComponent,
    HeaderComponent,
    SidenavPrimaryComponent,
    SidenavSecundaryComponent,
  ],
})
export class ShellModule {}
