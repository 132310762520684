<div
  fxLayout="row"
  fxLayoutAlign="center center"
  class="permission-restriction-container"
>
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1.0rem">
    <mat-icon>code_off</mat-icon>
    <span>{{ "app.restrictions.access denied" | translate }}</span>
  </div>
</div>
