import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from "@angular/forms";
import { Logger } from "@app/@core";
import { AppConstants } from "@app/@shared/app-constants";
import {
  RequestEntity,
  RequestFilter,
  RequestPagination,
  RequestParams,
} from "@app/@shared/models/_results";
import { GeneralService } from "@app/@shared/services/general.service";
import { NotificationService } from "@app/@shared/services/notification.service";
import { Subject } from "rxjs";
import { debounceTime, finalize, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-general-selector",
  templateUrl: "./general-selector.component.html",
  styleUrls: ["./general-selector.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GeneralSelectorComponent),
      multi: true,
    },
  ],
})
export class GeneralSelectorComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  @Input() value?: string;
  @Input() entity: string;

  @Input() public set filters(value: RequestParams) {
    //Reset currenty entity only on first load

    if (!this.first_load) {
      this.currentEntity = null;
    }
    // this._filter = value;
    let final_filters = new RequestParams({ filter: [], entity: [] });
    final_filters.filter = value?.filter?.filter((s) => s.value != null);
    final_filters.entity = value?.entity?.filter((s) => s.value != null);

    if (
      final_filters?.filter?.length > 0 ||
      final_filters?.entity?.length > 0
    ) {
      this.param.filter = final_filters.filter;
      this.param.entity = final_filters.entity;
      this.def_params(final_filters);
    }
  }
  @Input() title: string;
  @Input() field_entity: string;
  @Input() field_left: string[]; // Example add HTML [field_left]="['code']"
  @Input() field_down: string[]; // Example add HTML [field_down]="['ceco', 'status.description']"
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() showed_field: string;
  @Input() extra: string[] = [];
  @Output() sendCurrentEntity = new EventEmitter<any>();
  @Output() sendCurrentList = new EventEmitter<any>();

  private _currentEntity: any;
  private _isTouch: any;

  public get currentEntity(): any {
    return this._currentEntity;
  }
  @Input() public set currentEntity(currentEntity: any) {
    this._currentEntity = currentEntity;
    this.writeValue(currentEntity?.id);
  }

  @Input() allowSetNull = false;

  @Input() public set isTouch(isTouch: false) {
    this._isTouch = isTouch;
    if (this._isTouch == true) {
      this.control.markAllAsTouched();
    }
  }
  //RequestEntity --> getter and setter to update value param
  private _entity_param: any[];
  public get entity_param(): any[] {
    return this._entity_param;
  }

  control = new UntypedFormControl();
  filterControl = new UntypedFormControl();
  EntityList = <any[]>[];
  isLoading = false;
  first_load: boolean = true; //Used to control currentEntity restart

  total = 0;
  param = new RequestParams({
    query: {},
    order: [],
    filter: [],
    entity: [],
    pagination: {
      pageIndex: 0,
      pageSize: AppConstants.DEFAULT_PAGE_SIZE,
    } as RequestPagination,
  } as RequestParams);

  log = new Logger(GeneralSelectorComponent.name);

  protected _onDestroy = new Subject();

  constructor(
    private generalService: GeneralService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.param?.filter?.length > 0 || this.param?.entity?.length > 0
      ? null
      : this.def_params();

    this.control.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((relativeId) => {
        this.currentEntity = this.EntityList.find(
          (entity) => entity.id === relativeId,
        );
        this.sendCurrentEntity.emit(this.currentEntity);
        this.log.debug("value", relativeId);
        this.writeValue(relativeId);
      });

    this.filterControl.valueChanges
      .pipe(debounceTime(AppConstants.DEFAULT_DEBOUNCE_TIME))
      .pipe(takeUntil(this._onDestroy))
      .subscribe((query) => {
        this.param.query.value = query;
        this._loadData();
      });
  }

  //TODO: Fix double call -- executed by filters
  def_params(filters: RequestParams = null) {
    this.param.query.fields = [this.field_entity];
    this.param.query.value = "";
    this.param.order = [{ field: this.field_entity, type: "asc" }];

    if (filters?.filter?.length > 0) {
      this.param.filter = filters?.filter;
    }
    if (filters?.entity?.length > 0) {
      this.param.entity = filters?.entity;
    }

    this._loadData();
  }

  ngOnDestroy(): void {
    this._onDestroy.next(null);
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: string) {
    this.value = value;
    this.control.setValue(value, { emitEvent: false });
    this.onChange(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(disabled: boolean) {
    this.isDisabled = disabled;
  }

  onRefreshButtonClicked(event: MouseEvent): void {
    event.stopPropagation();

    this._loadData();
  }

  onSeeMoreButton(): void {
    this.param.pagination.pageSize += AppConstants.DEFAULT_PAGE_SIZE;
    this._loadData();
  }

  private _loadData(): void {
    this.isLoading = true;

    //Improve the timing load
    if (this.currentEntity && !this.EntityList?.length) {
      this.EntityList = [this.currentEntity];
    }

    this.generalService
      .find(this.param, this.entity)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (result) => {
          this.EntityList = result.items;
          this.first_load = false; //set "false" after first load
          //Add currentEntity in list of results (Pagination)
          if (this.currentEntity) {
            if (
              !this.EntityList.find((item) => item.id == this.currentEntity.id)
            )
              this.EntityList.unshift(this.currentEntity);
          }
          this.total = result.total;

          this.sendCurrentList.emit(this.EntityList);
        },
        error: (error) => {
          this.log.error(`Error loading ${this.entity} list`, error);
          this.notificationService.open("ErrorOperation", "error");
        },
      });
  }

  select(): string {
    if (this.showed_field) {
      if (this.value && this.EntityList.length > 0) {
        return this.EntityList.find((item) => item?.id === this.value)[
          this.showed_field
        ];
      }
    } else {
      if (this.value && this.EntityList.length > 0) {
        return this.EntityList.find((item) => item?.id === this.value)[
          this.field_entity
        ];
      }
    }
  }

  items(entity: any, field: any): string {
    field.split(".").forEach(function (item: string) {
      entity = entity[item];
    });
    return entity;
  }

  sfind(s: string, l: string[]): boolean {
    return l.includes(s);
  }
}
