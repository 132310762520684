import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AWSAuthService } from "@app/@core/services/aws/auth.service";
import { NotificationService } from "@app/@shared/services/notification.service";
import { PasswordValidator } from "@app/@shared/validators/password-validator";

export enum PasswordRefactorFormFields {
  password = "password",
}

@Component({
  selector: "app-password-refactor",
  templateUrl: "./password-refactor.component.html",
  styleUrls: ["./password-refactor.component.scss"],
})
export class PasswordRefactorComponent implements OnInit {
  PasswordRefactorFormFields = PasswordRefactorFormFields;

  hidePassword = true;

  passwordRefactorForm = new FormGroup({
    [PasswordRefactorFormFields.password]: new FormControl<string>(null, [
      Validators.required,
      PasswordValidator.password(),
    ]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<any>,
    private awsAuthService: AWSAuthService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {}

  changePassword() {
    this.awsAuthService
      .completeNewPassword(
        this.data.user,
        this.passwordRefactorForm.value.password,
      )
      .then((result) => {
        this.notificationService.open(
          "auth.refactor password.password configured correctly",
        );
        this.dialogRef.close(result);
      })
      .catch((error) => this.notificationService.open(error.message));
  }
}
