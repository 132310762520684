<div>
  <h2 mat-dialog-title class="dialog-title">
    {{ "auth.register.register suscriptor" | translate | capitalize }}
  </h2>

  <form [formGroup]="registerForm">
    <mat-card fxLayout="column" fxLayoutGap="0.5rem">
      <mat-dialog-content fxLayout="column">
        <!-- Nombre -->
        <mat-form-field fxFlex="40%">
          <mat-label>{{
            "users.user.name" | translate | capitalize
          }}</mat-label>
          <input
            matInput
            (keydown.enter)="$event.preventDefault()"
            [formControlName]="RegisterFormFields.name"
          />
          <mat-error
            *ngIf="
              registerForm.controls.name.errors?.required &&
              registerForm.controls.name.touched
            "
          >
            <span>{{ "Este campo es obligatorio" | translate }}</span>
          </mat-error>
        </mat-form-field>

        <div fxFlex="0.50rem"></div>

        <!-- Apellidos -->
        <mat-form-field fxFlex="60%">
          <mat-label>{{
            "users.user.surname" | translate | capitalize
          }}</mat-label>
          <input
            matInput
            (keydown.enter)="$event.preventDefault()"
            [formControlName]="RegisterFormFields.surname"
          />
          <mat-error
            *ngIf="
              registerForm.controls.surname.errors?.required &&
              registerForm.controls.surname.touched
            "
          >
            <span>{{ "Este campo es obligatorio" | translate }}</span>
          </mat-error>
        </mat-form-field>

        <!-- Email -->
        <mat-form-field>
          <mat-label>{{
            "users.user.email" | translate | capitalize
          }}</mat-label>
          <input matInput [formControlName]="RegisterFormFields.email" />
          <mat-error
            *ngIf="
              registerForm.controls.email.errors?.required &&
              registerForm.controls.email.touched
            "
          >
            <span>{{ "Este campo es obligatorio" | translate }}</span>
          </mat-error>
          <mat-error
            *ngIf="
              registerForm.controls.email.errors?.email &&
              registerForm.controls.email.touched
            "
          >
            <span>{{
              "app.forms.is invalid"
                | translate
                  : { item: ("users.user.email" | translate | capitalize) }
            }}</span>
          </mat-error>
        </mat-form-field>

        <div fxLayout="row">
          <!-- Dni -->
          <mat-form-field fxFlex="40%">
            <mat-label>{{
              "users.user.dni" | translate | capitalize
            }}</mat-label>
            <input
              matInput
              (keydown.enter)="$event.preventDefault()"
              [formControlName]="RegisterFormFields.dni"
            />
            <mat-error
              *ngIf="
                registerForm.controls.dni.errors?.required &&
                registerForm.controls.dni.touched
              "
            >
              <span>{{ "Este campo es obligatorio" | translate }}</span>
            </mat-error>
            <mat-error *ngIf="registerForm.controls.dni.errors?.maxlength">
              <span>{{
                "app.forms.is too long"
                  | translate
                    : { item: ("users.user.dni" | translate | capitalize) }
              }}</span>
            </mat-error>
            <mat-error
              *ngIf="registerForm.controls.dni.errors?.notUniqueUsername"
            >
              <span>{{
                "app.forms.is repeated"
                  | translate
                    : { item: ("users.user.username" | translate | capitalize) }
              }}</span>
            </mat-error>
            <mat-error *ngIf="registerForm.controls.dni.errors?.pattern">
              <span>{{
                "app.forms.is invalid"
                  | translate
                    : { item: ("users.user.username" | translate | capitalize) }
              }}</span>
            </mat-error>
          </mat-form-field>

          <div fxFlex="0.50rem"></div>

          <!-- Teléfono -->
          <mat-form-field fxFlex="60%">
            <mat-label
              >{{ "users.user.phone" | translate | capitalize }} *</mat-label
            >
            <ngx-mat-intl-tel-input
              formControlName="phone"
              [onlyCountries]="['es']"
              [enablePlaceholder]="true"
              cdkFocusInitial
            ></ngx-mat-intl-tel-input>
          </mat-form-field>
        </div>

        <mat-checkbox color="primary" (change)="onCheck($event)">
          {{ "auth.register.check" | translate | capitalize }}
          <a
            [href]="fileURL"
            target="_blank"
            class="btn-border"
            download="Política Privacidad.pdf"
          >
            Política de privacidad
          </a>
        </mat-checkbox>
      </mat-dialog-content>
    </mat-card>

    <mat-dialog-actions fxLayout="row" fxLayoutGap="0.5rem">
      <button fxFlex mat-raised-button (click)="onCancel()" color="primary">
        <span>{{ "app.actions.cancel" | translate | titlecase }}</span>
      </button>

      <button
        fxFlex
        mat-raised-button
        color="primary"
        [disabled]="registerForm.invalid || !check"
        (click)="register()"
      >
        <span>{{ "app.actions.accept" | translate | titlecase }}</span>
      </button>
    </mat-dialog-actions>
  </form>
</div>
