import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import { Technician, TechnicianFields } from "@app/@shared/models/technician";
import { Tenant, TenantFields } from "@app/@shared/models/tenant";
import { User, UserStatus, UserTypes } from "@app/@shared/models/user";

import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatSelectChange } from "@angular/material/select";
import { Logger } from "@app/@core";
import { AWSAuthService } from "@app/@core/services/aws/auth.service";
import { Company } from "@app/@shared/models/company";
import { ErrorNotificationService } from "@app/@shared/services/error_notification.service";
import { GeneralService } from "@app/@shared/services/general.service";
import { NotificationService } from "@app/@shared/services/notification.service";
import { UniqueUsernameValidator } from "@app/@shared/validators/unique-username-validator";
import { UsersService } from "@app/users/users.service";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-entity-user",
  templateUrl: "./entity-user.component.html",
  styleUrls: ["./entity-user.component.scss"],
})
export class EntityUserComponent implements OnInit {
  log = new Logger(EntityUserComponent.name);

  private _entity: User;
  private _includeCompaniesSelector: Boolean = true;
  private _includeHousingSelector: Boolean = true;
  private _type: UserTypes = UserTypes.user;
  private _editable: Boolean;
  private _register: Boolean;
  private _includeAuthActions: Boolean = false;
  _hide: boolean = true;
  touch: boolean = false;
  userStatus = UserStatus;

  @Input() set entity(value: User) {
    this._entity = value;
    let uniqueUsernameValidator = new UniqueUsernameValidator(this.userService);
    this.userForm.controls.dni.addAsyncValidators(
      uniqueUsernameValidator.createValidator(this.entity?.dni),
    );
    if (value != null) {
      this.type = value.type;
      this.loadUserForm();
    }
  }
  get entity(): User {
    return this._entity;
  }

  @Input() set includeCompaniesSelector(value: Boolean) {
    this._includeCompaniesSelector = value;
  }
  get includeCompaniesSelector(): Boolean {
    return this._includeCompaniesSelector;
  }

  @Input() set includeHousingSelector(value: Boolean) {
    this._includeHousingSelector = value;
  }
  get includeHousingSelector(): Boolean {
    return this._includeHousingSelector;
  }

  @Input() set type(value: UserTypes) {
    this._type = value;

    [
      "email",
      "address",
      "workstation",
      "department",
      "contact",
      "code",
      "housing",
    ].forEach((element) => {
      this.userForm.removeControl(element);
    });

    if (this.userForm) {
      this.userForm.controls.type.setValue(value);
      let userType;
      if (value == UserTypes.technician) {
        const _entity = this.entity as Technician;
        // this.userForm.addControl('company_id', this.formBuilder.control(Number(this.awsAuthService.currentUser?.company_id), [Validators.required]));
        this.userForm.addControl(
          "company_id",
          this.formBuilder.control(null, [Validators.required]),
        );
        this.selectedCompany = _entity?.company;

        this.userForm.addControl(
          "email",
          this.formBuilder.control(null, [
            Validators.required,
            Validators.email,
          ]),
        );

        this.userForm.addControl(
          "address",
          this.formBuilder.control(_entity?.address),
        );
        this.userForm.addControl(
          "workstation",
          this.formBuilder.control(_entity?.workstation),
        );
        this.userForm.addControl(
          "department",
          this.formBuilder.control(_entity?.department),
        );
        this.userForm.controls.role_id.setValue(4);
      } else if (value == UserTypes.company_admin) {
        const _entity = this.entity as Technician;

        this.userForm.addControl(
          "company_id",
          this.formBuilder.control(null, [Validators.required]),
        );
        // this.userForm.addControl('company_id', this.formBuilder.control(Number(this.awsAuthService.currentUser?.company_id), [Validators.required]));
        this.selectedCompany = _entity?.company;

        this.userForm.addControl(
          "email",
          this.formBuilder.control(null, [
            Validators.required,
            Validators.email,
          ]),
        );

        this.userForm.addControl(
          "address",
          this.formBuilder.control(_entity?.address),
        );
        this.userForm.addControl(
          "workstation",
          this.formBuilder.control(_entity?.workstation),
        );
        this.userForm.addControl(
          "department",
          this.formBuilder.control(_entity?.department),
        );
        this.userForm.controls.role_id.setValue(3);
      } else if (value == UserTypes.tenant) {
        this.userForm.removeControl("company_id");
        userType = TenantFields;
        const _entity: Tenant = this.entity as Tenant;

        this.userForm.addControl(
          "email",
          this.formBuilder.control(null, [Validators.email]),
        );

        this.userForm.addControl(
          "contact",
          this.formBuilder.control(_entity?.contact),
        );
        this.userForm.addControl(
          "code",
          this.formBuilder.control(_entity?.code),
        );
        let housing_ids: number[] = _entity?.housing?.map(
          (housing) => housing.id,
        );
        this.userForm.addControl(
          "housing_ids",
          this.formBuilder.control(housing_ids),
        );
        this.userForm.controls.role_id.setValue(2);
      } else if (value == UserTypes.urvial_administrator) {
        this.userForm.removeControl("company_id");

        this.userForm.addControl(
          "email",
          this.formBuilder.control(null, [
            Validators.required,
            Validators.email,
          ]),
        );

        this.userForm.controls.role_id.setValue(1);
      }

      if (userType) {
        for (let field in userType) {
          if (field != "id") {
            this.userForm.addControl(
              field,
              this.formBuilder.control("", [Validators.required]),
            );
          }
        }
      }
      this.loadUserForm();
    }
  }
  get type(): UserTypes {
    return this._type;
  }

  @Input() set editable(value: Boolean) {
    this._editable = value;
    value ? this.userForm.enable() : this.userForm.disable();
  }
  get editable(): Boolean {
    return this._editable;
  }

  @Input() set register(value: Boolean) {
    this._register = value;
    value ? this.userForm.enable() : this.userForm.disable();
  }
  get register(): Boolean {
    return this._register;
  }

  @Input() set includeAuthActions(value: Boolean) {
    this._includeAuthActions = value;
  }
  get includeAuthActions(): Boolean {
    return this._includeAuthActions;
  }

  @Output() formEvent = new EventEmitter<FormGroup>();

  UserTypes = UserTypes;
  currentUserType: UserTypes = this.awsAuthService.currentUser?.type;
  selectedCompany: Company;
  userForm: FormGroup;

  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private awsAuthService: AWSAuthService,
    private notificationService: NotificationService,
    private errorNotificationService: ErrorNotificationService,
    public generalServices: GeneralService,
  ) {
    this.createUserForm();
  }

  ngOnInit() {
    // Initial form status
    this.formEvent.emit(this.userForm);
    if (this.currentUserType == UserTypes.company_admin) {
      this.userForm.patchValue({ type: UserTypes.technician });
      this.type = UserTypes.technician;
      this.userForm.addControl(
        "company_id",
        this.formBuilder.control(
          Number(this.awsAuthService.currentUser?.company_id),
          [Validators.required],
        ),
      );
    }
  }

  createUserForm() {
    // let uniqueEmailValidator = new UniqueEmailValidator(this.userService);
    this.userForm = this.formBuilder.group({
      id: [null],
      type: [null, [Validators.required]],
      dni: [
        "",
        [
          Validators.required,
          Validators.pattern("^[XYZ0-9][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$"),
        ],
      ],
      email: ["", [Validators.email, Validators.maxLength(80)]],
      username: ["", [Validators.maxLength(50)]],
      phone: [
        "",
        [
          Validators.minLength(9),
          Validators.maxLength(12),
          Validators.required,
        ],
      ],
      name: ["", [Validators.maxLength(50), Validators.required]],
      surname: ["", [Validators.maxLength(50), Validators.required]],
      role_id: [null],
    });

    if (!this.entity?.id) {
      this.userForm.addControl(
        "password",
        this.formBuilder.control(this.awsAuthService.generatePassword(), [
          Validators.required,
        ]),
      );
      this.userForm.controls.email.enable();
      this.userForm.controls.username.enable();
      this.userForm.controls.type.enable();
      this.userForm.controls.name.enable();
      this.userForm.controls.surname.enable();
      this.userForm.controls.dni.enable();
      this.userForm.controls.phone.enable();
    }

    this.userForm.get("dni").valueChanges.subscribe((value) => {
      try {
        this.userForm.get("username").setValue(value);
      } catch (error) {
        this.userForm.get("username").reset();
      }
    });

    this.userForm.valueChanges
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => this.formEvent.emit(this.userForm));
  }

  loadUserForm() {
    this.userForm.removeControl("password");
    const _entity = Object.assign({}, this.entity);
    if (_entity.id) {
      delete _entity.created_on;
      delete _entity.updated_on;
    } else {
      this.userForm.addControl(
        "password",
        this.formBuilder.control(this.awsAuthService.generatePassword(), [
          Validators.required,
        ]),
      );
    }
    if (this.entity?.id) {
      const controlKeys = Object.keys(this.userForm.controls);
      const enableKeys = [
        "name",
        "surname",
        "phone",
        "contact",
        "code",
        "address",
        "workstation",
        "department",
      ];
      for (const key of controlKeys) {
        this.userForm.controls[key].disable();
        if (
          enableKeys.includes(key) &&
          this.currentUserType == UserTypes.company_admin &&
          _entity.status == UserStatus.confirmed
        ) {
          this.userForm.controls[key].enable();
        }
        if (key == "email" && !_entity.email) {
          this.userForm.controls[key].enable();
        }
        if (
          key == "contact" &&
          this.currentUserType == UserTypes.urvial_administrator
        ) {
          this.userForm.controls[key].enable();
        }
        if (this.currentUserType == UserTypes.urvial_administrator) {
          this.userForm.controls[key].enable();
        }
      }
    }
    this.userForm.patchValue(_entity);
  }

  onSelectCompany(control: FormControl) {
    this.userForm.controls.company_id.setValue(control.value.id);
  }

  onAdminChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.userForm.controls.role_id.setValue(3);
    } else {
      this.userForm.controls.role_id.setValue(4);
    }
  }

  onDisableClick() {
    this.updateUser({
      id: this.entity.id,
      type: this.type,
      status: UserStatus.disabled,
    } as User);
  }

  onEnableClick() {
    this.updateUser({
      id: this.entity.id,
      type: this.type,
      status: UserStatus.confirmed,
    } as User);
  }

  updateUser(user: User) {
    this.isLoading = true;

    this.userService
      .updateUser(user)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (user: User) => {
          this.log.info("User updated succesful");
          this.notificationService.open("SuccessFullyUpdate");
          this.entity = user;
        },
        error: (error) => {
          this.log.error("Error updating User", error);
          this.errorNotificationService.notification(
            error,
            "ErrorUpdatingData",
          );
        },
      });
  }

  onUserTypeChange(event: MatSelectChange) {
    this.type = event.value;
  }
}
