import { DialogService } from "./dialog.service";
import { ErrorNotificationService } from "./error_notification.service";
import { NavigateService } from "./navigate.service";
import { NotificationService } from "./notification.service";
import { RoutesService } from "./routes.service";

export const SERVICES = [
  DialogService,
  NotificationService,
  ErrorNotificationService,
  RoutesService,
  NavigateService,
];
