export interface ModuleInterface {
  label: string;
  icon?: string;
  path?: string;
  sidenav?: boolean;
  expanded?: boolean;
  permissions?: string[];
  submodules?: ModuleInterface[];
}

export const permissionsSettings: ModuleInterface[] = [
  {
    label: "dashboard.title",
    icon: "space_dashboard",
    path: "/dashboard",
    sidenav: true,
    permissions: [],
  },
  {
    label: "users.title",
    path: "/users",
    icon: "supervised_user_circle",
    sidenav: true,
    expanded: false,
    permissions: [],
  },
  {
    label: "housing.title",
    path: "/housing",
    icon: "house",
    sidenav: true,
    expanded: false,
    permissions: [],
    submodules: [
      {
        label: "housing.list.title",
        path: "/housing/list",
        sidenav: true,
        permissions: [],
      },
      {
        label: "housing.groups.list.title",
        path: "/housing/groups",
        sidenav: true,
        permissions: [],
      },
    ],
  },
  {
    label: "companies.title",
    icon: "business",
    path: "/companies",
    sidenav: true,
    expanded: false,
    permissions: [],
  },
  {
    label: "advices.title",
    icon: "feedback",
    path: "/advices",
    sidenav: true,
    expanded: false,
    permissions: [],
  },
  {
    label: "worksheets.title",
    icon: "content_paste",
    path: "/worksheets",
    sidenav: true,
    expanded: false,
    permissions: [],
  },
  {
    label: "exportsWorksheet.title",
    icon: "library_books",
    path: "/exports",
    sidenav: true,
    expanded: false,
    permissions: [],
  },
];

export const passwordSetting: string =
  '(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9])(?=[^<>{}"/|;:.,~!?@#$%^=&*]*[<>{}"/|;:.,~!?@#$%^=&*]).{8,}';
