<h2 class="mb-3">Historial de Cambios</h2>
<div
  fxLayout="column"
  fxLayoutGap="0.5rem"
  class="history-collection-container"
>
  <div fxLayout="column" class="mat-elevation-z2">
    <table mat-table [dataSource]="auditHistory?.items" multiTemplateDataRows>
      <ng-container matColumnDef="action_op">
        <th mat-header-cell *matHeaderCellDef>
          {{ "audit.action" | translate | uppercase }}
        </th>
        <td mat-cell *matCellDef="let history">{{ history.action_op }}</td>
      </ng-container>

      <ng-container matColumnDef="action_user">
        <th mat-header-cell *matHeaderCellDef>
          {{ "audit.user" | translate | uppercase }}
        </th>
        <td mat-cell *matCellDef="let history">{{ history.action_user }}</td>
      </ng-container>

      <ng-container matColumnDef="properties">
        <th mat-header-cell *matHeaderCellDef>
          {{ "audit.properties" | translate | uppercase }}
        </th>
        <td mat-cell *matCellDef="let history">
          {{ getDifferentProperties(history) | json }}
        </td>
      </ng-container>

      <ng-container matColumnDef="#">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let history" style="width: 50px">
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-icon
              class="submenu-button"
              [ngClass]="{ rotated: expandedElement === history }"
              >expand_more</mat-icon
            >
          </div>
        </td>
      </ng-container>

      <!-- ROW > NESTED CONTENT -------------------------------------------- -->
      <ng-container matColumnDef="expandedContainer">
        <td
          mat-cell
          *matCellDef="let history"
          [attr.colspan]="displayedColumns.length"
        >
          <div
            fxFill
            [@expandableRow]="
              history == expandedElement ? 'expanded' : 'collapsed'
            "
            class="history-collection-detail-container"
          >
            <div
              fxLayout="column"
              fxLayoutGap="0.5rem"
              class="detail-container"
            >
              <div fxFlex *ngIf="expandedElement === history">
                <mat-card>
                  <mat-card-content>
                    <pre> {{ history | json }} </pre>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </div>
        </td>
      </ng-container>
      <!-- ----------------------------------------------------------------- -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        class="data-element-row"
        [class.data-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedContainer']"
        class="data-detail-row"
      ></tr>
    </table>

    <mat-paginator
      [length]="auditHistory?.total"
      [pageIndex]="auditHistory?.page"
      [pageSize]="auditHistory?.page_size"
      (page)="onChangePage($event)"
    >
    </mat-paginator>
  </div>
</div>

<ng-template #noHistory>
  <p class="mt-3">No hay historial de cambios disponible.</p>
</ng-template>
