<div fxLayout="column" fxLayoutGap="0.5rem" class="image-upload-container">
  <!-- DROPZONE -->
  <div
    *ngIf="!file"
    (dragover)="onDropzoneOver($event)"
    (dragleave)="onDropzoneOut($event)"
    class="dropzone"
    [ngClass]="{ 'dropzone-over': inDropzone }"
  >
    <input
      #fileDropReference
      fxFlex
      fxFill
      type="file"
      [accept]="fileFormatsRestriction"
      (change)="fileBrowseHandler($event.target.files)"
      [disabled]="disabled"
      [style]="!isPreview ? 'cursor: default;' : ''"
    />
    <div
      *ngIf="isPreview"
      fxFlex
      fxFill
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <mat-icon>add</mat-icon>
    </div>
  </div>

  <!-- DROPZONE-PREVIEW -->
  <div
    *ngIf="file"
    class="preview"
    [style.backgroundImage]="sanitizeUri()"
    [style]="!isPreview ? 'cursor: default;' : ''"
  >
    <input
      #fileDropReference
      fxFlex
      fxFill
      type="file"
      [accept]="fileFormatsRestriction"
      (change)="fileBrowseHandler($event.target.files)"
      [disabled]="disabled"
    />
    <div *ngIf="!disabled" fxFlexFill fxLayout="column" class="contextual">
      <!-- <div fxFlex fxLayout="row" fxLayoutAlign="center center" class="edit">
        <button mat-icon-button (click)="fileDropReference.click()">
          <mat-icon>edit</mat-icon>
        </button>
      </div> -->
      <div fxFlex fxLayout="row" fxLayoutAlign="center center" class="delete">
        <button mat-icon-button (click)="resetFile()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- RESTRICTIONS INFO -->
  <div
    fxLayout="column"
    fxLayoutAlign="start"
    fxLayoutGap="0.5rem"
    class="dropzone-restrictions"
  >
    <span
      *ngIf="
        fileFormatsRestriction &&
        showRestrictions.includes('fileFormatsRestriction')
      "
      ><strong>{{ "upload-files.formats-restriction" | translate }}:</strong>
      {{ fileFormatsRestriction }}</span
    >
    <mat-divider
      [vertical]="true"
      *ngIf="
        fileFormatsRestriction &&
        showRestrictions.includes('fileFormatsRestriction')
      "
    ></mat-divider>
    <span
      *ngIf="
        fileItemsRestriction &&
        showRestrictions.includes('fileItemsRestriction')
      "
      ><strong>{{ "upload-files.item-restriction" | translate }}:</strong>
      {{ fileItemsRestriction }}</span
    >
    <mat-divider
      [vertical]="true"
      *ngIf="
        fileItemsRestriction &&
        showRestrictions.includes('fileItemsRestriction')
      "
    ></mat-divider>
    <span
      *ngIf="
        fileSizeRestriction && showRestrictions.includes('fileSizeRestriction')
      "
      ><strong>{{ "upload-files.size-restriction" | translate }}:</strong>
      {{ fileSizeRestriction }} MB</span
    >
  </div>

  <!-- 
  <div fxLayout="column" fxLayoutAlign="start " fxLayoutGap="0.5rem" class="dropzone-restrictions" *ngIf="showRestrictions">
    <span *ngIf="fileFormatsRestriction"><strong>{{ 'upload-image.formats-restriction' | translate }}:</strong> {{ fileFormatsRestriction }}</span>
    <span *ngIf="fileSizeRestriction"><strong>{{ 'upload-image.size-restriction' | translate }}:</strong> {{ fileSizeRestriction }} MB</span>
  </div> -->

  <!-- ACTIONS -->
  <div
    fxLayout="column"
    fxLayoutAlign="end center"
    fxLayoutGap="0.5rem"
    *ngIf="file && showActions"
  >
    <button fxFlexFill mat-raised-button color="primary" (click)="sendFile()">
      {{ buttonUpload | translate }}
    </button>
    <button fxFlexFill mat-button (click)="resetFile()">
      {{ buttonReset | translate }}
    </button>
  </div>
</div>
