<div>
  <h2 mat-dialog-title class="dialog-title">
    {{ "auth.change password.title" | translate | capitalize }}
  </h2>

  <form [formGroup]="passwordRecoverForm">
    <mat-card fxLayout="column" fxLayoutGap="0.5rem">
      <mat-dialog-content fxLayout="column">
        <!-- <div class="register-row-text">Ponte en contacto con Urvial para conseguir tu código de recuperación. </div> -->

        <!-- Código de recuperación -->
        <mat-form-field>
          <mat-label>{{
            "auth.login.confirmation_code" | translate | capitalize
          }}</mat-label>
          <input
            matInput
            type="code"
            formControlName="code"
            (keydown.enter)="$event.preventDefault()"
          />
          <mat-error
            *ngIf="
              passwordRecoverForm.controls.code.errors?.required &&
              passwordRecoverForm.controls.code.touched
            "
          >
            <span>{{ "Este campo es obligatorio" | translate }}</span>
          </mat-error>
        </mat-form-field>

        <!-- Username (dni) -->
        <mat-form-field>
          <mat-label>{{ "users.user.dni" | translate | capitalize }}</mat-label>
          <input
            matInput
            type="username"
            formControlName="username"
            (keydown.enter)="$event.preventDefault()"
          />
          <mat-error
            *ngIf="
              passwordRecoverForm.controls.username.errors?.required &&
              passwordRecoverForm.controls.username.touched
            "
          >
            <span>{{ "Este campo es obligatorio" | translate }}</span>
          </mat-error>
          <mat-error
            *ngIf="passwordRecoverForm.controls.username.errors?.pattern"
          >
            <span>{{
              "app.forms.is invalid"
                | translate
                  : { item: ("users.user.dni" | translate | capitalize) }
            }}</span>
          </mat-error>
          <mat-error
            *ngIf="passwordRecoverForm.controls.username.errors?.maxlength"
          >
            <span>{{
              "app.forms.is too long"
                | translate
                  : { item: ("users.user.dni" | translate | capitalize) }
            }}</span>
          </mat-error>
        </mat-form-field>

        <!-- Nueva contraseña -->
        <mat-form-field>
          <mat-label>{{
            "auth.change password.password replace" | translate | capitalize
          }}</mat-label>
          <input
            matInput
            type="password"
            formControlName="password_replace"
            (focus)="focusPassword = true"
            [type]="hidePassword ? 'password' : 'text'"
            (keydown.enter)="$event.preventDefault()"
          />
          <mat-error
            *ngIf="
              passwordRecoverForm.controls.password_replace.errors?.pattern &&
              passwordRecoverForm.controls.password_replace.touched
            "
          >
            <span>{{
              "app.forms.is not secure"
                | translate
                  : {
                      item:
                        ("auth.change password.password replace"
                        | translate
                        | capitalize)
                    }
            }}</span>
          </mat-error>
          <mat-error
            *ngIf="
              passwordRecoverForm.controls.password_replace.errors?.required &&
              passwordRecoverForm.controls.password_replace.touched
            "
          >
            <span>{{ "Este campo es obligatorio" | translate }}</span>
          </mat-error>
          <div fxLayout="row" matSuffix>
            <mat-icon (click)="hidePassword = !hidePassword">{{
              hidePassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </div>
        </mat-form-field>

        <app-password-hints
          *ngIf="focusPassword"
          [formControl]="passwordRecoverForm.controls.password_replace"
        ></app-password-hints>
      </mat-dialog-content>
    </mat-card>

    <mat-dialog-actions fxLayout="row" fxLayoutGap="0.5rem">
      <button
        fxFlex
        mat-raised-button
        (click)="onCancel()"
        color="primary"
        (keydown.enter)="$event.preventDefault()"
      >
        <span>{{ "app.actions.cancel" | translate | titlecase }}</span>
      </button>

      <button
        fxFlex
        mat-raised-button
        color="primary"
        (click)="changePassword()"
        [disabled]="passwordRecoverForm.invalid"
      >
        <span>{{ "app.actions.accept" | translate | titlecase }}</span>
      </button>
    </mat-dialog-actions>
  </form>
</div>
